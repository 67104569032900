.h-tile_beach-container {
    background: $beige-light;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media('>=desktop') {
        display: flex;
        flex: 1;
        flex-direction: row-reverse;

        transform: translateX(-28%) scale(0.5);
        opacity: 0.5;
    }
}

.h-tile_beach-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        width: 50%;
    }
}

.h-tile_beach-content {
    padding: 40px 30px 30px;
    text-align: center;
    color: $blue;

    @include media('>=desktop') {
        display: flex;
        flex-direction: column;
        padding: 20px 70px 20px 35px;
    }

    @include media('>=desktopLarge') {
        padding: 35px 70px 35px 35px;
    }
}

.h-tile_beach-badge {
    max-width: 80px;
    margin: 0 auto 30px;

    @include media('>=desktop') {
        margin: 0 0 50px 0;
    }

    @include media('>=desktopLarge') {
        margin: 0 0 57px 0;
    }
}

.h-tile_beach-text {
    @include media('>=desktop') {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.h-tile_beach-title {
    font-size: 30px;
    line-height: 37px;
    text-transform: uppercase;
    margin: 0 0 18px;

    @include media('>=desktop') {
        max-width: 460px;
        margin: 0 auto 20px;
    }

    @include media('>=desktopLarge') {
        font-size: 45px;
        line-height: 50px;
    }
}

.h-tile_beach-desc {
    font-size: 16px;
    line-height: 22px;

    @include media('>=desktop') {
        max-width: 460px;
        margin: 0 auto;
    }

    @include media('>=desktopLarge') {
        font-size: 18px;
        line-height: 25px;
    }
}

.h-tile_beach-link {
    display: inline-block;
    font-family: $MontserratBold;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.08em;
    color: $tamarillo;
    text-align: center;
    margin: 18px 0 0;

    @include media('>=desktop') {
        margin: 35px 0 0;
    }
}

.h-tile_beach-navigation {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 18px;
    justify-content: center;

    @include media('>=desktopLarge') {
        margin: 0 0 44px;
    }
}

.h-tile_beach-tab {
    display: inline-block;
    font-family: $MontserratBold;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.08em;
    text-align: center;
    padding: 12px 20px;
    border: 1px solid $blue;
    cursor: pointer;
    opacity: 0.3;
    flex: 0 0 auto;

    @include media('>=tablet', '<desktop') {
        padding: 12px 40px;
    }
    @include media('>=desktopLarge') {
        padding: 12px 40px;
    }

    &.active {
        opacity: 1;
    }
}
