// =========================================
//    Custom style for flatpickr datepicker
// =========================================

$calendar-border-color: #e9e9e9;

.gl_calendar-wide {
    .flatpickr-calendar,
    .flatpickr-innerContainer,
    .flatpickr-rContainer,
    .flatpickr-months,
    .flatpickr-weekdays,
    .flatpickr-days {
        width: 100%;
    }

    .dayContainer {
        min-width: 100%;
        width: 100%;
        max-width: none;
    }

    .flatpickr-day {
        max-width: none;
    }
}

.gl_calendar-blue {
    .flatpickr-input {
        text-align: center;
        border-width: 1px;
        padding: 0 10px;
    }

    .flatpickr-months {
        border-bottom: 1px solid $calendar-border-color;
    }

    .flatpickr-months .flatpickr-month {
        color: $blue;
        height: 42px;
    }

    .flatpickr-current-month {
        font-size: 100%;
        padding: 0;
        height: 42px;
    }

    .flatpickr-current-month .flatpickr-monthDropdown-months {
        height: 42px;
        padding: 0 0 0 5px;
        color: $blue;
    }

    .numInputWrapper span {
        right: -15px;
    }

    .flatpickr-current-month input.cur-year {
        padding: 0 0 0 10px;
        color: $blue;
    }

    .flatpickr-months .flatpickr-prev-month,
    .flatpickr-months .flatpickr-next-month {
        color: $blue;
        fill: $blue;
        padding: 12px 10px;
        height: 42px;
    }

    .flatpickr-months .flatpickr-prev-month:hover,
    .flatpickr-months .flatpickr-next-month:hover {
        color: $blue-hover;
    }

    .flatpickr-months .flatpickr-prev-month:hover svg,
    .flatpickr-months .flatpickr-next-month:hover svg {
        fill: $blue-hover;
    }

    span.flatpickr-weekday {
        text-transform: uppercase;
        color: $blue;
    }

    .flatpickr-day:not(.flatpickr-disabled) {
        color: $blue;
    }

    .flatpickr-day.selected,
    .flatpickr-day.startRange,
    .flatpickr-day.endRange,
    .flatpickr-day.selected.inRange,
    .flatpickr-day.startRange.inRange,
    .flatpickr-day.endRange.inRange,
    .flatpickr-day.selected:focus,
    .flatpickr-day.startRange:focus,
    .flatpickr-day.endRange:focus,
    .flatpickr-day.selected:hover,
    .flatpickr-day.startRange:hover,
    .flatpickr-day.endRange:hover,
    .flatpickr-day.selected.prevMonthDay,
    .flatpickr-day.startRange.prevMonthDay,
    .flatpickr-day.endRange.prevMonthDay,
    .flatpickr-day.selected.nextMonthDay,
    .flatpickr-day.startRange.nextMonthDay,
    .flatpickr-day.endRange.nextMonthDay {
        background: $blue;
        color: $white;
        border-color: $blue;
        font-weight: bold;
    }

    span.flatpickr-day.today:not(.selected),
    span.flatpickr-day.prevMonthDay.today:not(.selected),
    span.flatpickr-day.nextMonthDay.today:not(.selected) {
        border-color: $blue;
        font-weight: bold;
    }

    .flatpickr-day.today:hover {
        border-color: $blue-hover;
        background: $blue-hover;
        color: $white;
    }

    .flatpickr-day.today:focus {
        background: $blue;
        color: $white;
    }

    span.flatpickr-day.today:not(.selected):hover,
    span.flatpickr-day.prevMonthDay.today:not(.selected):hover,
    span.flatpickr-day.nextMonthDay.today:not(.selected):hover {
        border: 1px solid $blue-hover;
    }

    .flatpickr-current-month .flatpickr-monthDropdown-months:focus,
    .flatpickr-current-month .flatpickr-monthDropdown-months:active,
    .flatpickr-current-month input.cur-year:focus {
        outline: auto;
    }
}

.gl_calendar-noborder {
    .flatpickr-calendar {
        box-shadow: none;
    }

    span.flatpickr-day,
    span.flatpickr-day.prevMonthDay,
    span.flatpickr-day.nextMonthDay {
        border: 0;
    }
}
