.h-beach_carousel-container {
    position: relative;
    z-index: 4;
    // css hack to have proper anchor view on scroll if sticky panel is
    padding-top: calc(48px + 40px);
    margin-top: -88px;

    @include media('>=desktop') {
        // css hack to have proper anchor view on scroll if sticky panel is
        padding-top: calc(80px + 40px);
        margin-top: -120px;
    }

    .slick-current {
        .h-tile_beach-container {
            transform: none;
            opacity: 1;
        }
    }

    .slick-arrow {
        @include absolute-vertical-center;
        background-color: $beige-light;
        opacity: 0.85;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        border: 1px solid $cornflower-blue;
        z-index: 1;
    }

    .slick-prev {
        @include media('>=desktop') {
            left: calc(50% - 30px);
        }

        &::before {
            content: '';
            display: inline-block;
            width: 30%;
            height: 30%;
            border: 3px solid $blue;
            border-width: 2px 0 0 2px;
            transform: translateX(20%) rotate(-45deg);
        }
    }

    .slick-next {
        @include media('>=desktop') {
            right: 10px;
        }

        &::before {
            content: '';
            display: inline-block;
            width: 30%;
            height: 30%;
            border: 3px solid $blue;
            border-width: 2px 0 0 2px;
            transform: translateX(-20%) rotate(-225deg);
        }
    }
}
