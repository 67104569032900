.mahinaswimwear-component {
    color: $blue;
    margin: 30px 20px 40px;
    background-color: $giftblock-grey;

    @include media('>=tablet') {
        margin: 30px 30px 40px;
    }

    @include media('>=desktop') {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        margin: 65px 0;
    }
}

.mahinaswimwear-left-col {
    @include media('>=desktop') {
        flex: 0 1 55%;
    }
}

.mahinaswimwear-right-col {
    text-align: center;
    padding: 40px 30px;

    @include media('>=tablet') {
        padding: 65px;
    }

    @include media('>=desktop') {
        flex: 0 1 45%;
        padding: 5%;
    }

    @include media('>=desktopLarge') {
        padding: 50px 110px;
    }
}

.mahinaswimwear-title {
    padding: 0 0 10px;

    @include media('>=tablet') {
        padding: 0 0 20px
    }
}

.mahinaswimwear-desc {
    font-size: 12px;
    padding: 10px 0 0;

    @include media('>=tablet') {
        font-size: 24px;
        padding: 20px 0 0;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}
