.story-block {
    position: relative;
    margin: 16px 0 0;

    @include media('>=tablet') {
        margin: 20px 0 0;
    }
}

.story-block-content {
    margin: 20px;

    @include media('>=tablet') {
        margin: 20px 32px;
    }

    &.blended {
        position: absolute;
        width: 100%;
        padding: 20px;
        margin: 0;
        bottom: 0;
        text-shadow: 2px 2px 4px $blue;

        @include media('>=tablet') {
            padding: 20px 32px;
        }
    }
}

.story-block-title {
    margin: 0 0 5px;
}

.story-block-description {
    font-family: $Arial;
    color: $blue;
    margin: 0 0 25px;
    max-width: 300px;

    @include media('>=desktop') {
        max-width: unset;
    }
}

.story-block-link {
    display: block;

    @include media('>=tablet') {
        width: 300px;
    }
}
