.full-width-hero-banner-component {
    position: relative;
}

.full-width-hero-banner-link {
    display: block;
}

.full-width-hero-banner-title {
    width: 100%;
    text-align: center;
    text-decoration: none;
    padding: 10px;
    font-size: 22px;
    line-height: 26px;

    @include media('>=tablet') {
        font-size: 36px;
        line-height: 44px;
        padding: 10px 10px 45px;
    }

    @include media('>=desktop') {
        font-size: 48px;
        line-height: 58px;
    }

    &.bottom {
        @include absolute-horizont-center;
        bottom: 0;
    }

    &.middle {
        @include absolute-center;
    }
}
