.pd-accountcreation {
    margin: 45px 25px 20px;

    @include media('>=tablet') {
        margin: 70px 30px;
    }

    @include media('>=desktop') {
        margin: 100px auto;
        max-width: 710px;
    }
}

.pd-accountcreation-title {
    font: 16px / 19px $Arial;
    letter-spacing: normal;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 30px;
    
    @include media('>=tablet') {
        text-align: center;
        font-size: 20px;
        line-height: 20px;
    }
}

.pd-accountcreation-form {
    .pd-accountcreation-optional {
        display: none;
    }
}
