$imagecarouseltext-blue: #3a5570;
$imagecarouseltext-blue-light: #c7d4e2;

.image-carousel-text {
    @include media('>=desktop') {
        display: flex;
    }

    &.image-carousel-text-bottom {
        margin-bottom: 70px;
    }
}

.image-carousel-text-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.image-carousel-text-col-link {
    display: block;
}

.image-carousel-text-carousel-wrapper {
    position: relative;
}

.image-carousel-text-carousel {
    @include media('>=desktop') {
        height: 100%;
    }

    .slick-list,
    .slick-track {
        height: inherit;
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        width: 40px;
        height: 40px;
        z-index: 1;
    }

    .slick-prev {
        left: 5px;

        @include media('>=tablet') {
            left: 10px;
        }
    }

    .slick-next {
        right: 5px;

        @include media('>=tablet') {
            right: 10px;
        }
    }
}

.image-carousel-text-arrows-wrapper {
    @include media('>=desktop') {
        position: absolute;
        bottom: 0;
        right: 0;
        background: $imagecarouseltext-blue;
    }

    .slick-arrow {
        @include media('>=desktop') {
            display: inline-block;
            position: relative;
            width: 70px;
            height: 60px;
            text-align: center;
            padding: 0;
            left: 0;
        }

        &::before {
            border-width: 1px 0 0 1px;
            border-color: $imagecarouseltext-blue-light;
            width: 7px;
            height: 7px;
        }
    }
}
