.pd-asset-title {
    font-family: $Arial;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-transform: none;
    letter-spacing: normal;
    text-align: center;
    margin: 45px 15px;

    @include media('>=desktop') {
        margin: 45px auto;
        max-width: 1000px;
    }
}
