html,
body {
    height: 100%;
    width: 100%;
}

body > img {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// =================================
//    Styleguide
// =================================
.gl_flex {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    flex-flow: row nowrap;
}

.gl_flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

// Case to use: The button element cannot be flex containers in some browsers, e.g.: some Safari versions.
// => so inner flex div is required
.gl_flex-btn-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.gl_flex-breaker {
    width: 100%;
}

* {
    box-sizing: border-box;
}

.invisible,
.visually-hidden {
    display: none;
}

// to be visible for screen readers only
.offscreen {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}

.offscreen_mobile-only {
    @include media('<desktop') {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }
}

.offscreen_mobileandtablet-only {
    @include media('<tablet') {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }
}

.offscreen_desktop-only {
    @include media('>=desktop') {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }
}

// hide 1px height GTM iframe
iframe[src*="servedby.flashtalking.com"] {
    position: absolute;
    top: -9999px !important;
    left: -9999px !important;
}

// to be coupled with .offscreen on wrapping element
.force-visible-for-validation {
    display: inline-block !important;
}

.gl_global-wrapper {
    overflow: hidden; // forbidden horizontal scroll (to hide any transition elements with initial negative value - minicart transition)
    position: relative;


    &.pt_checkout {
        overflow: visible; // needed for checkout sticky summary if no minicart included
    }
}

li {
    outline: none;
}

a,
.gl_fake-link {
    text-decoration: underline;
    color: $blue;

    &:hover {
        color: $blue-hover;
    }

    &.gl_Arial {
        font-size: 12px;
    }

    &.gl_TimesNewRoman {
        font-size: 14px;
    }
}

p {
    margin: 20px 0;
}

strong,
b {
    .store-detail-time & {
        font: vw(24px) $MontserratBold;
        font-weight: bold;
        text-transform: uppercase;

        @include media('>=tablet') {
            font-size: 24px;
        }

        @include media('>=desktop') {
            font-size: 12px;
        }
    }
}

img {
    width: 100%;
    vertical-align: top;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &.bullets {
        list-style-type: disc;
        padding: 0 0 0 vw(30px);

        @include media('>=tablet') {
            padding: 0 0 0 24px;
        }

        @include media('>=desktop') {
            padding: 0 0 0 15px;
        }
    }
}

button {
    background: 0;
    border: 0;
    padding: 0;

    &:focus {
        outline-offset: 3px;
    }
}

.gl_clear-fix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

legend {
    width: 100%;
}

.primary-content {
    position: relative;
}

.text-label {
    font-weight: bold;
    text-transform: uppercase;
}

// =================================
//    Inline Description List
// =================================
.gl_dl-inline {
    dt,
    dd {
        display: inline;
    }

    dd {
        margin: 0;
    }

    dd::after {
        content: '';
        display: block;
    }
}

// =================================
//    Wave Separator
// =================================
.gl_wave-separator {
    width: 100%;
    display: inline-block;

    &::before {
        content: '';
        width: 62px;
        height: 4px;
        margin: 0 auto;
        display: block;
        background-size: contain;
        background-repeat: repeat-x;
    }

    @include media('>=tablet', '<desktop') {
        &::before {
            width: 124px;
            height: 8px;
        }
    }
}

.gl_wave-separator-white {
    &::before {
        background-image: url('../../images_active/wave_white_big.png');
    }

    @include media('>=desktop') {
        &::before {
            background-image: url('../../images_active/wave_white.png');
        }
    }
}

.gl_wave-separator-blue {
    &::before {
        background-image: url('../../images_active/wave_blue_big.png');
    }

    @include media('>=desktop') {
        &::before {
            background-image: url('../../images_active/wave_blue.png');
        }
    }
}

.gl_separator-margin {
    margin: vw(20px) 0 vw(30px);

    @include media('>=desktop') {
        margin: 20px 0 30px;
    }
}

// =================================
//    SVG wave Separator
// =================================
.gl_svg-wave-separator {
    text-align: center;

    .svgwave {
        transform: scale(0.5);
    }
}

.gl_svg-wave-separator-white {
    .svgwavepath {
        stroke: $white;
    }
}

.gl_svg-wave-separator-blue {
    .svgwavepath {
        stroke: $blue;
    }
}

// =================================
//    Wave Button
// =================================
.gl_wave-button {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    font: vw(28px) $MontserratBold;
    text-transform: uppercase;
    text-decoration: none;
    color: $blue;

    @include media('>=desktop', '<1100px') {
        font-size: 11px;
    }

    @include media('>=1100px') {
        font-size: 12px;
    }

    &::after {
        content: '';
        width: 100%;
        height: 6px;
        margin: 5px auto 0;
        display: block;
        background: url('../../images_active/wave_blue_heavy.png') repeat center;
        visibility: hidden;
    }

    &.active,
    &:hover {
        color: $blue-hover;

        &::after {
            visibility: visible;
        }
    }
}

// =================================
//    Bread crumb
// =================================
.gl_bread-crumb {
    font-family: $Arial;
    text-decoration: none;
    color: $blue;
    font-size: vw(24px);

    @include media('>=tablet') {
        font-size: 24px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

// =================================
//    Logout
// =================================
.gl_logout {
    font: 11px $MontserratBold;
}

// =================================
//    Fonts
// =================================
// .gl_MontserratBold {
//     font-family: $MontserratBold;
// }

.gl_TimesNewRoman {
    font-family: $TimesNewRoman;
    font-style: italic;
}

// .gl_Arial {
//     font-family: $Arial;
// }

// =================================
//    Colors
// =================================
// .gl_blue {
//     color: $blue;
// }

// .gl_grey {
//     color: $grey;
// }

// .gl_light-grey {
//     color: $light-grey;
// }

// .gl_bg_blue {
//     background-color: $blue;
// }

// .gl_bg_grey {
//     background-color: $grey;
// }

// .gl_bg_light-grey {
//     background-color: $light-grey;
// }

// =================================
//    Desktop only and mobile only
//   !important is used to overpower custom styles on elements
//   on which gl_desktop-only and gl_mobile-only will be used
// =================================
.gl_desktop-only {
    @include media('<desktop') {
        display: none !important;
    }
}

.gl_tablet-only {
    @include media('>=tablet') {
        display: none !important;
    }
}

.gl_mobile-only {
    @include media('>=desktop') {
        display: none !important;
    }
}

.gl_mobile-hidden-only {
    @include media('<tablet') {
        display: none !important;
    }
}

.gl_showOnPhone {
    @include media('>=tablet') {
        display: none !important;
    }
}

.gl_showOnTablet {
    display: none;

    @include media('>=tablet', '<desktop') {
        display: block !important;
    }
}

.gl_mobile-image-only {
    display: block !important;

    @include media('>=tablet') {
        display: none !important;
    }
}

.gl_tablet-image-only {
    display: none;

    @include media('>=tablet', '<desktop') {
        display: block !important;
    }
}

.gl_tabletanddesktop-image-only {
    display: none;

    @include media('>=tablet') {
        display: block !important;
    }
}

.gl_desktop-image-only {
    display: none;

    @include media('>=desktop', '<1200px') {
        display: block !important;
    }
}

.gl_desktoplarge-image-only {
    display: none;

    @include media('>=1200px', '<1500px') {
        display: block !important;
    }
}

.gl_desktopextralarge-image-only {
    display: none;

    @include media('>=1500px') {
        display: block !important;
    }
}

.gl_desktoplargeandextralarge-image-only {
    display: none;

    @include media('>=1200px') {
        display: block !important;
    }
}


// =================================
//    Headings
// =================================
h1,
h2,
h3,
h4,
.fakeH1,
.fakeH2,
.fakeH3,
.fakeH4 {
    font-family: $MontserratBold;
    margin: 0;
    padding: 0;
    color: $blue;
    letter-spacing: 0.09em;
    text-transform: uppercase;
}

.text-title {
    font-family: $MontserratBold;
    color: $blue;
    letter-spacing: 0.09em;
    text-transform: uppercase;
}

h1,
.fakeH1 {
    font-size: vw(80px);

    @include media('>=tablet') {
        font-size: 42px;
    }

    @include media('>=desktop') {
        font-size: 50px;
    }
}

h2,
.fakeH2 {
    font-size: vw(60px);

    @include media('>=tablet') {
        font-size: vw(38px);
    }

    @include media('>=desktop') {
        font-size: 32px;
    }
}

h3,
.fakeH3 {
    font-size: vw(46px);

    @include media('>=tablet') {
        font-size: vw(38px);
    }

    @include media('>=desktop') {
        font-size: 24px;
    }
}

h4,
.fakeH4 {
    font-size: vw(30px);

    @include media('>=tablet') {
        font-size: vw(20px);
    }

    @include media('>=desktop') {
        font-size: 16px;
    }
}

// =================================
//    General style for small articles
// =================================
.article {
    .content {
        font: italic vw(24px) $TimesNewRoman;
    }

    @include media('>=desktop') {
        .title {
            margin: 0;
        }

        .content {
            font-size: 16px;
        }
    }
}

// =================================
//    Main wrapper for all pages
// =================================
.gl_main-wrapper {
    margin: 0 auto;
    max-width: $desktopLarge;
}

// =================================
//    Global button styles
// =================================
.gl_button {
    display: inline-block;
    font: vw(22px) $MontserratBold;
    color: $blue;
    background-color: $white;
    border: 0;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    line-height: vw(84px);
    height: vw(84px);
    padding: 0 vw(30px);
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;

    @include media('>=480px') {
        height: 42px;
        line-height: 42px;
    }

    @include media('>=480px', '<desktop') {
        font-size: 16px;
    }

    @include media('>=desktop') {
        padding: 0 15px;
        font-size: 11px;
    }

    &:focus {
        outline-offset: 3px;
    }
}

.gl_btn {
    display: inline-block;
    width: 100%;
    font: 10px / 14px $MontserratBold;
    color: $blue;
    background-color: $white;
    border: 0;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    height: 44px;
    padding: 0 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    &:focus {
        outline-offset: 3px;
    }
}

.gl_btn.gl_btn-link {
    line-height: 44px;
}

// =================================
//    Small height for a button, used on Ship to multiple addresses step
// =================================
.gl_btn-small {
    height: 30px;
}

// =================================
//    Accordion/filters +/- icon
// =================================
.gl_accordion-toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    align-items: center;
    width: auto;

    &::before {
        display: none;
    }
}

// Styling based on ".active &" will falsely trigger on nested accordions
.tabs-accordion-header {
    display: block;
    width: 100%;
    text-align: left;
    font: vw(28px) $MontserratBold;
    text-transform: uppercase;
    color: $blue;
    padding: 13px;
    border-top: 1px solid $blue-light;
    position: relative;
    cursor: pointer;

    @include media('>=tablet') {
        font-size: 20px;
    }

    @include media('>=desktop') {
        font-size: 11px;
    }

    &.active {
        .gl_accordion-toggle-close {
            display: block;
        }

        .gl_accordion-toggle-open {
            display: none;
        }
    }
}

.gl_accordion-toggle-close {
    display: none;

    .ui-state-active &,
    .active & {
        display: block;
    }
}

.gl_accordion-toggle-open {
    .ui-state-active &,
    .active & {
        display: none;
    }
}

// =================================
//    Bigger font size for a button, used in homepage header
// =================================
.gl_btn-big {
    font-size: vw(20px);

    @include media('>=tablet') {
        font-size: 16px;
    }

    @include media('>=desktop') {
        font-size: 14px;
    }
}

// =================================
//    Blue button color
// =================================
.gl_btn-blue {
    background-color: $blue;
    color: $white;

    // EADA-25 - Lack of focus indicators
    &:focus {
        outline-color: $outline-color !important;
    }

    &:hover {
        background-color: $blue-hover !important;
        color: $white !important;
        border-color: $blue-hover !important;
    }

    &.disabled, //for <a>
    &[disabled] { //for <button>
        background-color: $grey;
        cursor: default;

        &:hover {
            background-color: $grey !important;
            color: $white !important;
        }
    }
}

// =================================
//    Blue white color
// =================================
.gl_btn-white {
    background-color: $white;
    color: $blue;
    border: 1px solid $blue;

    // EADA-25 - Lack of focus indicators
    &:focus {
        outline-color: $outline-color;
    }

    &:hover {
        background-color: $blue;
        color: $white;
    }

    &.disabled, //for <a>
    &[disabled] { //for <button>
        background-color: $grey;
        cursor: default;

        &:hover {
            background-color: $grey !important;
            color: $blue !important;
        }
    }
}

// =================================
//    Light grey button color
// =================================
.gl_btn-lightgrey {
    background-color: $light-grey;
    color: $blue;
    border: 1px solid $light-grey;

    // EADA-25 - Lack of focus indicators
    &:focus {
        outline-color: $outline-color;
    }

    &:hover {
        background-color: $blue;
        color: $white;
        border-color: $blue;
    }

    &.disabled, //for <a>
    &[disabled] { //for <button>
        background-color: $grey;
        cursor: default;

        &:hover {
            background-color: $grey !important;
            color: $blue !important;
            border-color: $grey !important;
        }
    }
}

// =================================
//    Light blue white color
// =================================
.gl_btn-medium-blue {
    background-color: $blue-medium;
    color: $white;
    border: 1px solid $blue-medium;

    &:focus {
        outline-color: $blue-medium-outline-color;
    }
}

.gl_btn-royal-blue {
    background-color: $royal-blue;
    color: $white;
    border: 1px solid $royal-blue;

    &:focus {
        outline-color: $blue;
    }
}

.gl_btn-light-blue {
    background-color: $blue-hover;
    color: $white;

    // EADA-25 - Lack of focus indicators
    &:focus {
        outline-color: $blue !important;
    }

    &:hover {
        background-color: $blue-medium !important;
        color: $white !important;
    }

    &.disabled, //for <a>
    &[disabled] { //for <button>
        background-color: $grey;
        cursor: default;

        &:hover {
            background-color: $grey !important;
            color: $white !important;
        }
    }
}

// =================================
//    White button color & white hover
// =================================
.gl_btn-white-full {
    background-color: $white;
    color: $blue;
    border: 1px solid $blue;

    // EADA-25 - Lack of focus indicators
    &:focus {
        outline-color: $outline-color;
    }

    &:hover {
        border-color: $blue-hover !important;
        color: $blue-hover !important;
    }
}

// =================================
//    Blue button color with inverse color on desktop
// =================================
.gl_btn-blue-inverse {
    background-color: $blue;
    color: $white;

    // EADA-25 - Lack of focus indicators
    &:focus {
        outline-color: $outline-color;
    }

    &:hover {
        background-color: $blue-hover !important;
        color: $white !important;
    }

    @include media('>=desktop') {
        background-color: $white;
        color: $blue;

        &:hover {
            background-color: $light-grey;
        }
    }
}

// =================================
//    View more button
// =================================
@keyframes view-more-loading {
    0% {
        background-position: 0 25px;
    }

    50% {
        background-position: 18px 0;
    }

    100% {
        background-position: 36px 25px;
    }
}

.gl_view-more {
    background-color: $white;
    border: 1px solid $blue;
    padding: 0 vw(138px);
    height: vw(83px);
    display: block;
    font-size: vw(22px);
    line-height: vw(83px);
    font-family: $MontserratBold;
    color: $blue;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    position: relative;

    // EADA-25 - Lack of focus indicators
    &:focus {
        outline-color: $outline-color;
    }

    &:hover {
        border-color: $blue-hover !important;
        color: $blue-hover !important;
    }

    &.loading::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 32px;
        background: transparent url('../../images_active/view-more-loading.png') repeat-x left 25px;
        animation: view-more-loading 1.5s infinite normal linear;
    }

    @include media('>=tablet') {
        line-height: 40px;
        height: 40px;
        padding-left: 76px;
        padding-right: 76px;
        font-size: 16px;
    }

    @include media('>=desktop') {
        font-size: 11px;
    }
}

// =================================
//    Back To Browsing button
// =================================
.gl_back-to-browsing {
    padding: 0 20px 0 25px;
    border: 1px solid $light-grey;
    display: inline-block;
    position: relative;
    text-decoration: none;
    background-color: $white;

    &.store-detail-back-mobile {
        border: 0;
        display: flex;
        justify-content: flex-start;
        align-content: stretch;
        flex-flow: row nowrap;
        width: 100%;
        align-items: center;
        box-shadow: 0 3px 5px 0 $light-grey;

        .gl_back-to-browsing-text {
            height: 50px;
            line-height: 50px;
        }
    }

    .gl_back-to-browsing-text {
        color: $blue;
        padding: 0 0 0 10px;
        height: 42px;
        line-height: 42px;
        font-size: 12px;
        font-family: $Arial;
    }

    &:hover {
        border: 1px solid $blue;

        &.store-detail-back-mobile {
            border: 0;
        }

        .gl_ic_back {
            &::before {
                color: $blue-hover;
            }
        }

        .gl_back-to-browsing-text {
            color: $blue-hover;
        }
    }
}

// =================================
//    UP button
// =================================
// .gl_up-button {
//     font: 11px $MontserratBold;
//     border: 0;
//     color: $white;
//     background-color: $blue;
//     cursor: pointer;
//     position: relative;
//     display: inline-block;

//     .gl_up-button-arrow-container {
//         position: absolute;
//         top: 0;
//         left: 0;
//         height: 15px;
//         width: 100%;
//         display: flex;
//         justify-content: center;
//     }

//     .gl_up-button-arrow {
//         @include arrow-down($color: $white);
//         transform: rotate(180deg);
//         width: 10px;
//         height: 5px;
//         display: block;
//         position: relative;
//         top: 10px;
//     }

//     .gl_up-button-text {
//         padding: 0 13px;
//         height: 42px;
//         line-height: 42px;
//         display: inline-block;
//         position: relative;
//         top: 4px;
//     }

//     &:hover {
//         background-color: $blue-hover;
//     }
// }

// =================================
//    Pagination button
// =================================
.gl_paging-button {
    color: $blue;
    width: vw(70px);
    height: vw(70px);
    line-height: vw(70px);
    font-size: vw(20px);
    border: 1px solid $blue;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-family: $MontserratBold;
    cursor: pointer;
    outline: 1px solid $blue;
    text-decoration: none;

    &:link,
    &:active,
    &:hover {
        outline: 1px solid $blue; // override normalize.scss
    }

    &:hover {
        color: $white;
        background-color: $blue-hover;
        border-color: $blue-hover;
        outline-color: $blue-hover;
    }

    &.selected {
        color: $white;
        background-color: $blue;
        border-color: $blue;
        outline-color: $blue;
    }

    @include media('>=desktop') {
        width: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 10px;
    }
}

// =================================
//    Size button
// =================================
.gl_size-button {
    display: block;
    color: $blue;
    border: 1px solid $blue;
    font: 14px/20px $Arial;
    padding: 5px 2px;
    text-align: center;
    position: relative;
    outline-offset: 3px;
    width: 60px;
    height: 32px;
    max-width: 60px;
    cursor: pointer;

    @include media('>=tablet') {
        width: 64px;
        max-width: 64px;
        height: 36px;
    }

    @include media('>=desktop') {
        width: 54px;
        max-width: 54px;
    }

    @include media('>=desktopLarge') {
        width: 59px;
        max-width: 59px;
    }

    &.selected-value {
        color: $white;
        background-color: $blue;

        &.unavailable-value::after {
            background-color: $white;
        }
    }

    &.unavailable-value::after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 67px;
        height: 1px;
        transform: rotate(-28deg);
        transform-origin: bottom left;
        background-color: $blue;

        @include media('>=tablet') {
            width: 71px;
            transform: rotate(-29deg);
        }

        @include media('>=desktop') {
            width: 61px;
            transform: rotate(-30deg);
        }

        @include media('>=desktopLarge') {
            width: 68px;
            transform: rotate(-31deg);
        }
    }

    .gl_has-error & {
        border-color: $red;

        &.unavailable-value::after {
            background-color: $red;
        }
    }
}

//    Options input label
// =================================
.refinement-options-input:focus + .refinement-options-label {
    outline: auto;
    outline-color: $blue-hover;
}

// =================================
//    Color button
// =================================
.gl_color-button {
    width: 36px;
    height: 36px;
    max-width: 36px;
    max-height: 36px;
    padding: 1px;
    border-radius: 50%;

    background-color: $white;
    display: block;
    border: 1px solid $light-grey;
    cursor: pointer;
    position: relative;

    @include media('>=tablet', '<desktop') {
        width: 40px;
        height: 40px;
        max-width: 40px;
        max-height: 40px;
    }

    &.refinement-options-input {
        width: 24px;
        height: 24px;
        max-width: 24px;
        max-height: 24px;

        &::after {
            content: '';
            width: 18px;
            height: 18px;
            border: 2px solid $white;
            border-radius: 50%;
            background-color: transparent;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &.color-multi::before {
        content: '';
        width: 0;
        height: 0;
        border-width: 11px;
        border-style: solid;
        border-color: $m-blue $m-green $m-red $m-yellow;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(45deg);
    }

    img {
        border-radius: 50%;
    }

    &.refinement-options-input:checked,
    .refinement-attr-li &:hover,
    &:hover,
    &.selected-value {
        border: 3px solid $blue;

        &::after {
            width: 14px;
            height: 14px;
        }

        &.color-multi::before {
            border-width: 9px;
        }
    }
}

.gl_color-button-wrap:focus {
    outline: none;

    .gl_color-button {
        outline: auto;
        outline-color: $blue-hover;
    }
}

// =================================
//    Button Plus
// =================================
.gl_plus-button {
    width: 14px;
    height: 14px;
    line-height: 14px;
    position: relative;
    cursor: pointer;

    @include media('>=tablet') {
        width: 22px;
        height: 20px;
        line-height: 20px;
    }

    span {
        font-size: 16px;
        position: absolute;
        top: 0;
        line-height: 1;
        width: 100%;
        text-align: center;

        .more-swatches & {
            position: static;
        }

        @include media('>=tablet') {
            font-size: 24px;
        }

        @include media('>=desktop') {
            font-size: 20px;
        }
    }
}

// =================================
//    Button Close
// =================================
.gl_close-button {
    width: 12px;
    height: 12px;
    line-height: 12px;
    position: relative;
    cursor: pointer;

    @include media('>=tablet') {
        width: 18px;
        height: 20px;
        line-height: 20px;
    }

    span {
        font-size: 13px;
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1;
        width: 100%;
        text-align: center;
        color: $blue;

        .more-swatches & {
            position: static;
        }

        @include media('>=tablet') {
            font-size: 20px;
        }

        @include media('>=desktop') {
            font-size: 16px;
        }
    }
}

// =================================
//    Main container for flexbox grid adds 2 extra cols for 12 based grid to work like 14 based
// =================================
.gl_flexbox-container {
    width: 100%;
    padding: 0 3.8%;

    .main-footer & {
        padding-left: vw(100px);
        padding-right: vw(100px);
    }

    &.header-top {
        padding-left: 5.2%;
        padding-right: 0;
    }

    @include media('>=desktop') {
        padding: 0 7%;

        .main-footer & {
            padding-left: 7%;
            padding-right: 7%;
        }
    }
}

.gl_flexbox-container-desktop {
    width: 100%;

    @include media('>=desktop') {
        padding: 0 7%;
    }
}

.gl_flexbox-container-mobile {
    width: 100%;
    padding: 0 3.8%;

    @include media('>=desktop') {
        padding: 0;
    }
}

// =================================
//    Same as .gl_flexbox-container but has smaler 2 side columns which is the case is some PSD's
// =================================
.gl_flexbox-container-cut {
    padding: 0;

    @include media('>=desktop') {
        width: 100%;
        padding: 0 3.5%;
    }
}

// =================================
//    slick slider dots style override
// =================================
.slick-dots {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: center;
    text-align: center;

    li {
        margin: 12px 16px 0 0;
        width: 16px;
        min-width: 16px;
        height: 16px;
        border: 1px solid $blue;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;

        &.slick-active {
            background-color: $blue;
        }

        &:last-child {
            margin: 12px 0 0 0;
        }
    }

    button {
        border: 0;
        background-color: transparent;
        padding: 0;
        color: transparent;
        outline-offset: 2px;
        width: 14px;
        min-width: 14px;
        height: 14px;
        min-height: 14px;
        // fix for focus state:
        overflow: hidden;
    }
}

// slick slider with equal height slides
.gl_slick-equal-slides {
    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;
    }
}

.gl_slick-center-slides {
    .slick-track {
        margin-left: auto;
        margin-right: auto;
    }
}

.gl_slick-standard-arrow {
    .slick-arrow {
        position: absolute;
        top: 40%;
        width: 25px;
        height: 25px;
        z-index: 1;
    }

    .slick-prev {
        left: -15px;

        @include media('>=desktopLarge') {
            left: -30px;
        }
    }

    .slick-next {
        right: -15px;

        @include media('>=desktopLarge') {
            right: -30px;
        }
    }
}

// =================================
//    Form styles
// =================================
textarea {
    border: 2px solid $blue;
    font: 16px $Arial;
    font-weight: bold;
    color: $blue;
    width: 100%;
    resize: none;
    padding: vw(30px);

    &:focus {
        outline-offset: 4px;
    }

    &::placeholder {
        color: $blue;
        text-transform: uppercase;
        font-size: 12px;
    }

    .gl_form-group.gl_has-error & {
        border-color: $red;
    }

    @include media('>=tablet') {
        padding: 8px;

        &::placeholder {
            font-size: 16px;
        }
    }

    @include media('>=desktop') {
        font-size: 11px;

        &::placeholder {
            font-size: 11px;
        }
    }
}

input::placeholder {
    color: $blue;
    text-transform: uppercase;
    font-size: 16px;

    .gl_form-group.gl_has-error & {
        color: $red;
    }

    .gl_form-group.gl_disabled & {
        color: $grey;
    }

    .gl_form-group.gl_has-success & {
        color: $blue-hover;
    }

    .iti & {
        color: $dark-grey;
        font-style: italic;
    }

    @include media('>=desktop') {
        font-size: 11px;
    }
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="search"],
input[type="number"] {
    border: 2px solid $blue;
    font: 16px $Arial;
    font-weight: bold;
    height: vw(84px);
    line-height: vw(84px);
    min-height: 42px;
    padding: 0 vw(84px) 0 vw(30px);
    color: $blue;
    width: 100%;
    position: relative;
    box-sizing: border-box;

    &:focus {
        outline-offset: 4px;
    }

    .gl_input-transparent_white & {
        border-color: $white;
        background: transparent;
    }

    .gl_form-group.gl_has-error & {
        border-color: $red;
        color: $red;
    }

    .gl_form-group.gl_disabled &,
    &[disabled],
    .gl_form-group.gl_has-success &[disabled] {
        background-color: $grey;
        color: $blue;
        cursor: default;
    }

    .gl_form-group.gl_has-success & {
        border-color: $blue-hover;
        color: $blue-hover;
    }

    @include media('>=tablet') {
        height: 42px;
        line-height: 42px;
        padding: 0 40px 0 8px;
    }

    @include media('>=desktop') {
        font-size: 11px;
    }
}

.gl_input_disabled {
    background-color: $grey;
    cursor: default;
}

.char-count {
    text-align: left;
    text-transform: uppercase;
    font-family: $MontserratBold;
    color: $blue;
    font-size: 10px;
}

.gl_form {
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="password"],
    input[type="search"],
    input[type="number"] {
        font: 14px / 14px $Arial;
        height: 44px;
        padding: 0 30px 0 10px;
    }

    .gl_floating-label {
        font-size: 14px;
        font-weight: normal;
    }

    .gl_checkbox {
        label {
            font: 12px /14px $Arial;
        }
    }

    .input-checkbox + label {
        font: 12px / 14px $Arial;
    }

    select {
        padding: 0 30px 0 10px;
        height: 44px;
        font: normal 14px / 40px $Arial;
    }

    .gl_has-success .gl_input-success,
    .gl_input-success-container .gl_input-success {
        height: 9px;
        width: 13px;

        &::before {
            width: 5px;
        }

        &::after {
            width: 9px;
            left: 6px;
        }
    }

    .gl_input-success-container {
        width: 30px;
    }
}

input[type="number"] {
    // hide the spinner in Firefox
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .cc-cvn-wrapper & {
        text-align: left;
        -moz-appearance: textfield;
        padding: 0 vw(84px) 0 vw(30px);

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        @include media('>=tablet') {
            padding: 0 40px 0 8px;
        }
    }
}

input[type="search"] {
    appearance: none;
    border-radius: 0;
}

.gl_input-wrapper {
    position: relative;
    margin: 0;
    width: 100%;

    .gl_input-submit {
        position: absolute;
        height: 100%;
        width: vw(84px);
        text-align: center;
        right: 0;
        top: 0;
        cursor: pointer;
        // vertical centering
        display: flex;
        align-items: center;
        justify-content: center;

        @include media('>=desktop') {
            line-height: 42px;
            width: 40px;
        }
    }

    .gl_input-ok {
        user-select: none;

        .gl_form-group.gl_disabled & {
            color: $grey;
        }

        .gl_form-group.gl_has-success &,
        .gl_form-group.gl_loading & {
            display: none;
        }
    }

    .gl_input-map-container {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        width: vw(84px);
        min-width: 40px;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media('>=desktop') {
            width: 40px;
        }

        .gl_has-success & {
            display: none;
        }
    }

    .gl_input-magnifying-glass-container {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        width: vw(84px);
        min-width: 40px;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media('>=tablet', '<desktop') {
            width: 40px;
        }

        @include media('>=tablet') {
            width: 40px;
        }
    }

    .gl_input-map {
        position: relative;
        margin: 0 auto;
    }
}

.gl_ajax-wrapper {
    width: 42px;
    height: 42px;
    position: relative;
    margin: 10px auto;

    .gl_btn &,
    .gl_button &,
    .gl_view-more & {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}

.gl_ajax-loader {
    display: none;
    width: 38px;
    height: 38px;
    position: absolute;
    top: 2px;
    right: 2px;
    border-radius: 50%;
    background-color: $white;

    .gl_ajax-loader-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    &::before {
        z-index: 2;
        border: 2px solid $light-grey;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }

    &::after {
        z-index: 3;
        top: 2px;
        left: 2px;
        border: 2px solid transparent;
        border-top-color: $blue;
        transform-origin: center center;
        animation-name: spin;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-direction: normal;
        animation-iteration-count: infinite;
        animation-fill-mode: none;
        animation-play-state: running;
    }

    .gl_loading & {
        display: block;
    }
}

// Custom radio button options
.gl_group-options {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -8px;

    .gl_group-option {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 0 8px;
        margin: 10px 0 0;
    }

    .gl_group-option-btn {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        text-align: center;
        font: 10px / 12px $MontserratBold;
        text-transform: uppercase;
        height: 44px;
        border: solid 1px $blue-emperor;
        color: $blue-emperor;
        padding: 0 8px;
    }

    .gl_group-option-btn.gl_has-selected {
        background-color: $blue-emperor;
        color: $white;
    }
}

.gl_checkmark-container {
    width: 10px;
    display: none;
    margin: 0 5px 3px;

    // cover case with selected custom radio button option
    .gl_has-selected & {
        display: inline-flex;
    }
}

.gl_checkmark-success {
    height: 9px;
    width: 13px;
    position: relative;
    margin: 0 auto;

    // cover case with selected custom radio button option
    .gl_has-selected & {
        &::before {
            content: '';
            width: 5px;
            height: 2px;
            display: block;
            background-color: $white;
            position: absolute;
            left: 0;
            bottom: 0;
            transform-origin: bottom right;
            transform: rotate(45deg);
        }

        &::after {
            content: '';
            width: 9px;
            height: 2px;
            display: block;
            background-color: $white;
            position: absolute;
            left: 6px;
            bottom: 0;
            transform-origin: bottom left;
            transform: rotate(-45deg);
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(720deg);
    }
}

.gl_input-success-container {
    position: absolute;
    right: 0;
    top: 0;
    width: vw(84px);
    height: 100%;
    flex-direction: column;
    justify-content: center;
    display: none;

    .gl_has-success & {
        display: flex;
    }

    .gl_radio & { //not needed for radio and also breaks display
        display: none;
    }

    @include media('>=tablet') {
        width: 40px;
    }
}

.gl_input-success {
    height: 9px;
    width: 13px;
    position: relative;
    margin: 0 auto;

    .gl_input-select & {
        display: none;
    }

    .gl_has-success &,
    .gl_input-success-container & {
        &::before {
            content: '';
            width: 5px;
            height: 2px;
            display: block;
            background-color: $blue-hover;
            position: absolute;
            left: 0;
            bottom: 0;
            transform-origin: bottom right;
            transform: rotate(45deg);
        }

        &::after {
            content: '';
            width: 9px;
            height: 2px;
            display: block;
            background-color: $blue-hover;
            position: absolute;
            left: 6px;
            bottom: 0;
            transform-origin: bottom left;
            transform: rotate(-45deg);
        }

        @include media('>=tablet') {
            width: 22px;
            height: 12px;

            &::before {
                width: 9px;
            }

            &::after {
                width: 16px;
                left: 10px;
            }
        }

        @include media('>=desktop') {
            height: 9px;
            width: 13px;

            &::before {
                width: 5px;
            }

            &::after {
                width: 9px;
                left: 6px;
            }
        }
    }
}

.gl_form-group {
    padding-bottom: 30px;

    .search-suggestions-form & {
        padding-bottom: 0;
    }

    &.required {
        position: relative;

        .gl_error-message,
        .backend-error-message,
        .form-caption {
            position: absolute;
            bottom: 10px;
            left: 0;
        }
    }

    // FE optimize
    &.required.country {
        .form-caption {
            position: relative;
            bottom: 0;

            @include media('>=desktop') {
                position:absolute;
                width: 100%;
                left: 100%;
                margin-left: 20px;
                top: 0;
                line-height: 14px;
            }
        }
    }

    .gl_error-message,
    .backend-error-message {
        display: none;
    }

    &.gl_has-error {
        .form-caption {
            display: none;
        }

        .backend-error-message,
        .gl_error-message {
            display: block;
        }

        .backend-error-message + .gl_error-message {
            display: none;
        }
    }

    .gl_input-comment {
        text-align: left;
        text-transform: uppercase;
        font-family: $MontserratBold;
        color: $blue;
        font-size: 10px;
        padding: 4px 0;
    }
}

.form-caption,
.gl_error-message,
.backend-error-message {
    font: 11px $Arial;
    text-align: left;
    line-height: 20px;
    color: $red;
}

.error-form {
    color: $red;
    font: 11px $Arial;
    margin: 10px 0;

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.dialog-required {
    font-family: $Arial;
    font-size: vw(22px);
    text-transform: capitalize;
    color: $blue;
    margin: 15px 0;

    @include media('>=tablet') {
        font-size: vw(16px);
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.form-caption {
    font-family: $Arial;
    font-size: vw(24px);
    color: $blue;

    &.form-caption-top {
        margin: vw(10px) 0;
    }

    @include media('>=tablet') {
        font-size: 14px;
    }

    @include media('>=desktop') {
        font-size: 12px;

        &.form-caption-top {
            margin: 10px 0;
        }
    }
}

// EADA21-42 ADA #179, #102, #49 Meaning of the asterisks (*) is not defined.
.gl_form-mandatory {
    font: bold 12px / 16px $Arial;
    color: $blue;
    text-align: right;
    margin: 0 0 10px;
}

// =================================
//    slick slider arrows style override
// =================================
.slick-prev {
    @include carousel-arrow('prev');

    .slick-vertical & {
        @include carousel-arrow('up');
    }
}

.slick-next {
    @include carousel-arrow('next');

    .slick-vertical & {
        @include carousel-arrow('down');
    }
}

.carousel-with-triangle-arrows {
    // clean standard arrows
    .slick-arrow::before {
        width: auto;
        height: auto;
        border: 0;
        transform: none;
    }
}

// =================================
//    global hide class
// =================================
.gl_hidden,
.hidden {
    display: none !important;
}

.gl_invisible {
    visibility: hidden !important;
}

// =================================
//    global invisible class for price display logic
// =================================
.price-invisible {
    visibility: hidden;
}

// =================================
//    global breadcrumbs
// =================================
.breadcrumbs-wrapper {
    position: relative;
    padding: 15px;
    border: 1px solid $light-grey;
    border-width: 1px 0;
    z-index: 1;
    margin-bottom: 20px;

    @include media('>=tablet') {
        margin-bottom: 35px;
    }

    @include media('>=desktop') {
        border-width: 0 0 1px;
        margin-bottom: 0;
        padding: 15px 20px;
    }

    .breadcrumb-element,
    .breadcrumb-refinement {
        font: 12px/16px $Arial;
        color: $blue;
        text-decoration: none;

        &::after {
            content: '\002f';
            display: inline-block;
            margin-left: 5px;
        }

        &:last-child::after {
            display: none;
        }

        &:last-child {
            font-weight: bold;
        }
    }

    .breadcrumb-relax {
        display: none;
    }
}

// Mobile breadcrumbs with horizontal scroll and last part visible
.breadcrumbs-wrapper.gl_mobile-only {
    padding: 0;

    .breadcrumb {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding: 15px;
    }

    .breadcrumb-element {
        white-space: nowrap;

        &::after {
            margin: 0 5px;
        }

        &:last-child {
            padding-right: 15px;
        }
    }
}

// =================================
//    Seo links section (CLP & PDP)
// =================================
.gl_seo-section-wrapper {
    text-align: left;
    padding: 18px 15px 25px;
    margin-bottom: 5px;

    @include media('>=desktop') {
        padding: 28px 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    @include media('>=1200px') {
        padding: 28px calc((100vw - 1200px) / 2); // ~ max-width 1200px
        justify-content: center;
    }

    .seo-title {
        font: 16px/16px $Arial;
        letter-spacing: normal;
        text-transform: none;
        font-weight: bold;

        @include media('>=tablet') {
            margin-bottom: 20px;
        }

        @include media('>=desktop') {
            margin-bottom: 0;
            flex: 0 0 20%;
            text-align: center;
        }
    }

    .seo-separator {
        text-align: left;
        margin-left: -30px;

        @include media('>=tablet') {
            display: none;
        }
    }

    .seo-links-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;

        @include media('>=desktop') {
            flex: 0 0 80%;
        }
    }

    .seo-link-wrapper {
        margin: 0 23px 10px 0;
        width: 100%;

        @include media('>=tablet') {
            width: 30%;
            margin-right: 23px;
        }

        @include media('>=desktop') {
            padding: 5px 10px;
            margin: 0;
            flex: 0 0 auto;
        }
    }

    .seo-link {
        color: inherit;
        font: 12px/14px $Arial;
        word-break: break-word;
    }
}

.gl_price-beforePromo > span {
    color: $blue;
    text-decoration: line-through;
}

.gl_price-afterPromo,
.gl_price-afterPromo > span,
.promotional-message.gl_price-afterPromo {
    color: $red;
}

// =================================
//    Selects
// =================================
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: $white;
    border: 2px solid $blue;
    border-radius: 0;
    font: 16px $Arial;
    font-weight: bold;
    height: vw(84px);
    line-height: calc(11vw - 4px);
    min-height: 42px;
    padding: 0 vw(84px) 0 vw(30px);
    color: $blue;
    width: 100%;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;

    &:focus {
        outline-offset: 4px;
    }

    &::-ms-expand {
        display: none; // IE magic...
    }

    .gl_form-group.gl_disabled &,
    &[disabled] {
        background-color: $grey;
        color: $blue;
        cursor: default;
    }

    &[disabled]::-ms-value {
        color: $blue; // IE magic...
    }

    & option:checked {
        background-color: $blue;
        color: $white;
    }

    .gl_form-group.gl_has-success & {
        border-color: $blue-hover;
        color: $blue-hover;

        + .gl_input-success-container {
            pointer-events: none;
        }
    }

    .gl_form-group.gl_has-error & {
        border-color: $red;
        color: $red;
    }

    @include media('>=480px') {
        height: 42px;
        line-height: 38px; // minus border
        padding: 0 40px 0 8px;
        background-position: right 16px center;
    }

    @include media('>=desktop') {
        font-size: 11px;
    }
}

.gl_select {
    .gl_input-wrapper {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            margin: -2px 0 0;
            right: 15px;
            top: 50%;
            z-index: 1;
            @include triangle-down();
            pointer-events: none; // critical, else clicking on the triangle does not trigger the <select>
        }
    }
}

.gl_select-group {
    display: flex;
    justify-content: flex-start;

    .gl_input-wrapper {
        width: auto;
        flex: 1 1 auto;
    }

    #dob-month {
        border-width: 2px 0;
    }

    #dwfrm_billing_paymentMethods_creditCard_expiration_month {
        border-right-width: 0;
    }

    .gl_select {
        padding: 0 18px 0 8px;

        .gl_input-wrapper::before {
            right: 8px;
        }
    }
}

.gl_dob {
    .gl_input-wrapper {
        @extend .offscreen;
    }

    .form-caption {
        display: none;
    }
}

.gl_get-direction {
    width: 42px;
    height: 42px;
    border: 1px solid $light-grey;
    display: flex;
    align-content: stretch;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background-color: $white;
}

.account-benefits {
    padding: vw(10px) 0 vw(20px);
    font-family: $Arial;
    font-size: vw(24px);
    color: $blue;
    text-align: center;

    @include media('>=tablet') {
        padding: 10px 0 20px;
        font-size: 16px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.password-requirements {
    font-family: $Arial;
    font-size: vw(24px);
    color: $blue;
    margin-bottom: 10px;

    @include media('>=tablet') {
        font-size: 16px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

// =================================
//  Floating labels
// =================================
.gl_floating-label {
    position: absolute;
    top: 50%;
    left: 10px;
    pointer-events: none;
    transition: 0.2s ease all;
    transform: translateY(-50%);
    background: $white;
    padding: 0;
    font-weight: bold;
    text-transform: uppercase;
    color: $blue;
    width: calc(100% - 10px - 40px);
    text-align: left;
    font-size: 16px;
    line-height: 1;

    .gl_input-transparent_white & {
        color: $white;
        background: transparent;
    }

    .gl_input-transparent_white .input-text:focus ~ &,
    .gl_input-transparent_white .filled & {
        background: $blue;
    }

    @include media('>=desktop') {
        font-size: 11px;
    }

    .optional & {
        text-transform: none;
    }

    .gl_has-error & {
        font-weight: normal;
    }

    .input-textarea ~ &,
    .gl_select ~ &,
    .input-text:focus ~ &,
    .iti ~ &,
    .filled & {
        top: 1px;
        font-weight: normal;
        padding: 0 2px;
        font-size: 10px;
        width: auto;
        max-width: calc(100% - 20px);
    }

    .cc-number-wrapper &,
    &.dob-label,
    &.cc-expiration-label {
        z-index: 2;
        white-space: nowrap;
        width: auto;
    }

    &.dob-label {
        max-width: none;
    }
}

.consent-dialog {
    max-width: 500px;
}

.iti {
    .gl_input-wrapper & {
        display: block;
    }

    .gl_form-group.gl_has-success & {
        input::placeholder {
            color: $dark-grey;
        }
    }
}

.s.u.m.o.me input[type=checkbox] {
    -webkit-appearance: checkbox;
}

address {
    font-style: normal;
}

// ApplePay button on cart, minicart, pdp
.dw-apple-pay-button,
.dw-apple-pay-button:hover,
.dw-apple-pay-button:active {
    // specific way to overwrite iOS default styles using !important because NO apple-pay-wrapper wrapper in some cases
    background-color: $white !important;
    border-color: $blue !important;
    background-image: -webkit-named-image(apple-pay-logo-black) !important;
    background-size: 50% !important;
    height: 44px !important;
    margin: 0 !important;
    border-radius: 0 !important;
    min-width: auto !important;

    .pdpForm & {
        width: calc(100% - 30px) !important;
        margin: 10px 15px 0 !important;

        @include media('>=desktop') {
            width: 100% !important;
            margin: 10px 0 0 !important;
        }
    }

    .product-form-sticky-form-row & {
        order: 0;
        width: calc(50% - 16px) !important;
        flex-grow: 1;
        margin: 10px 8px 0 !important;
    }

    .mixmatch-form-col-2 .pdpForm & {
        width: 100% !important;
        margin: 10px 0 0 !important;
    }

    .cart-special-payments-wrapper & {
        margin: 15px 7px 0 !important;
    }

    .checkout-form & {
        background-color: $light-grey !important;
        margin: 15px 7px 0 !important;
    }
}

.apple-pay-wrapper {
    // hide applePay in minicart
    .mini-cart-content-inner & {
        display: none;
    }
}

.adyen-klarna-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $blue;
    text-align: center;
    vertical-align: middle;
    height: 44px;
    margin-top: 15px;
}

.klarna-payment-icon {
    width: auto;
    height: 30px;
}

.pay-pal-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $blue;
    text-align: center;
    line-height: 44px;
    height: 44px;

    &.disabled {
        border-color: $grey;
        cursor: default;
    }

    .paypalsvg {
        width: 17px;

        .st0 {
            fill: $blue;
        }
    }
}

.toggle-password {
    position: absolute;
    right: -18px;
    top: 13px;
    height: 16px;
    width: 16px;
    cursor: pointer;

    .st0 {
        fill: $blue;
    }

    .st1 {
        stroke: transparent;
    }

    &.active {
        .st1 {
            stroke: $blue;
        }
    }

    &:focus {
        outline-color: $blue;
    }
}

// Custom scrollbar to improve user experience on webkit touch devices
.forced-scrollbar {
    position: relative;
}

// hide scrollbar in webkit browsers (Chrome, Safari..)
.webkit-forced-scrollbar_content::-webkit-scrollbar {
    display: none;
}

// custom scrollbar thumb
.forced-scrollbar_horizontal-thumb {
    display: none;
    position: absolute;
    border-radius: 8px;
    background-color: $scrollbar-thumb-color;
    width: 0%; // width is calculated by js: (visible width .forced-scrollbar parent) / (complete width of .forced-scrollbar parent) * 100 %
    height: 5px;
    bottom: 0;
}

// custom scrollbar track
.forced-scrollbar_horizontal-track {
    display: none;
    content: '';
    background-color: $light-grey;
    width: 100%;
    height: 5px;
    margin: -5px 0 0; // put scrollbar under the content div to delivery scroll event on fake scroll bar area
}

.forced-scrollbar_rounded-track {
    border-radius: 8px;
}

.gl_tel {
    // put float label above outline around the .iti__selected-flag
    label {
        z-index: 1;
    }
}

// ESSC-2 - Service Cloud - live-to-case (chat) implementation
// chat button is under the UFD sticky bottom banner
body > .embeddedServiceHelpButton {
    z-index: 1001;
}

// override position on mobile for PDP page because of the sticky at the bottom
.pdp-service-cloud {
    @include media ('<tablet') {
        .embeddedServiceSidebar.modalContainer {
            z-index: 20001;
        }

        .embeddedServiceHelpButton .helpButton {
            bottom: 116px;
        }
    }
}

// override chat popup z-index on mobile so it can covers the page
.embeddedServiceSidebar.modalContainer {
    @include media ('<tablet') {
        z-index: 20001 !important;
    }
}

// chat popup content override
.dockableContainer {
    // chat fields: labels and inputs override
    .embeddedServiceSidebarFormField .uiInput .uiLabel-left {
        color: $blue;
    }

    // chat fields: inputs override
    .embeddedServiceSidebarFormField .slds-style-inputtext,
    .embeddedServiceSidebarFormField .slds-style-select {
        color: $blue;
        font: 16px $Arial;
        font-weight: normal;

        @include media('>=desktop') {
            font-size: 11px;
        }
    }

    // chat popup icons override
    button[embeddedService-chatHeader_chatHeader] {
        height: 24px;
        min-height: 24px;
        width: 24px;
        min-width: 24px;
    }

    button:hover[embeddedService-chatHeader_chatHeader]:before {
        top: 17px;
        width: 24px;
        height: 24px;
    }

    button.closeButton:hover[embeddedService-chatHeader_chatHeader]:before {
        right: 14px;
    }

    button.minimizeButton {
        margin-right: 2px;
        padding: 1px;
    }

    button.minimizeButton:hover[embeddedService-chatHeader_chatHeader]:before {
        right: 40px;
    }

    // chat fields: select options override
    .embeddedServiceSidebarFormField .uiInputSelect>select.select {
        text-transform: lowercase;
    }

    h2[embeddedService-chatHeader_chatHeader] {
        color: $white;
    }
}

.gl_accordion-fold,
.gl_accordion-deploy {
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-flow: row nowrap;

    .accordionplussvg,
    .accordionminussvg {
        height: 16px;
        width: 16px;
    }

    .st0 {
        fill: $blue;
    }
}

.tabs-accordion-header {
    &.active {
        .gl_accordion-fold {
            display: block;
        }

        .gl_accordion-deploy {
            display: none;
        }
    }
}

.gl_accordion-fold {
    display: none;
}

// Block with ratio content:
// this element defines child size with ratio w:h = 1:1
.aspect-ratio {
    position: relative;
    width: 100%;
    height: 0;
    // Height calculation: h/w * 100 = p%
    padding-bottom: 100%; // 100% of the width
}

.aspect-ratio-child {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

// video styles
.fluid-video-wrap {
    position: relative;
    padding-bottom: 56.25%; // height / width
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    .bynder-widget {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
    }
}

// ECM23-7 - Helper class to force Montserrat font
.gl_montserrat-font {
    font-family: $MontserratBold !important;
    text-transform: uppercase !important;
}

.link_btn {
    line-height: 44px;
}

// Google places
.googleplaces-suggestions {
    font-size: 12px;
    margin: 7px 0 10px;
}

.googleplaces-btn {
    text-align: left;
}

.googleplaces-li {
    margin: 0 0 5px;
}

.googleplaces-powered-by {
    width: auto;
}
