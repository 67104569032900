.h-anchorbanner-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 14px;
    background-color: $white;

    @include media('>=tablet') {
        gap: 36px;
    }

    &.overlap {
        position: absolute;
        width: 100%;
        margin-top: -40px;
    }

    &.sticky {
        position: fixed;
        width: 100%;
        z-index: 25000;
        background-color: $light-grey;
    }
}

.h-anchor-container {
    text-transform: uppercase;
    font-family: $MontserratBold;
    font-size: 10px;
    line-height: 12px;
    color: $blue;

    a {
        text-decoration: none;
    }

    @include media('>=tablet') {
        font-size: 12px;
        line-height: 14px;
    }
}
