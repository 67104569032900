.swimwearstyles-component {
    color: $blue;
    margin: 50px 0;
}

.swimwearstyles-carousel {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 40px;

    @include media('>=tablet') {
        margin: 0 50px;
    }

    .slick-arrow {
        position: absolute;
        top: 40%;
        width: 25px;
        height: 25px;
        z-index: 1;
    }

    .slick-prev {
        left: -30px;
    }

    .slick-next {
        right: -30px;
    }
}

.swimwearstyles-carousel:not(.slick-initialized) {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .swimwearstyles-tile,
    .swimwearstyles-part-tile {
        &.last-part-border {
            border-right: 1px solid $blue-light;
        }

        &:last-child {
            border: 0;
        }
    }
}

.swimwearstyles-carousel.slick-initialized {
    display: block;
}

.swimwearstyles-part-tile {
    position: relative;

    &.slick-active.last-part-border {
        border-right: 1px solid $blue-light;
    }
}

.swimwearstyles-part-top-title {
    font-size: 14px;
    letter-spacing: normal;
    text-align: center;
    padding: 10px;
    margin: 0 0 10px;
}

.swimwearstyles-part-tile-title {
    font-size: 14px;
    letter-spacing: normal;
    text-align: center;
    padding: 10px;
    margin: 0 0 10px;
    visibility: hidden;
}

.swimwearstyles-part-tile-title.visible {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 1;
    visibility: visible;
}

.swimwearstyles-part-tile-title.standard {
    visibility: visible;
}

.swimwearstyles-part-tile-title.offset {
    right: -50%;
}

.swimwearstyles-tile {
    text-align: center;
    padding: 10px;
    overflow: hidden;

    &.slick-active.last-part-border {
        border-right: 1px solid $blue-light;
    }

    // default width in grid
    width: calc(100% / 3);
    flex: 0 0 auto;

    @include media('>=tablet') {
        width: calc(100% / 4);
    }

    @include media('>=desktop') {
        width: calc(100% / 7);
    }
}

.swimwearstyles-tile-link {
    display: block;
    text-decoration: none;
}

.swimwearstyles-tile-title {
    font-size: 14px;
    letter-spacing: normal;
    margin: 0 0 5px;
}

.swimwearstyles-tile-desc {
    font-size: 12px;
    margin: 10px 0 0;
}

.swimwearstyles-parts-title-dots-wrapper {
    display: none;
}
