// =================================
//    Gift certificate
// =================================
$giftcert-bg-color: #7c83a0;

// Gift certificate landing page: common
.giftcert-separator {
    .svgwave {
        transform: scale(0.5);
    }

    .svgwavepath {
        stroke: $white;
    }

    &.giftcert-separator-blue {
        .svgwavepath {
            stroke: $blue;
        }
    }
}

.giftcertpurchase-btn {
    display: block;
    line-height: 44px;
    max-width: 230px;
    margin: 20px auto;

    @include media('>=desktop') {
        max-width: 330px;
    }

    &.giftcertpurchase-btn-wide {
        max-width: 400px;

        @include media('>=desktop') {
            max-width: 330px;
        }
    }
}

.giftcertpurchase-link {
    display: block;
    margin: 30px 0 0;
    color: $white;
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;

    &:hover {
        color: $white;
    }

    @include media('>=desktop') {
        font-size: 20px;
        line-height: 22px;
    }
}

// trick for proper anchor offset on scroll to the section
.giftcert-anchor {
    display: block;
    padding-top: 120px;
    margin-top: -120px;
}

// Gift certificate warning
.giftcert-warning {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    color: $blue-emperor;
    font-size: 12px;
    line-height: 14px;
    padding: 30px 50px;

    @include media('>=tablet') {
        font-size: 16px;
        line-height: 20px;
    }
}

.giftcert-warning-icon {
    padding: 0 20px 0 0;

    .moneysvg {
        width: 52px;
        height: 52px;
    }

    .st0 {
        fill: $blue-emperor;
    }
}

.giftcert-warning-text1 {
    font-weight: bold;
}

.giftcert-warning-link {
    color: $blue-emperor;
}

// Gift certificate details
.giftcertpurchase-section {
    position: relative;
    background-color: $giftcert-bg-color;
    color: $white;
}

.giftcertpurchase-right {
    position: relative;
    padding: 10px 20px 50px;
    text-align: center;

    @include media('>=tablet') {
        padding: 10px 50px 50px;
    }

    @include media('>=desktop') {
        @include absolute-vertical-center;

        left: 50%;
        text-align: left;
        max-width: 420px;
        padding: 50px 10px 50px 50px;
    }

    @include media('>=desktopLarge') {
        padding: 100px 10px 100px 50px;
    }
}

.giftcertpurchase-title {
    font-size: 26px;
    line-height: 30px;
    letter-spacing: normal;
    color: $white;

    @include media('>=desktop') {
        font-size: 32px;
        line-height: 36px;
    }
}

.giftcertpurchase-separator {
    margin: 20px 0;

    @include media('>=tablet') {
        margin: 20px 0 20px -30px;
    }
}

.giftcertpurchase-text {
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 30px;

    @include media('>=desktop') {
        font-size: 16px;
    }
}

.giftcertpurchase-links {
    .giftcertpurchase-banner-btn {
        @include media('>=desktop') {
            margin: 20px 0;
        }
    }
}

// Gift certificate balancedetails
.giftcert-balancedetails-section {
    text-align: center;
    color: $white;
    background-color: $giftcert-bg-color;
    padding: 40px 20px;

    .giftcert-not-supported & {
        background-color: $blue-emperor;
    }

    @include media('>=tablet') {
        padding: 60px 40px;
    }

    @include media('>=desktop') {
        padding: 80px 40px;
    }
}

.giftcert-balancedetails-title {
    font: bold 16px/18px $Arial;
    letter-spacing: normal;
    text-transform: none;
    color: $white;

    @include media('>=tablet') {
        font-size: 24px;
        line-height: 26px;
    }
}

.giftcert-balancedetails-separator {
    margin: 20px 0 30px;
}

.giftcert-balancedetails-text {
    font-size: 16px;
    line-height: 20px;
    max-width: 256px;
    margin: 0 auto 35px;
}

.giftcert-balancedetails-form {
    max-width: 335px;
    margin: 0 auto;
}

// special display with backend error
.giftcert-balancedetails-form.has-backend-error {
    .giftcert-balancedetails-field {
        padding: 0;
    }

    .giftcert-balancedetails-input {
        border-color: $red !important;
        color: $red !important;
    }

    .giftcert-balancedetails-remove {
        color: $red;
    }
}

.giftcert-balancedetails-fields {
    position: relative;
}

.giftcert-balancedetails-field.gl_has-success {
    .gl_input-success::before {
        content: none;
    }

    .gl_input-success::after {
        content: none;
    }
}

.giftcert-balancedetails-remove {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 12px;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    color: $blue;
    text-decoration: underline;
}

.giftcert-balancedetails-error {
    display: none;
    background-color: $red;
    color: $white;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    padding: 13px 5px;
    margin: 0 0 20px;
}

.giftcert-balancedetails-success {
    display: none;
    padding: 0 0 20px;
    letter-spacing: 1px;

    .balance-value {
        font-family: $MontserratBold;
        text-transform: uppercase;
        letter-spacing: normal;
    }
}

.giftcert-balancedetails-link {
    line-height: 44px;
}

// Gift certificate form
.giftcert-purchase {
    text-align: center;
    padding: 20px;
    color: $blue;

    @include media('>=tablet') {
        padding: 0 40px;
        background-color: $giftcert-bg-color;
    }

    @include media('>=desktop') {
        padding: 50px 57px 0;
    }
}

.giftcert-purchase-form {
    @include media('>=tablet') {
        background-color: $white;
        max-width: 910px;
        margin: 0 auto;
        padding: 40px;
    }

    @include media('>=desktop') {
        padding: 50px 80px;
    }

    .giftcert-calendar {
        .giftcert-alt-input,
        .flatpickr-month,
        .flatpickr-innerContainer {
            @include media('>=tablet') {
                max-width: 350px;
                margin: 0 auto;
            }
        }

        // hide altInput
        .giftcert-alt-input {
            display: none;
        }

        .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
            @include media('>=tablet') {
                right: calc((100% - 350px) / 2);
            }
        }

        .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
            @include media('>=tablet') {
                left: calc((100% - 350px) / 2);
            }
        }
    }
}

.giftcert-purchase-title {
    font: bold 16px/18px $Arial;
    letter-spacing: normal;
    text-transform: none;

    @include media('>=tablet') {
        font-size: 24px;
        line-height: 26px;
    }
}

.giftcert-purchase-separator {
    margin: 20px 0;
}

.giftcert-purchase-legend {
    font: 18px/20px $Arial;
}

.giftcert-purchase-amount-legend {
    margin: 0 0 10px;
}

.giftcert-purchase-details-legend {
    margin: 0 0 15px;
}

.giftcert-purchase-desc {
    font-size: 12px;
    line-height: 14px;
    color: $blue-emperor;
    max-width: 300px;
    margin: 0 auto 15px;

    @include media('>=tablet') {
        max-width: 500px;
    }
}

.giftcert-purchase-row {
    @include media('>=tablet') {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -8px;
    }

    .gl_form-group {
        @include media('>=tablet') {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 8px 30px;
        }
    }

    .gl_error-message {
        @include media('>=tablet') {
            padding: 0 8px;
        }
    }
}

.giftcert-purchase-amountfields {
    padding: 0 0 20px;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row wrap;
        margin: 0 0 30px;
        border-bottom: 1px solid $blue-light;
    }

    .gl_form-group {
        @include media('>=tablet') {
            flex: 0 0 50%;
            padding: 10px 0 0 16px;
            flex-grow: 1;
        }
    }

    .custom_form-group {
        padding: 0 0 15px;

        @include media('>=tablet') {
            flex: 0 0 calc(50% + 8px);
            padding: 0;
        }
    }

    .giftcert-purchase-freeamount {
        padding: 0 0 5px;
    }

    // deviation on fixed amount
    .gl_has-success .gl_input-success-container {
        display: none;
    }

    .gl_has-success .filled .gl_input-success-container {
        display: flex;
    }
}

.giftcert-purchase-amount-error {
    flex: 0 0 100%;
    max-width: 100%;
}

.giftcert-purchase-day-checkbox {
    text-align: left;
}

.giftcert-selected-date {
    font-size: 12px;
    line-height: 14px;
    padding: 0 0 15px;
}

.giftcert-selected-date-value {
    font-weight: bold;
}

.giftcert-purchase-error {
    display: none;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    padding: 0 0 20px;
}

// Gift certificate warning
.giftcert-faq {
    margin: 0 15px;

    @include media('>=desktop') {
        max-width: 820px;
        margin: 0 auto;
    }
}
