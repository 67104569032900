// Do not edit this file, everything will be removed after running gulp tasks

$sprite: (
      'close_x': (
        offset-x: 0px,
        offset-y: 0px,
        width: 20px,
        height: 20px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'complementary-gift-box': (
        offset-x: 0px,
        offset-y: -1594px,
        width: 95px,
        height: 105px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'express_delivery': (
        offset-x: 0px,
        offset-y: -403px,
        width: 67px,
        height: 34px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'facebook': (
        offset-x: 0px,
        offset-y: -81px,
        width: 24px,
        height: 24px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'family_all': (
        offset-x: 0px,
        offset-y: -575px,
        width: 50px,
        height: 50px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'family_all_hover': (
        offset-x: 0px,
        offset-y: -645px,
        width: 50px,
        height: 50px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'family_fatherson': (
        offset-x: 0px,
        offset-y: -715px,
        width: 50px,
        height: 50px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'family_fatherson_hover': (
        offset-x: 0px,
        offset-y: -785px,
        width: 50px,
        height: 50px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'family_motherdaughter': (
        offset-x: 0px,
        offset-y: -855px,
        width: 50px,
        height: 50px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'family_motherdaughter_hover': (
        offset-x: 0px,
        offset-y: -925px,
        width: 50px,
        height: 50px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'get_direction': (
        offset-x: 0px,
        offset-y: -257px,
        width: 28px,
        height: 28px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'get_direction_hover': (
        offset-x: 0px,
        offset-y: -305px,
        width: 28px,
        height: 28px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'gift': (
        offset-x: 0px,
        offset-y: -457px,
        width: 37px,
        height: 37px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'gift_box': (
        offset-x: 0px,
        offset-y: -1071px,
        width: 41px,
        height: 62px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'google': (
        offset-x: 0px,
        offset-y: -125px,
        width: 24px,
        height: 24px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'linkedin': (
        offset-x: 0px,
        offset-y: -169px,
        width: 24px,
        height: 24px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'pay_pal': (
        offset-x: 0px,
        offset-y: -40px,
        width: 87px,
        height: 21px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'pdp_zoom': (
        offset-x: 0px,
        offset-y: -353px,
        width: 30px,
        height: 30px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'return-policy': (
        offset-x: 0px,
        offset-y: -1153px,
        width: 64px,
        height: 64px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'secured_payment': (
        offset-x: 0px,
        offset-y: -995px,
        width: 61px,
        height: 56px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'send-us-an-email': (
        offset-x: 0px,
        offset-y: -1237px,
        width: 64px,
        height: 64px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'shipping-details': (
        offset-x: 0px,
        offset-y: -1321px,
        width: 64px,
        height: 64px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'tapzoom': (
        offset-x: 0px,
        offset-y: -1489px,
        width: 85px,
        height: 85px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'tapzoom_big': (
        offset-x: 0px,
        offset-y: -1719px,
        width: 174px,
        height: 174px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'track-my-order': (
        offset-x: 0px,
        offset-y: -1405px,
        width: 64px,
        height: 64px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'turtle_hover': (
        offset-x: 0px,
        offset-y: -514px,
        width: 41px,
        height: 41px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'turtle_loader': (
        offset-x: 0px,
        offset-y: -213px,
        width: 24px,
        height: 24px,
        total-width: 174px,
        total-height: 1893px,
        image: '../../images_active/sprites.png'
      ),
      'close_x_2x': (
        offset-x: 0px,
        offset-y: 0px,
        width: 40px,
        height: 40px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'complementary-gift-box_2x': (
        offset-x: 0px,
        offset-y: -3188px,
        width: 190px,
        height: 210px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'express_delivery_2x': (
        offset-x: 0px,
        offset-y: -806px,
        width: 134px,
        height: 68px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'facebook_2x': (
        offset-x: 0px,
        offset-y: -162px,
        width: 48px,
        height: 48px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'family_all_2x': (
        offset-x: 0px,
        offset-y: -1150px,
        width: 100px,
        height: 100px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'family_all_hover_2x': (
        offset-x: 0px,
        offset-y: -1290px,
        width: 100px,
        height: 100px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'family_fatherson_2x': (
        offset-x: 0px,
        offset-y: -1430px,
        width: 100px,
        height: 100px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'family_fatherson_hover_2x': (
        offset-x: 0px,
        offset-y: -1570px,
        width: 100px,
        height: 100px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'family_motherdaughter_2x': (
        offset-x: 0px,
        offset-y: -1710px,
        width: 100px,
        height: 100px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'family_motherdaughter_hover_2x': (
        offset-x: 0px,
        offset-y: -1850px,
        width: 100px,
        height: 100px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'get_direction_2x': (
        offset-x: 0px,
        offset-y: -514px,
        width: 56px,
        height: 56px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'get_direction_hover_2x': (
        offset-x: 0px,
        offset-y: -610px,
        width: 56px,
        height: 56px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'gift_2x': (
        offset-x: 0px,
        offset-y: -914px,
        width: 74px,
        height: 74px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'gift_box_2x': (
        offset-x: 0px,
        offset-y: -2142px,
        width: 82px,
        height: 124px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'google_2x': (
        offset-x: 0px,
        offset-y: -250px,
        width: 48px,
        height: 48px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'linkedin_2x': (
        offset-x: 0px,
        offset-y: -338px,
        width: 48px,
        height: 48px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'pay_pal_2x': (
        offset-x: 0px,
        offset-y: -80px,
        width: 174px,
        height: 42px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'pdp_zoom_2x': (
        offset-x: 0px,
        offset-y: -706px,
        width: 60px,
        height: 60px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'return-policy_2x': (
        offset-x: 0px,
        offset-y: -2306px,
        width: 128px,
        height: 128px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'secured_payment_2x': (
        offset-x: 0px,
        offset-y: -1990px,
        width: 122px,
        height: 112px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'send-us-an-email_2x': (
        offset-x: 0px,
        offset-y: -2474px,
        width: 128px,
        height: 128px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'shipping-details_2x': (
        offset-x: 0px,
        offset-y: -2642px,
        width: 128px,
        height: 128px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'tapzoom_2x': (
        offset-x: 0px,
        offset-y: -2978px,
        width: 170px,
        height: 170px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'tapzoom_big_2x': (
        offset-x: 0px,
        offset-y: -3438px,
        width: 348px,
        height: 348px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'track-my-order_2x': (
        offset-x: 0px,
        offset-y: -2810px,
        width: 128px,
        height: 128px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'turtle_hover_2x': (
        offset-x: 0px,
        offset-y: -1028px,
        width: 82px,
        height: 82px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
      'turtle_loader_2x': (
        offset-x: 0px,
        offset-y: -426px,
        width: 48px,
        height: 48px,
        total-width: 348px,
        total-height: 3786px,
        background-size: 348px,
        image: '../../images_active/sprites_2x.png'
      ),
);




@mixin sprite-size($image) {
  background-size: map-get(map-get($sprite, $image), 'total-width') map-get(map-get($sprite, $image), 'total-height');
}

@mixin sprite-width($image) {
  width: map-get(map-get($sprite, $image), 'width');
}

@mixin sprite-height($image) {
  height: map-get(map-get($sprite, $image), 'height');
}

@mixin sprite-position($image) {
  background-position: map-get(map-get($sprite, $image), 'offset-x') map-get(map-get($sprite, $image), 'offset-y');
}

@mixin sprite-image($image) {
  background-image: url(map-get(map-get($sprite, $image), 'image'));
}

// useful functions
@function get-sprite-width($image) {
  @return map-get(map-get($sprite, $image), 'width');
}

@function get-sprite-height($image) {
  @return map-get(map-get($sprite, $image), 'height');
}

// Generate certain image sprite
// Arguments
// - $image : sprite image name
// - $showWidth : adds width option to sprite.
// - $showHeight : adds height option to sprite.
@mixin sprite($image, $showWidth: true, $showHeight : true) {
  @include sprite-image($image);
  @include sprite-position($image);
  background-repeat: no-repeat;

  @if $showWidth {
    @include sprite-width($image);
  }

  @if $showHeight {
    @include sprite-height($image);
  }

}

// Useful to generate all sprites - Optimized for vertical aligned sprites
// Arguments
// - $showWidth : adds width option to sprite.
// - $showHeight : adds height option to sprite.
@mixin sprites( $showWidth : false, $showHeight : true ) {
  @each $icon-name, $icon-properties in $sprite {
    .#{$icon-name} { @include sprite( $icon-name, $showWidth, $showHeight ); }
  }
}



// Generate all sprites by default
// Add `$generate-all-sprites : false;` into your settings to skip this

$generate-all-sprites : false !default;
@if $generate-all-sprites {
  @include sprites;
}
