$textforcarousel-blue: #3a5570;

.image-carousel-text-carousel-tile {
    position: relative;
    padding: 60px 20px 20px;

    @include media('>=desktop') {
        padding: 80px 80px 65px;
    }
}

.textforcarousel-component {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: $textforcarousel-blue;
    height: 100%;
}

.textforcarousel-img-wrapper {
    margin: 0 auto 25px;
}

.textforcarousel-img {
    width: auto;
}

.textforcarousel-title {
    font: 16px / 1 $TimesNewRoman;
    font-style: italic;
    text-transform: none;
    letter-spacing: normal;
    color: $textforcarousel-blue;

    @include media('>=tablet') {
        font-size: 28px;
    }

    @include media('>=desktop') {
        font-size: 33px;
    }
}

.textforcarousel-subtitle {
    font: 30px / 1 $MontserratBold;
    text-transform: none;
    letter-spacing: normal;
    color: $textforcarousel-blue;

    @include media('>=tablet') {
        font-size: 38px;
    }

    @include media('>=desktop') {
        font-size: 47px;
    }
}

.textforcarousel-text {
    max-width: 80%;
    margin: 0 auto 90px;

    @include media('>=desktop') {
        max-width: none;
        margin: 0;
    }
}

.textforcarousel-count {
    background: $textforcarousel-blue;
    color: $white;
    padding: 15px;
    width: 100%;
    margin: auto 0 0;

    @include media('>=tablet') {
        max-width: 350px;
        margin: auto auto 0;
    }

    @include media('>=desktop') {
        position: absolute;
        background: none;
        color: $textforcarousel-blue;
        margin: 0;
        max-width: none;
        width: auto;
        left: 80px;
        right: auto;
        padding: 0;
        bottom: 20px;
    }
}
