.h-herobanner-container {
    position: relative;
}

.h-herobanner-content {
    @include absolute-center;
    width: 100%;
    text-align: center;
    padding: 10px;
    z-index: 1;
}

.h-herobanner-title {
    font-size: 30px;
    line-height: 37px;
    text-transform: uppercase;
    color: $white;
    margin: 0;

    @include media('>=desktop') {
        font-size: 45px;
        line-height: 50px;
    }
}

.h-herobanner-logo-image {
    max-width: 155px;
    max-height: 66px;
    margin: 0 auto 10px;

    @include media('>=tablet') {
        margin: 0 auto 40px;
    }

    @include media('>=desktop') {
        margin: 0 auto 70px;
    }

    @include media('>=desktopLarge') {
        margin: 0 auto 85px;
    }
}
