// =================================
//    My account
// =================================

// Login page
.myaccount-login-wrapper.row {
    justify-content: flex-start;
    align-content: center;
    align-items: stretch;
}

.myaccount-login-fullcol {
    width: 100%;
    text-align: center;
}

.myaccount-section-item {
    @include media('<desktop') {
        padding: 0;
    }
}

.myaccount-login-col {
    display: none;
    padding: 0 8%;

    @include media('>=desktop') {
        display: block;
        padding: 0 10px;
    }

    &.forced-active {
        display: block;
    }
}

.login-box {
    position: relative;
    height: 100%;
    padding: vw(40px) 0;

    @include media('>=desktop') {
        padding: 10%;
    }
}

.myaccount-login-title {
    display: inline-block;
    margin: vw(70px) 0 0;
    font-size: vw(48px);

    @include media('>=tablet') {
        margin: 70px 0 0;
        font-size: vw(38px);
    }

    @include media('>=desktop') {
        font-size: 24px;
    }
}

.myaccount-login-title-separator {
    margin: vw(20px) 0 vw(90px);

    @include media('>=desktop') {
        margin: 20px 0 50px;
    }
}

.login-benefits-title {
    margin: 0 0 20px;
}

.login-link-form {
    .toggle-password {
        width: 24px;
        height: 14px;
        top: 15px;
        right: -27px;
    }
}

.login-section-title {
    width: 100%;
    text-align: left;
    font-size: vw(28px);

    .myaccount-login-col & {
        display: none;

        @include media('>=desktop') {
            display: block;
        }
    }

    @include media('>=desktop') {
        margin-bottom: 0;
        text-align: center;
        font-size: 14px;
    }
}

.login-button-bottom {
    @include media('>=tablet') {
        height: 44px;
    }
}

.login-actions-wrapper {
    margin: 0;
    align-items: flex-start;
    justify-content: space-between;
}

.login-benefits-link,
.login-order-link {
    @include media('>=desktop') {
        .myaccount-login-wrapper & {
            position: absolute;
            bottom: 10%;
            left: 10%;
            width: 80%;
        }
    }
}

.login-account-separator,
.login-order-separator {
    margin-top: 20px;
}

.login-account-separator {
    margin-bottom: 30px;
}

.login-account-form-util {
    font: 12px / 24px $Arial;
    color: $blue;
    padding: 0 0 15px;
}

.login-account-forgot {
    font: 12px / 24px $Arial;
}

.login-account-rememberme {
    > .form-caption {
        display: none;
    }
}

.myaccount-login-benefits {
    text-align: center;
    background: $grey-bg;
    color: $blue;
    font-size: vw(28px);

    @include media('>=desktop') {
        font-size: 16px;
    }
}

.myaccount-login-benefits-separator {
    margin: 20px 0 0;
}

.myaccount-login-order {
    color: $blue;
}

.login-order-paragraph {
    font-size: vw(24px);

    @include media('>=tablet') {
        font-size: vw(20px);
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.login-orderpo {
    margin-bottom: 10px;
}

.myaccount-reasurrance-wrapper {
    @include media('>=desktop') {
        margin: 50px 0;
    }
}

// Account navigation
.navigation-registered {
    @include media('>=desktop') {
        background: $grey-bg;
        padding: 15px 0;
        text-align: center;
    }
}

.account-navigation-list-item {
    font-family: $MontserratBold;
    padding: 20px 5%;

    @include media('>=desktop') {
        font-family: $Arial;
        display: inline-block;
        vertical-align: middle;
        padding: 0 15px;
        border-right: 1px solid $blue;

        &:last-child {
            border: 0;
        }
    }
}

.account-navigation-list-link {
    position: relative;
    font-size: vw(28px);
    text-transform: uppercase;
    text-decoration: none;

    @include media('>=desktop') {
        float: left;
        font-size: 10px;
    }

    &.active::before {
        content: '';
        display: block;
        position: absolute;
        bottom: -10px;
        margin: 2px 0 0;
        height: 5px;
        width: 100%;
        background-color: $blue-hover;
    }
}

.account-navigation-list-mobile {
    width: 100%;
}

.acc-selectmenu-menu {
    display: none;

    &.ui-selectmenu-open {
        display: block;
    }

    .ui-menu {
        background: $white;
    }

    .ui-menu-item {
        font-family: $MontserratBold;
        font-size: vw(28px);
        padding: 0 10%;
        border-top: 1px solid $blue-light;
        text-transform: uppercase;
        color: $blue;
        height: 50px;
        line-height: 50px;

        @include media('>=tablet') {
            padding: 0 10%;
            font-size: vw(20px);
        }

        &:last-child {
            border-bottom: 1px solid $blue-light;
        }
    }
}

.acc-selectmenu-button {
    position: relative;
    display: block;
    background: $light-grey;
    font-family: $MontserratBold;
    font-size: vw(28px);
    padding: 0 5%;
    border-top: 1px solid $blue-light;
    text-transform: uppercase;
    color: $blue;
    height: 50px;
    line-height: 50px;

    @include media('>=tablet') {
        padding: 0 5%;
        font-size: vw(20px);
    }
}

// Registration page
.registration-title-section {
    order: -1;

    @include media('>=desktop') {
        order: 0;
    }

    .profile-separator {
        margin: 20px 0;

        @include media('>=desktop') {
            margin: 20px 0 30px;
        }
    }
}

// Edit profile page
.editprofile-wrapper {
    margin: vw(100px) 0;
    justify-content: center;
    align-items: flex-start;
    align-content: center;

    @include media('>=desktop') {
        margin: 70px auto;
    }
}

.editprofile-section {
    & + & {
        margin-top: 100px;

        @include media('>=desktop') {
            margin-top: 0;
        }
    }

    .row {
        margin: 0 -10px;
    }
}

.profile-field-spacing {
    margin-bottom: vw(60px);

    &.gl_has-error {
        margin: 0;
    }

    @include media('>=tablet') {
        margin-bottom: 30px;
    }
}

.profile-equal-spacing {
    margin: 15px 0;
}

.profile-privacy-policy {
    text-align: center;
    text-transform: none;
    margin: 0 0 vw(20px);

    @include media('>=desktop') {
        margin: 0 0 10px;
    }
}

.profile-separator {
    margin: 20px 0 vw(60px);

    @include media('>=desktop') {
        margin: 20px 0 30px;
    }
}

.editprofile-section-title {
    text-align: center;
    font-size: vw(28px);

    @include media('>=desktop') {
        font-size: 14px;
    }
}

.profile-newsletter-checkbox {
    margin: 15px 0;
}

// My account saved address section
.myaccount-section-wrapper {
    position: relative;
    margin: vw(70px) 0;
    color: $blue;
    font-size: vw(24px);

    @include media('>=desktop') {
        font-size: 12px;
        margin: 70px 0;
    }

    .account-address-loader {
        .gl_ajax-wrapper {
            align-items: flex-start;
        }
    }

    .gl_form-group {
        margin: 0 0 15px;
    }
}

.myaccount-button-wrap {
    margin: 0 auto;

    @include media('>=desktop') {
        max-width: 280px;
    }
}

.myaccount-section-title {
    text-align: center;
    font-size: vw(28px);

    @include media('>=desktop') {
        font-size: 14px;
    }
}

.myaccount-section-subtitle {
    text-align: center;
}

.address-tile {
    font-size: vw(24px);
    color: $blue;
    line-height: 1.5;
    margin: 0 0 vw(60px);
    display: inline-block;
    vertical-align: top;
    width: 100%;

    @include media('>=tablet') {
        font-size: 12px;
    }

    @include media('>=desktop') {
        width: 35%;
        margin: 0 0 30px 25%;

        &:nth-child(2n) {
            margin: 0 0 30px;
        }

        &.default-address {
            display: block;
            width: 50%;
            margin: 0 auto 30px;
        }
    }

    address {
        font-style: normal;
    }
}

.myaccount-address-actions {
    font-size: 0;
}

.myaccount-address-action-separator,
.myaccount-address-action {
    display: inline-block;
    font-size: vw(24px);

    @include media('>=tablet') {
        font-size: 12px;
    }
}

.myaccount-address-action {
    padding: 0 vw(16px);

    @include media('>=desktop') {
        padding: 0 8px;
    }

    &.myaccount-address-first-action {
        padding-left: 0;
    }
}

.mini-address-name {
    .mini-address-title & {
        font-family: $Arial;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0 0 5px;
    }
}

.mini-address-location {
    margin: 0 0 10px;
}

.myaccount-popup-wrapper {
    margin: 20px 0;
    font-size: 12px;
    color: $blue;
    justify-content: center;
    flex-flow: row wrap;

    .address-dynamic-form {
        .row {
            margin-left: -10px;
            margin-right: -10px;
        }

        & > .form-col {
            padding: 0;
        }
    }
}

.account-popup-title {
    font-size: vw(24px);
    text-align: center;
    margin: 0 0 30px;

    @include media('>=desktop') {
        font-size: 24px;
    }
}

.address-form-row-button {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.apply-button-wrapper,
.cancel-button-wrapper {
    flex: 0 0 47%;
    max-width: 47%; //IE adds padding ignoring border-box for flex children
}

.delete-button-wrapper {
    flex: 0 0 100%;
    margin: 15px 0 0;
}

// Payments instruments
.add-card {
    text-align: center;
}

.delete-card {
    text-decoration: underline;
}

.payment-list {
    margin: vw(20px) 0;

    @include media('>=desktop') {
        margin: 20px 0;
    }
}

.payment-list-item {
    font-size: vw(24px);
    color: $blue;
    line-height: 1.5;
    margin: 0 0 vw(60px);
    display: inline-block;
    vertical-align: top;
    width: 100%;

    @include media('>=desktop') {
        width: 30%;
        font-size: 12px;
        margin: 0 0 30px 30%;

        &:nth-child(2n) {
            margin: 0 0 30px;
        }
    }
}

.form-label-text {
    font-size: 10px;
    text-transform: uppercase;
}

// Password management
.setnewpassword-wrapper {
    justify-content: center;
    flex-flow: row wrap;
}

.account-popup-titlesubtitle {
    text-align: center;
}

// Wishlist section
.wishlist-wrapper {
    margin: 70px auto;

    .product-image:hover {
        .product-fake-btn {
            display: none;
        }
    }

    .product-tile-wrapper {
        padding-bottom: vw(120px);
        margin: 0 0 40px;

        .wishlist-add-to-cart-wrapper {
            position: absolute;
            bottom: 0;
            width: calc(100% - 20px);
        }

        @include media('>=tablet') {
            margin: 0 0 60px;
            padding-bottom: 60px;
        }
    }

    .tiles-container {
        margin: 40px 0 0;
    }

    .wishlist-product-size {
        display: block;
    }
}

.wishlist-delete-item {
    cursor: pointer;
    position: absolute;

    .product-tile-wrapper & {
        top: -16px;
        right: 7px;

        @include media('>=tablet') {
            top: -20px;
        }
    }

    .wishlist-favorite-store & {
        top: 10px;
        right: 10px;
    }
}

.wishlist-favorite-store-wrapper {
    padding: 10px;
}

.wishlist-favorite-store {
    border: 1px solid $light-grey;
    padding: 4px;
    position: relative;
    width: 100%;
}

.wishlist-store-separator {
    margin: 20px 0 30px;
}

.wishlist-favorite-store-image {
    flex: 0 0 22%;
}

.wishlist-pickup-available {
    font-family: $MontserratBold;
    font-size: vw(22px);
    color: $blue;
    margin: 10px 0 0;
    text-transform: uppercase;

    @include media('>=desktop') {
        font-size: 11px;
    }
}

.wishlist-favorite-store-details {
    flex: 0 0 78%;
    padding: 0 35px 0 20px;
}

.wishlist-favorite-store-title {
    font-size: vw(24px);
    margin: 0;

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.wishlist-favorite-store-subtitle {
    font-size: vw(22px);
    color: $blue;
    margin: 5px 0 10px;
    text-align: left;

    @include media('>=desktop') {
        font-size: 12px;
    }
}

a.wishlist-store-discover {
    display: block;
    margin: 0 0 10px;
    font-size: 16px;

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.wishlist-store-mystore {
    width: auto;
    color: $blue-hover;
    border-color: $blue-hover;
}

// Newsletter section
.myaccount-newsletter-wrapper {
    margin: 70px auto;
    justify-content: center;
    flex-flow: row wrap;
}

.newsletter-separator {
    margin: 20px 0 30px;
}

.myaccount-newsletter-title {
    text-align: center;
    font-size: vw(28px);

    @include media('>=desktop') {
        font-size: 14px;
    }
}

.newsletter-subscription-label {
    font-family: $MontserratBold;
    text-transform: uppercase;
    float: left;
    font-size: vw(24px);
    margin: vw(10px) 0;


    @include media('>=desktop') {
        margin: 5px 0;
        font-size: 12px;
    }
}

.newsletter-subscription-list {
    color: $blue;
    margin: 0 0 30px;
}

.newsletter-subscription-form {
    .ui-checkboxradio-label {
        margin: 0 10px 0 0;
    }
}

.newsletter-subscription-choices {
    text-align: right;
}

// Order history section

.orderhistory-wrapper {
    margin: 70px auto;
}

.orderhistory-title {
    text-align: center;
    font-size: vw(28px);

    @include media('>=desktop') {
        font-size: 14px;
    }
}

.orderhistory-table {
    text-transform: uppercase;
    color: $blue;
    font-size: vw(20px);

    @include media('>=desktop') {
        font-size: 11px;
    }
}

.orderhistory-order {
    padding: 20px 0;

    @include media('>=desktop') {
        border: 1px solid $blue-light;
        border-width: 1px 0 0;

        &:last-child {
            border-width: 1px 0;
        }
    }
}

.orderhistory-order-items-heading {
    font-family: $MontserratBold;
    font-size: vw(22px);
    margin: 0 0 20px;

    @include media('>=desktop') {
        font-size: 12px;
    }

    sup {
        font-size: vw(5px);
        top: -1em;

        @include media('>=desktop') {
            font-size: 5px;
        }
    }
}

.orderhistory-order-items-spacer {
    display: block;
    margin: 5px 0;
}

.orderhistory-td {
    padding: vw(20px) 0;

    @include media('>=desktop') {
        padding: 0;
    }
}

.orderhistory-orderdetails {
    padding: vw(60px) 0;

    @include media('>=tablet') {
        padding: 10px 0;
    }

    @include media('>=desktop') {
        padding: 0;
    }
}

// Order history pagination

.orderhistory-content {
    .search-result-options {
        padding: 0;
        margin: vw(40px) 0 0;
        font-size: vw(28px);
        color: $blue;

        @include media('>=desktop') {
            margin: 20px 0 0;
            font-size: 14px;
        }
    }
}

.results-hits {
    margin: vw(40px) 0;

    @include media('>=desktop') {
        margin: 20px 0;
    }
}

.paging-item {
    float: left;
    margin: 0 vw(20px) vw(20px) 0;

    @include media('>=desktop') {
        margin: 0 10px 10px 0;
    }
}

// My account dashboard
.myaccount-dashboard-container.row {
    margin: 70px 0;
    justify-content: center;
}

.myaccount-dashboard-col {
    position: relative;

    @include media('>=desktop') {
        padding: 0 0 62px;
    }
}

.myaccount-dashboard-wishlist {
    margin: 0 0 vw(100px);

    @include media('>=desktop') {
        margin: 0;
    }
}

.myaccount-dashboard-orders {
    font-size: vw(24px);
    color: $blue;
    text-align: center;

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.myaccount-dashboard-order {
    text-transform: uppercase;
    color: $blue;
    font-size: vw(20px);
    margin: 0 0 vw(60px);

    @include media('>=desktop') {
        font-size: 11px;
        margin: 0;
    }
}

.myaccount-dashboard-title {
    text-align: center;
    font-size: vw(28px);

    @include media('>=desktop') {
        font-size: 14px;
    }
}

.myaccount-dashboard-separator {
    margin: vw(30px) 0 vw(50px);

    @include media('>=desktop') {
        margin: 20px 0 30px;
    }
}

.myaccount-dashboard-favorites-list {
    margin: 0 0 vw(40px);

    @include media('>=desktop') {
        margin: 0;
    }
}

.myaccount-dashboard-button {
    @include media('>=desktop') {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}

.myaccount-dashboard-favorites-item {
    float: left;
    width: 33%;
}


/////////////////////////////////
//  Check order section
/////////////////////////////////

.orderdetails {
    font-size: vw(28px);
    color: $blue;
    text-transform: uppercase;
    padding: vw(40px) 2%;
    margin: vw(140px) 0 0;

    @include media('>=tablet') {
        font-size: 20px;
    }

    @include media('>=desktop') {
        font-size: 10px;
        padding: 20px 2%;
        margin: 70px 0 0;
    }
}

.orderdetails-heading {
    text-align: center;
    font-size: vw(64px);

    @include media('>=tablet') {
        font-size: 32px;
    }
}

.orderdetails-section {
    margin: 0 0 50px;
}

.orderdetails-summary-column {
    flex: 1 1 100%;

    @include media('>=desktop') {
        flex: 1 1 25%;
    }
}

.orderdetails-column-title {
    padding: 20px 0;
    border-bottom: 1px solid $light-grey;
}

.orderdetails-column-content {
    padding: 20px 0 0;
}

.orderdetails-orderinformation {
    flex-flow: row wrap;

    @include media('>=desktop') {
        flex-flow: row nowrap;
    }
}

.orderdetails-shipments {
    flex-flow: row wrap;
}

.orderdetails-ordertotal {
    padding: 0 0 20px;
}

.orderdetails-actions {
    text-align: center;
    margin: 0 2% vw(140px);

    @include media('>=desktop') {
        margin: 0 2% 70px;
    }
}

.orderdetails-return-link {
    margin: vw(30px) auto vw(70px);

    @include media('>=desktop') {
        margin: 40px auto 70px;
    }
}

.consent {
    margin: 20px 0 30px;

    .gl_form-group {
        padding-bottom: 0;

        &.gl_checkbox.profile-equal-spacing {
            .gl_error-message,
            .backend-error-message,
            .form-caption {
                bottom: -18px;
            }
        }
    }

    label {
        color: $blue;
    }

    .gl_form-group,
    .consent-date {
        + .gl_form-group {
            margin-top: 10px;
        }
    }
}

.consent-group {
    flex-wrap: wrap;

    @include media('>=tablet') {
        flex-wrap: nowrap;
    }

    .consent-checkbox {
        flex: 0 0 100%;

        @include media('>=tablet') {
            flex-basis: 65%;
        }
    }

    .consent-date {
        flex: 0 0 100%;
        padding-top: 5px;
        padding-left: 30px;
        align-self: flex-start;

        @include media('>=tablet') {
            flex-basis: 35%;
            padding-top: 0;
            padding-left: 20px;
            align-self: center;
        }
    }

    + .consent-group {
        margin-top: 20px;
    }
}

.consent-date {
    font: vw(24px) $Arial;
    color: $blue;
    font-style: italic;

    @include media('>=tablet') {
        font-size: 12px;
    }
}

.consent-disclamer,
.consent-description {
    font: vw(28px) $Arial;
    color: $blue;

    @include media('>=tablet') {
        font-size: 14px;
    }
}

.consent-disclamer {
    margin-top: 20px;

    .consent-disclamer-br {
        display: none;
    }
}

// Registration Dialog
.registrationDialog {
    color: $blue;

    .ui-dialog-titlebar {
        height: 0;
    }

    .ui-dialog-titlebar-close {
        top: 8px;
        right: 8px;
        z-index: 3;

        .ui-button-icon::before {
            font-size: 36px;
        }
    }
}

.registration-dialog-form-wrapper {
    background-color: $white;
    padding: 49px 18px 18px;

    @include media('>=desktop') {
        padding: 18px;
    }

    .row {
        margin: 0 -10px;
    }

    .gl_form-group {
        padding-bottom: 22px;

        &.gl_checkbox {
            padding-bottom: 0;
        }

        &.required {
            .gl_error-message,
            .backend-error-message,
            .form-caption {
                bottom: 5px;
            }
        }
    }
}

.registration-dialog-title {
    font-size: 20px;
    line-height: 20px;
    letter-spacing: normal;
    margin-bottom: 12px;

    @include media('>=desktop') {
        font-size: 28px;
        line-height: 28px;
    }
}

.registration-dialog-subtitle {
    display: block;
    text-decoration: none;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 26px;

    @include media('>=desktop') {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 20px;
    }
}

.registration-dialog-error-button,
.registration-dialog-success-button,
.registration-dialog-form-submitbutton {
    font-size: 14px;
}

.registration-dialog-error-button,
.registration-dialog-title,
.registration-dialog-subtitle,
.registration-dialog-form-submitbutton {
    font-family: $MontserratBold;
    text-transform: uppercase;
    text-align: center;
}

.registration-dialog-button {
    margin: 0 auto;
    display: block;
}

.registration-dialog-success-wrapper,
.registration-dialog-error-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: $white;
}

.registration-dialog-content {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 90%;
    max-width: 90%;
    transform: translate(-50%, -50%);
}

.registration-dialog-success-subtitle,
.registration-dialog-error-subtitle {
    font-size: 18px;
    margin-bottom: 20px;
}

.registration-dialog-success-title,
.registration-dialog-error-title {
    font-size: 28px;
    margin-bottom: 20px;
}

.registration-dialog-information {
    position: relative;
    color: $blue;
    font-family: $Arial;
    font-size: 9px;
    text-align: center;
    margin: 10px 0 6px;

    a {
        color: inherit;
    }
}

// css hack to have proper anchor view on scroll if sticky panel is
.nl-registration-us {
    .registration-form {
        position: relative;
        z-index: 1;
    }

    .registration-dialog-information-target {
        margin-top: -110px;
        padding-top: 120px;
        z-index: -1;
    }
}

.registration-dialog-content-wrapper {
    position: relative;

    @include media('>=desktop') {
        order: 2;
        flex: 0 0 50%;
    }
}

.registration-dialog-wrapper {
    @include media('>=desktop') {
        display: flex;
        justify-content: center;
        align-items: stretch;
        align-content: stretch;
    }
}

.registration-dialog-image-wrapper {
    position: relative;
    margin-bottom: -1px;

    @include media('>=desktop') {
        order: 1;
        flex: 0 0 50%;
    }
}

.registration-dialog-error-image,
.registration-dialog-success-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

// Tracking shipment order
.track-shipment-order-list {
    text-align: center;
}

.track-shipment-order-item {
    margin-bottom: 3px;
}

.track-shipment-order-item:first-child {
    margin-top: 20px;
}

.track-shipment-order-item:last-child {
    margin-bottom: 0;
}

.track-shipment-order-link {
    font-size: vw(24px);
    text-align: center;

    @include media('>=desktop') {
        font-size: 14px;
    }
}

// Tracking shipment order in Account dashboard
.myaccount-dashboard-wrapper {
    .track-shipment-order-item:last-child {
        @include media('>=desktop') {
            margin: 0 0 40px;
        }
    }
}

.password-validation {
    font-family: $Arial;
    font-size: vw(24px);
    color: $blue;
    text-align: left;

    @include media('>=tablet') {
        font-size: 16px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }

    .success {
        color: $ecogreen;
    }

    .error {
        color: $red;
    }
}

.password-validation-mobile {
    margin-bottom: 12px;
    margin-top: -12px;
    text-align: left;
}
