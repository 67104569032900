// =================================
//    PDP
// =================================

// EPU-1 clean up
.pt_product-details .breadcrumbs-wrapper {
    border-width: 0;
    padding: 18px 20px;
}

.product-abovefold-wrapper {
    &.pdp-not-quickview {
        flex-flow: row wrap;

        @include media('>=desktop') {
            gap: 20px;
            flex-wrap: nowrap;
        }

        @include media('>=desktopMedium') {
            gap: 80px;
        }

        @include media('>=1440px') {
            gap: 164px;
        }
    }

    &.pdp-in-quickview {
        flex-flow: row wrap;
        padding-bottom: 22px;

        @include media('>=desktop') {
            position: relative;
            flex-wrap: nowrap;
            padding: 32px;
            gap: 20px;
        }

        @include media('>=desktopLarge') {
            padding: 32px;
            gap: 32px;
        }
    }
}

.product-name-container {
    width: 100%;
    padding: 18px 19px;
    background: $white;

    @include media('>=tablet', '<desktop') {
        padding: 22px 32px 27px;
    }

    .product-detail-name {
        color: $blue;
        text-transform: uppercase;
        letter-spacing: normal;
        font-size: 18px;
        line-height: 24px;
    }
}

.product-detail-name.fakeH1 {
    font: normal 18px/24px $MontserratBold;
    text-transform: uppercase;
    margin-top: 4px;

    @include media('>=desktopLarge') {
        .pdp-in-quickview & {
            margin-top: 18px;
        }
    }

    @include media('>=desktopLarge') {
        .pdp-not-quickview & {
            font-size: 24px;
            margin-top: 79px;
        }
    }
}

.product-col-1 {
    position: relative;

    .pdp-not-quickview & {
        flex: 0 0 100%;
        max-width: 100%;
        align-items: center;
        align-content: center;
        order: 1;

        @include media('>=desktop') {
            flex: 1 1 calc(48% - 10px);
            max-width: calc(48% - 10px);
        }

        @include media('>=desktopMedium') {
            flex-basis: calc(56% - 40px);
            max-width: calc(56% - 40px);
        }

        @include media('>=1440px') {
            flex-basis: calc(60% - 84px);
            max-width: calc(60% - 84px);
        }
    }

    .pdp-in-quickview & {
        flex: 0 0 100%;
        max-width: 100%;

        @include media('>=desktop') {
            flex-basis: calc(58% - 10px);
            max-width: calc(58% - 10px);
        }

        @include media('>=desktopLarge') {
            flex-basis: calc(58% - 16px);
            max-width: calc(58% - 16px);
        }
    }
}

.product-col-2 {
    .pdp-not-quickview & {
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;

        @include media('>=desktop') {
            flex: 1 1 calc(52% - 10px);
            max-width: calc(52% - 10px);
            padding-right: 80px;
        }

        @include media('>=desktopMedium') {
            flex-basis: calc(44% - 40px);
            max-width: calc(44% - 40px);
        }

        @include media('>=1440px') {
            flex-basis: calc(40% - 84px);
            max-width: calc(40% - 84px);
        }
    }

    .pdp-in-quickview & {
        flex: 0 0 100%;
        max-width: 100%;

        @include media('>=desktop') {
            flex-basis: calc(42% - 10px);
            max-width: calc(42% - 10px);
        }

        @include media('>=desktopLarge') {
            flex-basis: calc(42% - 16px);
            max-width: calc(42% - 16px);
        }
    }
}

.productimage-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .js-bynderVideo,
    .bynder-widget {
        width: 100%;
        height: 100%;
    }
}

.product-detail-wishlist-wrapper {
    position: absolute;
    top: 24px;
    right: 28px;
    z-index: 2;

    @include media('>=tablet') {
        right: 32px;
    }

    @include media('>=desktop') {
        right: 24px;
    }
}

.productform-link {
    font: 14px/20px $Arial;
    color: $blue;
    text-decoration: underline;
    white-space: nowrap;

    @include media('>=tablet', '<desktop') {
        font-size: 16px;
    }
}

.productform-link-icon {
    height: 28px;
    width: 28px;
}

.addtowishsvg {
    stroke-width: 2;
    stroke: $blue;
    fill: none;

    .st1 {
        fill: $white;
    }

    &.filled .st0 {
        fill: $blue;
    }
}

.product-primary-image-wrapper {
    width: 100%;
    position: relative;

    .slick-arrow {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;

        &::before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            border: 2px solid $blue;
            border-width: 2px 0 0 2px;
        }

        &.slick-disabled {
            display: none !important;
        }
    }

    .slick-prev {
        left: 35px;

        @include media('>=tablet', '<desktop') {

        }

        .pdp-in-quickview & {
            left: 10px;
        }

        &::before {
            transform: rotate(-45deg);
        }
    }

    .slick-next {
        right: 35px;

        .pdp-in-quickview & {
            right: 10px;
        }

        &::before {
            transform: rotate(-225deg);
        }
    }
}

.product-images-mosaic {
    .pdp-not-quickview & {
        .product-primary-image {
            cursor: zoom-in;
            position: relative;

            &:first-child {
                .hdlink-wrapper {
                    display: block;
                }
            }
        }

        &.slick-initialized {
            .hdlink-wrapper {
                display: block;
            }
        }


        @include media('>=desktop') {
            display: flex;
            flex-flow: row wrap;
            gap: 8px;

            .product-primary-image {
                flex: 0 0 calc(50% - 4px);

                &:first-child {
                    flex: 0 0 100%;
                }

                &:nth-child(n+6) {
                    display: none;
                }
            }

            &.product-images-deployed {
                .product-image-ovelay {
                    display: none;
                }

                .product-primary-image:nth-child(n+6) {
                    display: block;
                }
            }

            .image-type-video + & {
                margin-top: 8px;
            }
        }
    }

    .pdp-in-quickview & {
        @include media('<desktop') {
                .product-primary-image {
                padding: 0 11vw;

                img {
                    margin: 0 auto;
                    max-width: 100%;
                }
            }
        }

        @include media( '>=desktop') {
            display: flex;
            flex-flow: row wrap;
            gap: 8px;

            .product-primary-image {
                flex: 0 0 calc(25% - 4px);

                &:first-child {
                    flex: 0 0 100%;
                }
            }
        }
    }
}

.hdlink-wrapper {
    display: none;
    cursor: pointer;
    position: absolute;
    bottom: 24px;
    right: 28px;

    @include media('>=tablet') {
        right: 32px;
    }

    @include media('>=desktop') {
        right: 24px;
    }
}

.product-hd-image-link {
    display: block;
    width: 28px;
    height: 28px;
}

.hdlinksvg {
    .st0 {
        fill: none;
        stroke: $blue;
        stroke-width: 2px;
    }
}

.product-content-updates {
    position: relative;
}

.debug-info-trigger {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    border-top: 12px solid $red;
    border-left: 12px solid transparent;
    z-index: 2;

    &:hover .debug-info-tooltip {
        display: block;
    }
}

.debug-info-tooltip {
    position: absolute;
    top: -8px;
    right: 3px;
    border: 1px dotted $black;
    padding: 5px;
    font-size: 10px;
    display: none;
    white-space: nowrap;
    background: $white;
    z-index: 99999;
}

.pdp-progress-loader {
    .turtlesvg {
        width: 24px;
        height: 24px;
        margin: 7px;
    }
}

.product-detail-price-wrapper {
    margin-top: 21px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;

    @include media('>=desktop') {
        margin-top: 18px;
    }

    .product-detail-price,
    .promotion {
        flex: 0 0 auto;
    }

    .promotion {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        color: $mouse-grey;
        font: normal 14px / 20px $Arial;
    }

    .promotion-title {
        padding: 0 4px;
    }

    .tooltip {
        display: none;
    }

    .price-sales {
        font: bold 18px / 20px $Arial;
    }

    .price-value-text {
        display: inline-block;
    }

    .gl_price-beforePromo {
        margin-left: 4px;

        > span {
            font: normal 14px/20px $Arial;
            color: $mouse-grey;
        }
    }
}

.product-detail-container {
    position: relative;

    .product-detail-price {
        font: 10px / 14px $Arial;
        color: $blue;
    }

    .cross-sell-pdp & {
        display: flex;
        flex-direction: column;
        padding: 0;
        width: 100%;
        max-width: 100%;
        min-height: 100%;
        border: 0;

        @include media('>=desktop') {
            min-height: 600px;
        }
    }
}

.product-crosssell-container {
    border-radius: 4px;
    background-color: $crosssell-grey;
    margin: 27px 0 33px;
    position: relative;

    /*
    @include media('>=desktop') {
        margin: 27px 0 33px;
    }
    */
}

.cross-sell-wrapper {
    padding: 8px;

    .pt_product-iframe & {
        display: none;
    }
}

.cross-sell-link {
    text-decoration: none;
    color: $blue;
    cursor: pointer;
}

.cross-sell-block {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.cross-sell-image {
    width: 72px;
    height: 72px;
    flex: 0 0 72px;
}

.cross-sell-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 16px;
    text-align: left;
    flex-grow: 1;
}

.cross-sell-title {
    font: bold 16px / 20px $Arial;
    max-height: 22px;
    overflow: hidden;
}

.cross-sell-name {
    font: 14px / 24px $Arial;
    color: $mouse-grey;
    margin-top: 3px;
}

.cross-sell-bikini-wrapper {
    padding: 8px;
    align-items: center;
    align-content: center;
}

.cross-sell-bikini-title {
    color: $blue;
    font: bold 16px / 20px $Arial;
    flex: 1 1 40%;
    min-width: 40%;
    cursor: pointer;
    padding: 0 4px;
}

.cross-sell-bikini-slider-wrapper {
    overflow: hidden;
    flex: 1 1 auto;
    max-width: 60%;
    padding: 0 16px;
}

.cross-sell-bikini-slider {
    &:not(.slick-initialized) {
        .cross-sell-bikini-tile {
            width: 72px;
            flex: 0 0 72px;
        }
    }

    .cross-sell-bikini-tile {
        cursor: pointer;
    }

    .slick-arrow {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        &.slick-disabled {
            display: none !important;
        }
    }

    .slick-prev {
        left: -20px;
    }

    .slick-next {
        right: -16px;

        @include media('>=375px') {
            right: -20px;
        }
    }
}

.cross-sell-bikini-image {
    margin: 0 3px;
    background-color: $grey-bg;
}

.zoom-images {
    .product-image-container > & {
        display: none;
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;

        &.slick-disabled {
            display: none !important;
        }
    }

    .slick-prev {
        left: 35px;
    }

    .slick-next {
        right: 35px;
    }
}

.zoom-image {
    background-color: $white;
}

.product-content {
    .pdp-not-quickview & {
        padding: 21px 19px;

        @include media('>=tablet', '<desktop') {
            padding: 29px 32px;
        }

        @include media('>=desktop') {
            padding: 0;
        }
    }

    .pdp-in-quickview & {
        padding: 0 20px;

        @include media('>=desktop') {
            padding: 0;
        }
    }
}

.product-description-content-list,
.product-care-content-list {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
    margin-top: 19px;
}

.product-description-content-list {
    li:nth-child(n+3) {
        display: none;
    }

    &.deployed {
        li:nth-child(n+3) {
            display: list-item;
        }
    }
}

.product-description-detailstoggle {
    margin-top: 16px;
    text-decoration-line: underline;
    cursor: pointer;
}

.product-detail-variations {
    .pdp-not-quickview & {
        margin-top: 30px;

        @include media('>=desktopMedium') {
            margin-top: 36px;
        }
    }

    .pdp-in-quickview & {
        margin-top: 29px;
    }

    .variation-select-title {
        color: $blue;
        font: 14px/20px $Arial;
        font-weight: bold;

        @include media('>=tablet', '<desktop') {
            font-size: 20px;
        }
    }

    .title-swatch-value {
        font-weight: normal;
        color: $mouse-grey;

        @include media('>=tablet', '<desktop') {
            font-size: 16px;
        }
    }
}

.attribute-color {
    .variation-select-title {
        margin-bottom: 12px;

        @include media('>=tablet') {
            margin-bottom: 18px;
        }

        @include media('>=desktop') {
            margin-bottom: 14px;
        }

        @include media('>=desktopMedium') {
            margin-bottom: 16px;
        }
    }

    .swatch-list {
        display: flex;
        flex-flow: row wrap;
        gap: 7px;
        max-width: 100%;
    }
}

.attribute-size {
    .pdp-not-quickview & {
        margin-top: 40px;

        @include media('>=tablet', '<desktop') {
            margin-top: 50px;
        }
    }

    .pdp-in-quickview & {
        margin-top: 36px;
    }

    .product-set-details & {
        margin-top: 12px;
    }
}

.variation-select-title {
    .cross-sell-pdp & {
        font-size: 12px;
        padding: 5px 0;
        margin: 0;
    }

    .cross-sell-pdp .attribute-color & {
        text-align: left;
    }
}

.size-legend-wrapper {
    justify-content: space-between;
    margin-bottom: 11px;

    @include media('>=tablet') {
        margin-bottom: 14px;
    }

    @include media('>=desktop') {
        margin-bottom: 7px;
    }

    @include media('>=desktopMedium') {
        margin-bottom: 12px;
    }
}

.productform-links-wrapper {
    margin-left: 12px;
}

.swatch-list-sizes {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    @include media('>=desktop') {
        gap: 7px;
    }
}

.size-radios .gl_has-error .swatchanchor {
    border-color: red;
    color: red;

    &.unavailable-value::after {
        background-color: $red;
    }
}

.size-radios {
    .swatchanchor {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.starsvg {
    .swatchanchor & {
        fill: $blue;
        height: 16px;
    }

    .swatchanchor.selected-value & {
        fill: $white;
    }

    .ufd-star-explanation & {
        fill: $mouse-grey;
        height: 16px;
    }
}

@keyframes expand {
    from {
        padding-left: 19px;
        padding-right: 19px;
    }

    to {
        padding-left: 0;
        padding-right: 0;
    }
}

.pdpForm {
    .sticky {
        margin: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 19000; // lower than the mobile menu, cross-sell, and monogramming modals
        animation-duration: .5s;
        animation-name: expand;
    }

    .gl_btn {
        font: 16px/20px $Arial;
    }
}

.product-description {
    margin: 28px 22px 0 20px;
    font: 14px/24px $Arial;
    color: $blue;

    @include media('>=tablet', '<desktop') {
        font-size: 16px;
    }

    @include media('>=tablet') {
        margin: 11px 40px 0;
    }

    @include media('>=desktop') {
        margin: 28px auto 0;
    }
}

.pdpdetails-additionnal {
    margin-top: 22px;
    border: 1px solid $blue;
    padding: 15px 30px;
}

.pdpdetails-additionnal-label {
    text-transform: uppercase;
}

.pdpdetails-additionnal-model {
    text-transform: capitalize;
}

.family-label {
    display: inline-block;
    margin-top: 17px;
    padding: 4px 10px 3px;
    background: $crosssell-grey;
    border-radius: 4px;
    font: bold 11px/13px $Arial;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $family-grey;
    text-decoration: none;

    @include media('>=tablet', '<desktop') {
        margin-top: 25px;
    }
}

.pdp-tabs {
    margin: 37px 20px 0;

    @include media('>=tablet') {
        margin: 51px 32px 0;
    }

    @include media('>=desktop') {
        margin: 41px auto 0;
    }

    .tabs-accordion-header {
        text-transform: none;
        font: bold 16px/20px $Arial;
        padding: 18px 0;
    }

    .tab {
        margin: 25px 0;

        &.traceability-tab {
            margin-top: 0;
        }
    }

    .tab-content {
        font: 14px/24px $Arial;
        color: $mouse-grey;

        @include media('>=tablet', '<desktop') {
            font-size: 16px;
        }

        &.care-instructions-pdp {
            color: $blue;
        }
    }

    .care-instructions-pdp-uppercase {
        text-transform: uppercase;
    }

    .styleguide-fit-shape {
        max-width: 100%;
    }

    .size-chart-description-text {
        font: 14px/24px $Arial;
        color: $mouse-grey;

        @include media('>=tablet', '<desktop') {
            font-size: 16px;
        }
    }

    .size-chart-description-subtitle {
        color: $blue;
        text-transform: none;
        margin: 26px 0 10px;
    }

    .size-chart-unit {
        font: normal normal 14px/20px $Arial;
        padding: 0 16px;
        height: 32px;
        min-width: 55px;
        margin: 0 0 0 8px;
    }

    .size-chart-countries {
        margin: 0;
    }

    .size-chart-countries-select {
        font: normal normal 12px/20px $Arial;
        text-transform: uppercase;
        height: 32px;
        min-height: 32px;
    }

    .netreviews_reviews_rate {
        display: none;
    }

    .reviews-stars {
        position: absolute;
        top: calc(50% - 13px);
        right: 50px;
        height: 30px;
        width: 60px;
    }

    .size-chart-selector-wrapper {
        justify-content: flex-start;
        margin: 0 0 16px 0;
    }

    .size-chart-tables-wrapper {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .size-chart-table {
        width: 100%;
        margin: 0;
    }

    .size-chart-table th,
    .size-chart-table td {
        max-width: 130px;
        border-width: 1px;
    }

    .size-chart-table thead tr:first-child th,
    .size-chart-table tbody tr:last-child th,
    .size-chart-table tbody tr:last-child td {
        border-width: 1px;
    }

    .size-chart-table td {
        font: normal 14px/24px $Arial;
        padding: 8px;
    }

    .size-chart-table thead th {
        font: bold 12px/18px $Arial;
        padding: 18px 10px;

        @include media('>=desktop') {
            padding: 18px;
        }
    }

    .size-chart-table tbody th {
        font: bold 14px/20px $Arial;
        padding: 10px 6px;

        @include media('>=desktop') {
            padding: 10px;
        }
    }

    .size-chart-page-col {
        > .size-chart-description-text {
            width: auto;
            margin: 0;
            padding: 0;
        }
    }

    .gl_accordion-deploy {
        display: block;
    }

    .styleguide-pdplink,
    .sizechart-pdplink {
        font-family: $Arial;
        font-size: 14px;

        @include media('>=tablet', '<desktop') {
            font-size: 16px;
        }
    }

    .sizechart-pdplink {
        line-height: 24px;
    }

    .sizechart-pdplink-wrapper {
        // similar to .size-chart-description from _size_chart.scss
        padding: 0 10px;
        margin-top: 30px;
        text-align: left;

        @include media('>=desktop') {
            margin: 30px 0;
        }
    }
}

.styleguide-pdp {
    .slick-arrow.slick-disabled {
        display: none !important;
    }
}

.pdp-preorder-msg,
.shipping-estimate-wrapper {
    font: 14px/16px $Arial;
    letter-spacing: 0.04em;
    color: $mouse-grey;
}

.shipping-estimate-wrapper {
    margin-top: 20px;

    @include media('>=tablet') {
        margin-top: 35px;
        text-align: center;
    }

    @include media('>=desktop') {
        margin-top: 19px;
    }
}

.gshipping-title {
    font: bold 14px/24px $Arial;
    margin-bottom: 8px;
    text-transform: none;

    @include media('>=tablet', '<desktop') {
        font-size: 16px;
    }
}

.gshipping-details {
    font: normal 14px/24px $Arial;
    margin: 0 0 32px;
    color: $mouse-grey;

    @include media('>=tablet', '<desktop') {
        font-size: 16px;
    }

    .traceability-tab & {
        margin-bottom: 16px;
    }
}

.ufd-star-explanation {
    font: 14px/18px $Arial;
    color: $mouse-grey;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ufd-popup-fakelink {
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
}

.pdp-unavailable-size-description,
.monogram-deactivated,
.pdp-unavailable-size-option {
    font: 14px/21px $Arial;
    color: $mouse-grey;
}

.pdp-unavailable-size-description {
    @include media('>=desktop') {
        margin-bottom: 12px;
    }
}

.pdp-unavailable-size-option {
    @include media('>=desktop') {
        margin-bottom: 18px;
    }
}

.monogram-deactivated-margin {
    margin-top: 12px;
}

.pdp-not-possible-msg {
    font: 12px/20px $Arial;
    color: $mouse-grey;
}

.product-add-to-cart {
    .pdp-not-quickview & {
        margin-top: 18px;

        @include media('>=tablet', '<desktop') {
            margin-top: 13px;
        }
    }

    .pdp-in-quickview & {
        margin-top: 6px;
    }
}

.product-info-wrapper {
    margin-top: 64px;
    margin-bottom: 40px;

    @include media('>=tablet', '<desktop') {
        margin-top: 86px;

        .small-blog-article-col-left.col-sm-6 {
            flex-basis: 100%;
            max-width: 100%;
            padding: 0 20%;
        }

        .small-blog-article-col-right.row {
            flex-basis: 100%;
            max-width: 100%;
            padding: 0 60px;
        }
    }

    @include media('>=1440px') {
        margin-top: 104px;
    }

    .gl_btn.gl_btn-link {
        line-height: 48px;
        height: 48px;
    }

    .small-blog-article-title {
        font: bold 20px/20px $Arial;
    }

    .blog-separator {
        margin: 15px auto 20px;
        width: 125px;
    }

    .small-blog-article-subtitle {
        font: bold 11px/13px $Arial;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: $family-grey;
        padding: 4px 10px 3px;
        background: $crosssell-grey;
        border-radius: 4px;
        display: inline-block;
    }

    .small-blog-article-description {
        font: normal normal 14px/24px $Arial;
        margin: 25px auto 0;

        @include media('>=tablet', '<desktop') {
            font-size: 16px;
        }
    }

    .small-blog-article-link {
        margin-top: 33px;
    }

    .pdp-sistercategorylinks-wrapper {
        margin: 64px 20px 0;
        padding: 0;

        @include media('>=tablet') {
            margin: 78px 32px 0;
        }
    }

    .pdp-sistercategorylinks-title {
        font: bold 20px/20px $Arial;
        text-transform: none;
        text-align: center;
        margin: 0;
    }

    .seo-separator {
        margin: 20px auto 19px;
        text-align: center;
        width: 125px;
    }

    .blog-pdp-category-section.gl_flexbox-container {
        padding: 0;
        width: auto;
        margin: 64px 20px 0;

        @include media('>=tablet') {
            margin: 78px 32px 0;
        }

        @include media('>=desktop') {
            margin: 112px 80px 0;
        }

        @include media('>=1440px') {
            margin-top: 136px;
        }
    }

    .small-blog-article-wrapper {
        margin: 0;

        @include media ('>=desktop') {
            margin: 0 auto;
            max-width: none;
        }
    }

    .small-blog-article-button.gl_button {
        @include media('>=desktop') {
            margin-top: 11px;
        }
    }
}

.giftblock-slide {
    position: relative;
    flex-flow: row wrap;
    background-color: $giftblock-grey;

    @include media('>=desktop') {
        flex-wrap: nowrap;
        align-items: center;
    }
}

.pdp-giftblock-content {
    text-align: center;
    margin: 47px 16px 44px;
    flex-flow: column wrap;
    justify-content: center;
    align-self: center;
    flex: 0 1 100%;
    max-width: 100%;
    font: normal 14px/24px $Arial;
    color: $blue;

    @include media('>=desktop') {
        flex-basis: 50%;
        margin: auto;
        padding: 95px 104px 92px 61px;
    }

    .gl_separator-margin {
        margin: 16px auto;
        text-align: center;
        width: 125px;
    }
}

.pdp-giftblock-image {
    flex: 0 1 100%;

    @include media('>=desktop') {
        flex-basis: 50%;
        padding: 0 26px;
    }

    @include media('>=desktopMedium') {
        padding: 0 32px;
    }
}

.pdp-giftblock-dots {
    margin: vw(90px) 0 0;
}

.pdp-giftblock-content-title {
    font: bold 20px/20px $Arial;
    text-transform: none;
}

.svgthirdwave .st0 {
    stroke: $blue;
}

// Complete your look block
.pdplook-wrapper {
    position: relative;
    margin-top: 64px;
    padding: 39px 20px 40px;
    background: $pdplook-bg;

    @include media('>=tablet', '<desktop') {
        margin-top: 79px;
        padding: 63px 32px 72px;
    }

    @include media('>=desktopLarge') {
        padding: 34px 108px 40px 80px;
    }

    @include media('>=1440px') {
        padding: 34px 108px 40px 80px;
    }

    .gl_separator-margin {
        margin: 20px auto 38px;
        text-align: center;
        width: 125px;
    }

    .slick-arrow {
        width: 34px;
        height: 34px;
        position: absolute;
        top: 38%;
        transform: translateY(-38%);
        z-index: 9;
        background: $white;
        border-radius: 50%;

        &::before {
            width: 10px;
            height: 10px;
        }

        &.slick-disabled {
            display: none !important;
        }
    }

    .slick-prev {
        left: 4px;

        &::before {
            position: relative;
            right: -3px;
        }
    }

    .slick-next {
        right: 4px;

        &::before {
            position: relative;
            left: -3px;
        }
    }
}

.pdplook-carousel {
    @include media('>=desktopLarge') {
        gap: 4px;
    }

    @include media('>=1440px') {
        gap: 20px;
    }

    & > .product-tile {
        flex: 0 1 calc(50% - 6px);

        @include media('>=desktopLarge') {
            flex-basis: calc(50% - 2px);

            .product-name {
                font-size: 12px;
                line-height: 18px;
            }
        }

        @include media('>=1440px') {
            flex-basis: calc(50% - 10px);
        }
    }

    .product-tile:nth-child(n+3) {
        @include media('>=desktopLarge') {
            padding-top: 26px;
        }

        @include media('>=1440px') {
            padding-top: 62px;
        }
    }
}

.product-info-wrapper .product-carousel-component .carousel-component.tiles-container,
.pdplook-carousel {
    display: flex;
    flex-flow: row wrap;
    gap: 12px;

    & > .product-tile-wrapper,
    & > .product-tile {
        flex: 0 1 calc(50% - 6px);
    }

    & > .product-tile-wrapper {
        margin: 0;
        padding: 0;
    }

    .carousel-component-tile {
        padding: 0;
        margin: 0;
        display: block;
    }

    .product-name {
        font: bold 14px/20px $Arial;

        @include media('>=tablet', '<desktop') {
            font-size: 16px;
        }

        @include media('>=desktopMedium') {
            font-size: 16px;
        }
    }

    .product-color,
    .product-pricing,
    .product-sales-price,
    .product-standard-price {
        font: normal 14px/24px $Arial;
    }

    .product-color {
        text-decoration: underline;
    }

    .product-info {
        margin: 11px 0 0;

        @include media('>=tablet', '<desktop') {
            margin-top: 16px;
        }
    }

    &.slick-initialized {
        .carousel-component-tile {
            padding: 0 10px;
        }

        .slick-prev {
            left: -35px;
        }

        .slick-next {
            right: -35px;
        }
    }
}

.product-info-wrapper .product-carousel-component .carousel-component.tiles-container {
    @include media('>=desktop') {
        flex-wrap: nowrap;
        gap: 20px;
    }
}


/*
.pdplook-carousel.slick-initialized {
    .product-tile {
        @include media('>=tablet') {
            margin: 0 5px 0 0;
        }
    }

    .product-info {
        @include media('>=tablet') {
            margin: 8px 0 0;
        }
    }

    .slick-dots {
        margin: 0 0 10px;
    }
}
*/

.pdplook-imagewrapper {
    position: relative;
    justify-content: center;
    flex-flow: row wrap;

    @include media('>=desktop') {
        flex-wrap: nowrap;
        gap: 8px;
    }

    @include media('>=desktopLarge') {
        gap: 180px;
    }
}

.pdplook-title-wrapper {
    text-align: center;
}

.pdplook-carousel {
    display: flex;
    flex-flow: row wrap;
}

.pdplook-button {
    border: 1px solid $blue;
    padding: 12px;
    margin-top: 33px;
    text-align: center;

    @include media('>=tablet', '<desktop') {
        padding: 14px 38px;
        max-width: 386px;
        margin: 48px auto 0;
    }

    @include media('>=desktop') {
        max-width: 250px;
        margin: 33px auto 0;
    }

    @include media('>=desktop') {
        margin: 113px auto 0;
    }
}

.pdplook-link {
    text-decoration: none;
    text-transform: uppercase;
    font: bold 12px/20px $Arial;

    @include media('>=tablet', '<desktop') {
        font-size: 14px;
    }
}

.pdplook-mainimage {
    flex: 0 0 100%;

    @include media('>=desktop') {
        flex: 0 0 55%;
    }

    @include media('>=desktopLarge') {
        flex-basis: calc(62% - 90px);
    }
}

.pdplook-products {
    flex: 0 0 100%;
    margin-top: 8px;

    @include media('>=tablet', '<desktop') {
        margin-top: 16px;
    }

    @include media('>=desktop') {
        flex: 0 0 45%;
        margin-top: 0;
    }

    @include media('>=desktopLarge') {
        flex-basis: calc(38% - 90px);
    }
}

.pdplook-title {
    font: bold 20px/20px $Arial;
    text-transform: none;
}

.pdp-tabs-container {
    border-bottom: 1px solid $fishnet-grey;
}

.pdp-block-slider {
    margin: 64px 20px 0;

    @include media('>=tablet') {
        margin: 78px 32px 0;
    }

    @include media('>=desktop') {
        margin: 111px 0 0;
    }

    @include media('>=desktopMedium') {
        margin: 111px 80px;
    }

    @include media('>=1440px') {
        margin: 161px 302px;
    }
}

.product-info-wrapper .product-carousel-component {
    margin: 64px 20px 0;

    @include media('>=tablet') {
        margin: 78px 32px 0;
    }

    @include media('>=desktop') {
        text-align: center;
        margin: 111px 60px 0; // inner carousel has 20px left and right margins
    }

    @include media('>=desktopLarge') {
        margin: 111px 80px 0; // inner carousel does not have 20px left and right margins anymore
    }

    @include media('>=1440px') {
        margin: 161px 80px 0;
    }

    .newin-title-wrapper {
        margin: 0;
    }

    .product-carousel-component-title {
        font: bold 20px/20px $Arial;
        text-transform: none;
        margin: 0;
        text-align: center;
    }

    .newin-action-wrapper {
        margin-top: 33px;

        @include media('>=tablet') {
            margin-top: 50px;
            text-align: center;
        }

        @include media('>=desktop') {
            margin-top: 22px;
        }

        @include media('>=1440px') {
            margin-top: 52px;
        }
    }

    .newin-link {
        margin: 0;
        font: bold 12px/48px $Arial;

        @include media('>=tablet', '<desktop') {
            font-size: 14px;
        }

        @include media('>=tablet') {
            margin: 0 auto;
        }
    }

    .search-result-view-more {
        margin-top: 33px;
    }
}

.cross-sell-dialog {
    .cross-sell-pdp-content {
        flex-grow: 1;
        flex-direction: column;
        min-height: 100%;

        @include media('>=desktop') {
            min-height: 600px;
            height: 600px;
        }
    }

    .product-image-container {
        display: flex;
        flex-grow: 1;
        background: $grey-bg;
        padding: 0 0 115px; // space for sticky bottom form
    }

    .productimage-wrapper {
        margin: auto 0;
    }

    .product-content {
        padding: 0;
    }
}

// Cross-sell panel
.cross-sell-pdp {
    display: flex;
    max-width: 100vw;
    min-height: 100%;
    background-color: $white;

    .product-primary-image-wrapper {
        height: 100%;

        @include media('>=desktop') {
            width: 600px;
            margin: 0;
        }

        .slick-prev {
            left: 10px;

            @include media('>=tablet') {
                left: 25px;
            }

            @include media('>=desktop') {
                left: 15px;
            }
        }

        .slick-next {
            right: 10px;

            @include media('>=tablet') {
                right: 25px;
            }

            @include media('>=desktop') {
                right: 15px;
            }
        }

        img {
            margin: 0 auto;
            max-width: 100%;

            @include media('>=tablet') {
                width: 600px;
            }

            @include media('>=desktop') {
                width: 370px;
            }
        }
    }

    .product-primary-image {
        padding: 30px;
        text-align: center;

        &.gl_flex {
            justify-content: center;
        }

        @include media('>=tablet') {
            padding: 30px 70px;
        }
    }

    .product-attributes-list {
        @include media('>=480px') {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: flex-start;
        }
    }

    .attribute-color {
        @include media('>=480px') {
            padding-right: 10px;
            flex: 0 0 40%;
        }
    }

    .attribute-size {
        @include media('>=480px') {
            padding-left: 10px;
            flex: 0 0 60%;
        }
    }

    .add-to-cart.gl_button {
        font-size: 11px;
    }

    .gl_color-button {
        width: 35px;
        height: 35px;
        max-width: 35px;
        max-height: 35px;
        padding: 3px;
    }

    .slick-dots li {
        width: 16px;
        min-width: 16px;
        height: 16px;
        border-radius: 100%;
        margin-right: 10px;
        margin-bottom: 10px;

        @include media('>=480px') {
            width: 20px;
            min-width: 20px;
            height: 20px;
            margin-right: 15px;
            margin-bottom: 15px;
        }
    }

    .product-form-sticky {
        position: absolute;
    }

    .product-image-container {
        position: relative;
    }
}

.cross-sell-pdp-header,
.quickview-pdp-header {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.cross-sell-style,
.quickview-pdp-name {
    padding: 22px 0px 18px 19px;
    flex-grow: 1;
    max-width: calc(100% - 48px);
}

.cross-sell-style {
    font: 16px/19px $Arial;
    font-weight: bold;
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media('>=tablet') {
        font-size: 18px;
        line-height: 22px;
        height: 52px;
    }

    @include media('>=desktop') {
        font-size: 16px;
        line-height: 19px;
        height: 49px;
    }
}

.cross-sell-pdp-close,
.quickview-pdp-close {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;
    width: 48px;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    cursor: pointer;

    .closesvg {
        width: 20px;
        height: 20px;

        .st0 {
            fill: $blue;
        }
    }
}

.quickview-pdp-desktop-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .closesvg {
        width: 14px;
        height: 14px;

        .st0 {
            fill: $blue;
        }
    }
}

.quickview-dialog,
.cross-sell-dialog {
    background: none;
    padding: 0;
}


.product-form-sticky {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 20000;
    background-color: $light-grey;

    .menu-active & {
        position: static;
    }
}

.product-form-sticky-content {
    padding: 15px;
    border-top: 1px solid $blue-grey;
}

.product-form-sticky-row {
    justify-content: space-between;
    margin: 0 0 12px;
}

.product-detail-name-sticky {
    font: 12px / 14px $Arial;
    font-weight: bold;
    color: $blue;
    padding: 0 20px 0 0;
    flex-grow: 1;
    max-height: 28px;
    overflow: hidden;
}

.product-detail-price-sticky {
    text-align: right;

    .price-value-text {
        display: block;
        font-weight: bold;
        font-size: 12px;
    }
}

.product-form-sticky-form-row {
    display: flex;
    align-items: flex-end;
    flex-flow: row wrap;
}

.product-form-sticky-form-buttons-row {
    margin: 0 -8px;

    .product-pdp-button,
    .product-pdp-fake-link-btn {
        margin: 10px 8px 0;
    }

    .product-pdp-button {
        order: 1;
        width: calc(50% - 16px);
        flex-grow: 1;
    }
}

.product-detail-size-sticky {
    flex: 0 0 100%;
}

.product-action-sticky {
    flex: 0 0 100%;
    position: relative;

    &.not-found-variation-action {
        margin-top: 6px;
    }

    .add-to-cart {
        font: 10px / 13px $MontserratBold;
        height: 44px;
    }

    // Back-in-stock in sticky panel
    .pdp-unavailable-size-text-sticky {
        position: absolute;
        top: -14px;
        margin: 0;
        width: 100%;
        height: 10px;
        overflow: hidden;
        font: 10px / 10px $Arial;
    }

    .gl_error-message {
        position: absolute;
        bottom: -12px;
        width: 100%;
        height: 10px;
        overflow: hidden;
        font: 10px / 10px $Arial;
    }

    .not-found-variation-input-sticky {
        padding: 0;
    }

    .input-email-sticky {
        height: 44px;
        line-height: 44px;
        padding: 0 vw(84px) 0 8px;

        &::placeholder {
            font-size: 12px;
        }

        @include media('>=desktop') {
            padding: 0 40px 0 8px;
        }
    }

    .input-ok-sticky {
        line-height: 44px;
    }

    .pdp-unavailable-size-block-sticky {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        height: 44px;
    }

    .size-radios .swatchanchor {
        background-color: $white;
    }
}

.monogram-not-set {
    height: auto;
    border: none;
    text-align: left;
    margin-bottom: 12px;

    .monogram-add-btn {
        cursor: pointer;
    }

    .monogram-add-btn-text,
    .monogram-add-btn-price {
        font: 14px/21px $Arial;
        color: $blue;
    }

    .monogram-add-btn-text {
        text-decoration: underline;
    }
}

.monogram-dialog {
    border: 1px solid $blue;

    @include media('>=desktop') {
        max-width: 1000px;
    }

    @include media('>=desktopLarge') {
        max-width: 1200px;
    }
}

.monogram-action {
    text-decoration: underline;
    cursor: pointer;
    font: normal 14px/21px $Arial;
}

.monogram-action-right {
    margin-left: 12px;
}

.monogram-disclaimer {
    color: $red;
    font-size: 12px;
    margin: 10px 0;
}

.monogram-form-buttons,
.monogram-characters-inputs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-flow: row wrap;
    margin: auto -6px;
}

.monogram-form-button {
    color: $blue;
    font-size: 16px;
    min-width: 42px;
    height: 42px;
    text-align: center;
    text-transform: uppercase;
    padding: 3px;
    background-color: $white;
    border: 1px solid $light-grey;
    cursor: pointer;
    text-decoration: none;
    margin: 6px;

    &:hover {
        border-color: $blue-hover;
        color: $white;
    }

    &.selected {
        border-color: $blue;
        color: $white;
    }

    .invalid & {
        border-color: $red;
        color: $red;
        outline-color: $red;
    }

    &.monogram-placement,
    &.monogram-font {
        &:hover {
            background-color: $blue-hover;
        }

        &.selected {
            background-color: $blue;
        }
    }

    &.monogram-placement {
        padding: 0;
        max-width: 50%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }

    &.monogram-font {
        padding: 3px 8px;
        line-height: 34px;
    }

    &.monogram-color {
        &:hover,
        &.selected {
            padding: 1px;
            border-width: 3px;
        }

        &.selected {
            outline: none;
        }
    }
}

.monogram-color-inner {
    width: 100%;
    height: 100%;
}

input[type=text].monogram-character-input {
    width: 42px;
    height: 42px;
    padding: 3px;
    background-color: $white;
    border: 1px solid $light-grey;
    text-align: center;
    color: $blue;
    font-size: 16px;
    line-height: 34px;
    margin: 6px;

    &.filled {
        border-color: $blue;
        background-color: $blue;
        color: $white;
    }

    &.invalid {
        border-color: $red !important;
        color: $red !important;
        outline-color: $red;
    }
}

.monogram-submit-wrapper {
    text-align: center;
}

.monogram-submit {
    @include media('>=480px') {
        max-width: 200px;
    }

    @include media('>=480px', '<desktop') {
        font-size: 12px;
    }
}

.monogram-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-flow: row wrap;
    padding: 0 10px;
    margin: 6px 4px;

    @include media('>=tablet') {
        padding: 0;
        margin: 3px 12px 12px;
    }
}

.monogram-header {
    flex: 0 0 100%;
    width: 100%;

    @include media('>=tablet') {
        padding: 0 0 0 60%;
    }
}

.monogram-rightcol {
    order: 1;
    flex: 0 0 100%;
    width: 100%;

    @include media('>=tablet') {
        order: 2;
        flex-basis: 40%;
        width: 40%;
        padding-left: 10px;
    }
}

.monogram-leftcol {
    order: 2;
    flex: 0 0 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-flow: row wrap;
    padding-top: 30px;

    @include media('>=tablet') {
        order: 1;
        flex-basis: 60%;
        flex-wrap: nowrap;
        width: 60%;
        padding-top: 60px;
        padding-right: 10px;
    }
}

.monogram-step + .monogram-step {
    margin-top: 20px;
}

.monogram-title {
    margin: 0;
    font-size: 16px;
    text-align: center;
    padding: 0 25px;

    @include media('>=tablet', '<desktop') {
        font-size: 18px;
        margin-top: 4px;
    }

    @include media('>=desktop') {
        font-size: 24px;
    }
}

.monogram-step-label {
    color: $blue;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 6px;
    margin-left: 20px;

    @include media('>=tablet') {
        margin-left: 0;
    }

    @include media('>=tablet', '<desktop') {
        font-size: 16px;
    }

    @include media('>=tablet') {
        font-size: 18px;
    }
}

.monogram-separator {
    text-align: center;
    margin: 10px auto 4px;

    .svgwave {
        transform: scale(0.5);
    }

    .svgwavepath {
        stroke: $blue;
    }
}

.monogram-thumbnails {
    order: 2;
    flex: 0 0 100%;
    width: 100%;
    padding: 10px 30px 0;

    @include media('<tablet') {
        position: relative;

        .slick-arrow {
            position: absolute;
            top: calc(50% - 25px);
            z-index: 2;
        }

        .slick-prev {
            left: -50px;
        }

        .slick-next {
            right: -50px;
        }
    }

    @include media('>=tablet') {
        order: 1;
        flex-basis: 100px;
        width: 100px;
        padding: 0 10px 0 0;
    }
}

.monogram-main-image-wrapper {
    order: 1;
    flex: 0 0 100%;
    width: 100%;
    padding-bottom: 10px;

    @include media('>=tablet') {
        order: 2;
        flex-basis: calc(100% - 100px);
        width: calc(100% - 100px);
        padding-bottom: 0;
        padding-left: 10px;
    }
}

.monogram-help.invalid,
.monogram-error {
    color: $red;
}

.monogram-help,
.monogram-error {
    font-size: 11px;
}

.monogram-error-invisible {
    visibility: hidden;
}

.monogram-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.monogram-thumbnail-slide {
    width: 100px;
}

.monogram-show-other-colors {
    text-decoration: underline;
    cursor: pointer;
    color: $blue;
    font-size: 12px;
    display: inline-block;
    margin-left: 20px;
    align-self: center;
}

.monogram-image-overlay {
    position: relative;

    &:focus {
        .monogram-image {
            border: 3px solid $blue-hover;
        }
    }
}

.monogram-thumbnail {
    border: 1px solid $white;
}

.monogram-thumbnail-slide-content {
    border: 1px solid $white;
    margin: 0 5px;

    @include media('>=tablet') {
        margin: 5px 0;
    }

    .slick-current & {
        border-color: $blue;
    }
}

.monogram-placement-image {
    flex: 0 0 auto;
    max-width: 60px;
    width: auto;
    max-height: 100%;

    @include media('>=tablet') {
        max-width: 90px;
    }
}

.monogram-placement-text {
    flex: 1 1 auto;
    font-size: 12px;
    text-transform: none;
    line-height: 1.1em;
    padding: 0 4px;
}

.monogram-set-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: normal 14px/21px $Arial;
    color: $blue;
    margin-bottom: 12px;
}

.monogram-set-value {
    display: inline-block;
    color: $mouse-grey;
    padding-left: 16px;
}

.monogram-set-label {
    font-weight: bold;
}

.tap-zoom-wrapper {
    display: none;
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 50%;
    z-index: 1;
}

.tap-zoom {
    @include mobile-first-icon(tapzoom);
    @include viewport-icon('tapzoom_big', '>=tablet', '<desktop');
}

.pdpzoom-trigger-wrapper {
    display: none;

    @include media('>=desktop') {
        display: block;
        cursor: pointer;
        position: absolute;
        bottom: 10px;
        right: 10px;
    }

    .pdpzoom-trigger {
        transform-origin: center;
    }
}

.pdpzoom-trigger {
    @include mobile-first-icon(pdp_zoom);
}

.iframe-fluid-container {
    position: relative;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    overflow: hidden;
}

.product-image-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.zoom-loader {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -75px 0 0 -75px;
    width: 150px;
}

.productform-sizingalert {
    font: 12px / 14px $Arial;
    color: $mouse-grey;
    margin-bottom: 12px;
}

.product-image-ovelay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    color: $white;
    font: normal 48px/63px $Arial;
    z-index: 2;
    cursor: pointer;

    @include media('>=tablet') {
        display: flex;
    }
}

.zoomImagesDialog {
    background-color: $white;

    &.ui-dialog {
        padding: 0 !important;
        height: 100vh !important;
        width: 100vw !important;
        max-height: 100vh !important;
        max-width: 100vw !important;
        min-height: 100vh !important;
        min-width: 100vw !important;
    }

    .product-primary-zoom {
        height: 100vh !important;
        width: 100vw !important;
        max-height: 100vh !important;
        max-width: 100vw !important;
        min-height: 100vh !important;
        min-width: 100vw !important;
        overflow: hidden !important;
        cursor: all-scroll;
    }

    .zoom-image {
        width: 1960px;
        height: 1960px;
        object-fit: none; // Do not scale the image, it will be rendered in 1960px*1960px
    }
}

.pdp-tabs-container {
    .tabs-accordion-header {
        font: bold 16px/20px $Arial;
        text-transform: none;
        border-color: $fishnet-grey;

        @include media('>=tablet', '<desktop') {
            font-size: 20px;
        }
    }

    .tab {
        display: none;
    }
}

.product-dialog-footer {
    margin-top: 16px;
}

.productset-product-detail-container {
    .product-detail-price {
        color: $blue;
    }

    .price-sales {
        font: 18px/18px $MontserratBold;
    }

    .price-standard {
        font: 12px/14px $Arial;
    }

    .pdp-preorder-msg {
        margin: 10px 0;
    }
}

// reviews
.reviews-stars {
    .netreviews_review_rate_and_stars {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        padding: 0;
        top: 0;
        cursor: pointer;
    }

    .netreviews_bg_stars,
    .netreviews_review_rate_and_stars {
        div:not(.netreviews_reviews_rate) {
            position: static;
            font-size: 12px;
        }

        // stars imposition
        div:not(.netreviews_reviews_rate):nth-child(2) {
            margin: 0 0 0 -60px;
        }
    }

    .netreviews_reviews_rate_empty {
        margin: 0 0 0 60px;
    }
}

.product-detail-reviews {
    .product-detail-reviews-anchor {
        display: block;
        margin-top: 4px;

        @include media('>=desktop') {
            margin-top: 12px;
        }
    }

    .netreviews_reviews_rate {
        position: static !important;
        padding: 0 0 0 8px;
        text-transform: none;
        text-decoration: underline;
        font: 14px/20px $Arial !important;
    }
}

.product-review {
    color: $blue;
}

.product-rating-label {
    text-align: center;
    font-family: $MontserratBold;
    text-transform: uppercase;
    font-size: vw(24px);
    line-height: vw(26px);

    @include media('>=desktop') {
        font-size: 12px;
        line-height: 16px;
    }
}

// PRODUCT SET DETAIL PAGE
.productset-wrapper {
    padding: 10px;

    @include media('>=tablet') {
        padding: 10px 0;
    }
}

.productset-description-inner {
    .gl_wave-separator {
        margin-bottom: 40px;
    }
}

.productset-name {
    text-align: center;
    text-transform: none;
    font-size: vw(48px);
    margin-bottom: 10px;

    @include media('>=tablet') {
        font-size: 24px;
    }
}

.productset-content-wrapper {
    margin-top: 20px;
    flex-flow: row wrap;
    justify-content: center;

    @include media('>=tablet') {
        flex-flow: row nowrap;
        margin: 60px 10% 0;
    }
}

.productset-description-wrapper,
.productset-main-image-wrapper {
    flex: 0 0 70%; // so the image is not too tall

    @include media('>=tablet') {
        flex: 1 1 50%;
        padding: 0 10px;
    }
}

.productset-main-image-wrapper {
    margin-bottom: 30px;

    @include media('>=tablet') {
        margin-bottom: 0;
    }
}

.productset-description-wrapper {
    @include media('>=tablet') {
        display: flex;
    }
}

.productset-description {
    background-color: $white;
    border: 10px solid $blue;
    outline: 10px solid $white;
    border-image-slice: 10 !important;
    padding: vw(95px) vw(25px);
    text-align: center;
    width: 100%;

    @include media('>=tablet') {
        padding: 25px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: stretch;
        align-content: center;
    }
}

.productset-shopall-wrapper {
    margin: 20px 0;
    text-align: center;

    .gl_wave-separator {
        margin: 15px 0;
    }

    .product-detail-price {
        font-weight: bold;
    }

    .pdpForm {
        margin: 0;
    }

    @include media('>=tablet') {
        margin: 60px 0;
    }
}

.productset-shop-look {
    text-transform: uppercase;
    font-size: vw(48px);
    font-weight: bold;
    color: $blue;
    text-align: center;

    @include media('>=tablet') {
        font-size: 24px;
    }
}

.add-all-to-cart {
    margin-top: 25px;
    width: auto;

    &.gl_button {
        padding: 0 60px;
        font-size: 13px;
    }
}

.productset-promotions {
    text-align: center;

    .promotion {
        margin: 20px 0;
    }
}

.productset-selectlook {
    text-align: center;
    color: $blue;
    font-size: 13px;
}

.productset-details {
    margin: 10px 0 40px;

    .product-item-name {
        text-decoration: none;
    }

    .add-sub-product {
        margin-top: 6px;
    }

    .product-detail-name {
        font-size: 18px;
        line-height: 18px;
        text-align: center;
    }
}

.productset-product-detail-container {
    position: relative;
    padding: vw(40px);
    background: $white;
    max-width: 90%;
    border: 1px solid $blue;
    margin: 0 auto;

    @include media('>=desktop') {
        max-width: 380px;
        padding: 20px;
    }

    @include media('>=desktopLarge') {
        padding: 30px 40px;
    }
}

.productset-item {
    margin-top: 30px;
}

.productset-item-content {
    background: $grey-bg;
    padding-bottom: 2%;

    @include media('>=desktop') {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-flow: row nowrap;
    }
}

// product image on Product set & Mix-And-Match pages
.productset-item-content {
    .product-image-container {
        @include media('>=desktop') {
            flex: 0 0 70%;
            width: 70%;
            max-width: 70%;
        }

        @include media('>=desktopLarge') {
            flex: 0 0 65%;
            width: 65%;
            max-width: 65%;
        }
    }
}

.productset-item-detail {
    @include media('>=desktop') {
        width: auto;
        flex: 1 0 30%;
        max-width: 30%;
        margin: 2.5% 2.5% 2.5% 0;
    }

    @include media('>=desktopLarge') {
        margin: 4.5% 4.5% 4.5% 0;
    }
}

.productset-item-number {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;

    @include media('>=tablet') {
        text-align: right;
        padding-right: 15px;
    }
}

.pdp-ps-image-wrapper {
    text-align: center;

    @include media('>=desktop') {
        padding: 0 10%;
    }
}

.pdp-ps-image {
    width: auto;
    margin: 0 auto;
    max-width: 100%;
}

.pdp-ps-image-slider-wrapper {
    margin: 20px auto;

    @include media('<tablet') {
        .pdp-ps-image-slider {
            padding: 2% 8% 0;
        }
    }

    @include media('>=tablet', '<desktop') {
        .pdp-ps-image-slider {
            padding: 2% 20% 0;
        }
    }

    @include media('<desktop') {
        .slick-dots {
            margin-top: 20px;
        }
    }

    @include media('>=desktop') {
        .pdp-ps-image-slider {
            padding: 0 100px;
            position: relative;
        }

        .slick-arrow {
            position: absolute;
            top: 50%;
            margin-top: -25px;
        }

        .slick-prev {
            left: 60px;
        }

        .slick-next {
            right: 60px;
        }
    }
}

.product-detail-model {
    margin: 15px 0;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: $blue;

    @include media('>=desktopLarge') {
        font-size: 14px;
    }
}

.product-detail-model-label {
    font-family: $MontserratBold;
}

.view-product-detail {
    font-size: 12px;
    display: block;
    text-align: center;
    margin: 41px auto 0;
}

.reviews-tab {
    // ERV-442 Giant top padding for Reviews section on tablet - Digital team asked for no space for this tab
    padding: 0;

    #netreviews_reviews_tab {
        font-family: $Arial;
    }

    #netreviews_rating_section {
        padding: 0;
        border-width: 0;
    }

    .netreviews_rating_header {
        height: 40px;
        min-height: 40px;

        div {
            float: none;
            text-align: center;
        }
    }

    .netreviews_certification {
        position: static;
    }

    #netreviews_informations_label {
        display: none;
    }

    .netreviews_logo {
        position: static;
    }

    .netreviews_reviews_rate,
    .netreviews_certification,
    .netreviews_certification:active,
    .netreviews_certification:visited,
    span[id^=netreviews_rate_total],
    .netreviews_filtering_section,
    .netreviews_subtitle {
        color: $blue;
    }

    .netreviews_note_generale {
        font-size: 42px;
        line-height: 42px;
    }

    .netreviews_bg_stars_big div {
        font-size: 18px;
    }

    .netreviews_subtitle {
        font-size: 14px;
        padding: 0 3px;
    }

    .netreviews_global_rating {
        width: 40%;
    }

    .netreviews_global_rating_details {
        width: 60%;
    }

    .netreviews_rates_list {
        justify-content: space-around;
    }
}

.tab .netreviews_filtering_section select {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;

    // force styleguide rules (KO on firefox otherwise)
    height: vw(84px);
    line-height: calc(11vw - 4px);

    @include media('>=480px') {
        height: 42px;
        line-height: 38px; // minus border
    }
}

.duties-and-taxes {
    display: block;
    text-align: center;
    padding: 0 0 6px;
    font-size: vw(24px);
    color: $blue;

    .store-availability-wrapper & {
        display: none;
    }

    @include media('>=tablet') {
        font-size: 20px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }

    &.mini-cart-subtotals-label {
        text-align: left;
        padding: 5px 0 0;
    }

    .cross-sell-pdp & {
        font-size: 12px;
    }
}

.add-to-cart.disabled,
.product-ufd-button.disabled,
.product-ufd-button[disabled] {
    background: $add-to-cart-disabled;

    &:hover {
        background: $add-to-cart-disabled !important;
    }
}

// Quickview (use case: Modify in the cart)
#QuickViewDialog {
    width: 100vw !important;
    height: 100vh !important;
    min-height: 100vh !important;
    background-color: $white;
    overflow: auto;

    @include media('>=desktop') {
        width: 864px !important;
        height: auto !important;
        min-height: auto !important;
        margin: 0 auto;
    }

    @include media('>=desktopLarge') {
        width: 1154px !important;
    }
}

.quickview-dialog {
    @include media ('<desktop') {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        height: 100% !important;
        width: 100% !important;
    }

    .ui-dialog-titlebar-close {
        display: none;
    }
}

.quickview-pdp-header {
    .product-detail-name {
        font: normal 18px/24px $MontserratBold;
        color: $blue;
        text-transform: none;
        font-weight: bold;
        letter-spacing: normal;
        text-transform: uppercase;
    }
}

.pdp-actions-wrapper {
    text-align: center;

    .product-pdp-button {
        margin-top: 19px;
    }

    .product-pdp-fake-link-btn {
        margin-top: 6px;
    }
}

.product-ufd-button {
    margin-top: 16px;
}

.product-pdp-fake-link-btn {
    font: normal 14px/21px $Arial;
}

.pdp-unavailable-size-block {
    @include media('>=desktop') {
        margin-top: 16px;
    }
}

.product-carousel-component.last-visited,
.product-carousel-component.newin-wrapper,
// also impact cart page
.product-carousel-component.pdp-predictive-carousel {
    .gl_svg-wave-separator {
        width: 125px;
        margin: 16px auto 26px;
        text-align: center;
    }
}

// predictive intelligence
div[id^="cq_recomm_slot-"] {
    width: 100%;

    .product-carousel-component-title {
        text-align: center;
    }
}

.product-additional-content-list {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
    margin-top: 19px;
}

.shipping-details-svg { // for cart page icons
    .st0 {
        fill: $blue;
    }

    .st1 {
        fill: $white;
    }

    .st2 {
        stroke: $blue;
    }
}

// =================================
// before EPU-1
// =================================

/*
// Product form sticky: on mobile pdp & cross-sell dialog
.page-with-sticky-pdp-form {
    padding-bottom: 115px;

    @include media('>=desktop') {
        padding-bottom: 0;
    }
}


// Loader container

.attribute {
    .store-availability-wrapper & {
        float: left;
        height: 100%;
        position: relative;
    }

    .store-availability-wrapper & {
        width: 100%;
        border-bottom: 1px solid $light-grey;

        @include media('>=desktop') {
            width: 50%;
            border-bottom: 0;
        }
    }
}

.attribute-size {
    .store-availability-wrapper & {
        margin: 0 0 0 -1px;
    }

    .store-availability-wrapper & {
        .variation-select-title {
            border-right: 0;
        }
    }
}

.size-list {
    padding: 0;

    .size-select {
        height: 44px;
        text-transform: none;
        font-family: $Mono;

        @include media('<tablet') {
            font-size: 12px;
        }
    }

    .size-select-error-msg-sticky {
        max-width: 90%;
        height: 20px;
        overflow: hidden;
    }
}

.product-variation-list {
    .store-availability-wrapper & {
        display: none;
        position: absolute;
        padding: 10px 3px 0 10px;
        background: $white;

        @include media('>=desktop') {
            border: 1px solid $light-grey;
            border-width: 0 1px 1px;
        }
    }

    .store-availability-wrapper & {
        position: relative;
        padding: vw(50px) vw(35px);

        @include media('>=tablet') {
            padding: 50px 35px;
        }

        @include media('>=desktop') {
            position: absolute;
            padding: 10px 3px 0 10px;
        }
    }
}

.variation-title-block {
    .store-availability-wrapper & {
        display: block;
    }

    .store-availability-wrapper & {
        line-height: 50px;

        @include media('>=desktop') {
            line-height: normal;
        }
    }
}

.product-sticky-col1 {
    float: left;
    width: 40%;
    padding: 20px 5px 0 20px;

    .store-availability-wrapper & {
        width: 100%;
        padding: vw(60px) vw(60px) 0;

        @include media('>=tablet') {
            padding: 60px 60px 0;
        }

        @include media('>=desktop') {
            padding: 0;
            height: 75px;
            align-items: center;
            width: 70%;
        }
    }
}

.product-sticky-col2 {
    float: left;
    width: 30%;

    .store-availability-wrapper & {
        float: right;
        width: 100%;
        margin-top: vw(70px);

        @include media('>=tablet') {
            margin-top: 35px;
        }

        @include media('>=desktop') {
            margin-top: 0;
            width: 30%;
        }
    }
}

.product-sticky-col3 {
    float: right;
    width: 30%;
    padding: 20px 20px 0;
}

// PDP Thumbnail slider
.pdp-thumbnail-slider {
    width: 80px;
    margin: 0 10px;
    display: none;

    .pdp-thumbnail-slide {
        width: 100%;
        height: 80px;
        margin: 0 0 5px;
        cursor: pointer;
        overflow: hidden;
    }

    .slick-current {
        .productthumbnail {
            border-color: $blue;
        }
    }

    @include media('>=desktop') {
        display: block;
    }
}



.thumbnailvideo {
    text-align: center;
}

.productthumbnail {
    height: 100%;
    width: auto;
    min-width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid transparent;
}

.pdp-thumbnail-slider-wrapper {
    .slick-arrow {
        &::before {
            width: 20%;
            height: 20%;
        }
    }
}

// PDP product primary image
.product-info {
    margin: 0 0 70px;
}

.pdp-tabs {
    position: relative;
    overflow: hidden;
    margin-top: 41px;
}

.pdp-tabs-img {
    width: 7%;
}

.pdp-tabs-container {
    @include media('>=desktop') {
        margin: 0;
        background: $white;

        .tabs-accordion-header {
            .gl_plus-button {
                width: 18px; // to match .gl_close-button width
            }

            .gl_plus-button,
            .gl_close-button {
                font-size: 20px;
                font-weight: bold;
                line-height: 20px;
                text-align: center;

                span {
                    position: static;
                    display: block;
                    line-height: 20px;
                }
            }
        }
    }

    .tabs-accordion-header {
        font: bold 16px/20px $Arial;
        text-transform: none;
        border-color: $fishnet-grey;
    }

    .size-chart-selector-wrapper {
        margin-top: 4px;
    }
}

// PDP product form


.product-detail-description {
    color: $blue;
    text-align: center;
    margin: vw(20px) 0 vw(10px);
    font-size: vw(36px);

    @include media('>=tablet') {
        font-size: 23px;
    }

    @include media('>=desktop') {
        margin: 20px 0 10px;
        font-size: 18px;
    }
}

.product-detail-separator {
    margin: 8px 0;
}

.product-detail-variations {
    clear: both;
    height: 100%;

    .variation-attribute {
        display: inline-block;
        vertical-align: top;
        margin: 0 8px 8px 0;
    }

    .variation-attribute:last-child {
        margin: 0 0 8px;
    }
}

.pdp-detail-variations .attribute {
    margin-top: 21px;
}

.attribute-color {
    // fix for inner scroll content in the fieldset
    .color-fieldset {
        min-width: 100%;
        max-width: 100%;
    }

    .swatch-list {
        max-width: 100%;
        overflow: auto;
        display: flex;
        flex-flow: row nowrap;
        gap: 8px;

        @include media('>=desktop') {
            display: block;
        }
    }
}

.title-size-value {
    display: none;

    &.show-title {
        display: inline-block;
    }

    .store-availability-wrapper & {
        display: none;

        @include media('>=desktop') {
            display: inline-block;
        }
    }
}

.title-swatch-value {
    .store-availability-wrapper & {
        display: none;

        @include media('>=desktop') {
            display: inline-block;
        }
    }
}

.product-variations,
.product-attributes-list {
    height: 100%;
}

.product-attributes-list {
    .store-availability-wrapper & {
        border-top: 1px solid $light-grey;

        @include media('>=desktop') {
            border-top: 0;
        }
    }
}

.variation-select-title {
    text-align: center;
    color: $blue;
    text-transform: uppercase;
    position: relative;

    .attribute & {
        text-align: left;
        margin: 0 0 12px;
        text-transform: none;
        font: bold 14px / 20px $Arial;
    }

    .title-swatch-value {
        font-weight: normal;
        color: $mouse-grey;
    }

    .pdp-detail-variations .attribute-color & {
        margin: 0 0 14px;
    }

    .pdp-detail-variations .attribute-size & {
        margin: 0 0 12px;
    }

    .store-availability-wrapper & {
        text-align: left;
        margin: 0;
        height: 50px;
        cursor: pointer;
        border-right: 1px solid $light-grey;
        padding-left: vw(50px);

        &.active {
            background-color: $light-grey;
        }

        @include media('>=tablet') {
            padding-left: 50px;
        }

        @include media('>=desktop') {
            padding-top: 20px;
            padding-right: 15px;
            padding-left: 15px;
            height: 100%;
            min-height: 75px;
            display: block;

            &.active {
                background-color: transparent;
            }

            &::after {
                content: '';
                position: absolute;
                right: 15px;
                top: 25%;
                margin: 4px 0;
                @include triangle-down();
            }

            &.active {
                &::after {
                    @include triangle-up();
                }
            }
        }
    }

    @include media('>=desktop') {
        margin: 10px 0 15px;
    }
}

.title-size-label,
.title-size-value {
    display: none;
}

.size-variation-find {
    text-transform: none;
    font-size: vw(24px);
    padding-left: 10px;
    text-decoration: underline;
    color: $blue;
    cursor: pointer;

    .store-availability-wrapper & {
        display: none;
    }

    @include media('>=tablet') {
        font-size: 20px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.availability-web {
    font-size: vw(24px);

    @include media('>=tablet') {
        font-size: 20px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.tooltip {
    .pdp-in-quickview & {
        display: none;
    }
}

.tooltip-content {
    display: none;
}

.back-order-section {
    margin: 20px 0 0;
}

// Link is on QV (modify in cart) & Shop By Look page


//PDP below the fold

// PDP tabs / accordion
.pdpdetails-tab-heading {
    font-size: vw(28px);
    text-align: center;

    @include media('>=desktop') {
        font-size: 14px;
    }
}

.pdp-tab-list {
    padding: 50px 0 0;
    text-align: center;
}

.pdp-tab-list-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 35px;

    &.ui-state-active {
        .pdp-tab-list-link::after {
            visibility: visible;
        }
    }
}

a.pdp-tab-list-link {
    text-transform: capitalize;
    font-size: 20px;
}

.product-description-tab,
.care-instructions-tab {
    font: normal 14px/24px $Arial;
    color: $blue;
}

.product-description-tab {
    margin-top: 39px;
}

// PDP giftblock slider section

.pdp-block-slider {
    margin: 30px 0 40px;
    padding: 0 3.8%;
    font-size: vw(24px);
    color: $blue;

    @include media('>=tablet') {
        margin: 36px 0 30px;
    }

    @include media('>=desktop') {
        margin: 62px auto 65px;
        max-width: 1024px;
        padding: 0;
        font-size: 12px;
    }

    @include media('>=desktopLarge') {
        max-width: 1255px;
    }
}



// PDP craftmanship slider section
.craftmanship-slider {
    margin: vw(20px) 0 0;

    @include media('>=desktop') {
        margin: 10px 0 0;
    }
}

.pdp-craftmanship-slider-text {
    text-align: center;
    background: $grey-bg;
    padding: 5%;
    font-size: vw(24px);
    margin: 0 0 vw(20px);
    justify-content: center;
    flex: 1;

    @include media('>=desktop') {
        margin: 0;
        font-size: 12px;
        padding: 0 5%;
    }
}

.craftmanship-section-title {
    font-size: vw(36px);
    text-transform: capitalize;

    @include media('>=desktop') {
        font-size: 18px;
    }
}

.pdp-craftmanship-slider-row {
    flex-flow: column wrap;
}

.craftmanship-slider-col1,
.craftmanship-slider-col2 {
    flex: 1 0 100%;

    @include media('>=desktop') {
        flex: 1;
    }
}

.pdp-craftmanship-slider-imagetop {
    width: 100%;
    margin: 0 0 vw(20px);

    @include media('>=desktop') {
        margin: 0 0 10px;
    }
}

.craftmanship-slider-col1 {
    margin: 0 10px 0 0;
}

.craftmanship-section-separator {
    margin: 10px 0 20px;
}

// Product carousel deviation on PDP
.product-carousel-component.pdp-product-carousel-component {
    margin: 30px 0 40px;

    @include media('>=tablet') {
        margin: 36px 0 30px;
    }

    @include media('>=desktop') {
        margin: 62px auto 65px;
        max-width: 1024px;
    }

    @include media('>=desktopLarge') {
        max-width: 1255px;
    }
}





.no-family-badge {
    .family-badge {
        display: none;
    }
}

// predictive intelligence
div[id^="cq_recomm_slot-"] {
    width: 100%;
}



// PDP Recently viewed products
.product-listing {
    margin: vw(140px) 0 0;

    @include media('>=desktop') {
        margin: 70px 0 0;
    }
}

.product-listing.last-visited {
    @include media('>=desktop') {
        margin: 65px auto 50px;
        max-width: 1004px;
    }

    .last-visited-tiles-container {
        .last-visited-tile-wrapper {
            display: block;

            @include media('>=desktopLarge') {
                flex-basis: 25%;
                max-width: 25%;
            }
        }
    }

    .last-visited-view-more {
        display: block;
        max-width: 250px;
        margin: 20px auto 0;
    }
}

// =================================
//    Seo links deviation on PDP
// =================================
.pdp-sistercategorylinks-wrapper {
    margin: 0 0 25px;
}

.cross-sell-active {
    // no scroll on page body
    @include media('<desktop') {
        overflow: hidden;
        position: fixed;
    }
}

// ENF-5 - Multiple bonus products
.bonus-anchor {
    cursor: default;
}

.pdp-unavailable-size-block {
    text-align: center;
    margin: 20px 15px 0;

    @include media('>=desktop') {
        margin: 20px 0 0;
    }
}

.pdp-unavailable-size-description,
.pdp-unavailable-size-option {
    font-size: vw(24px);
    color: $blue;

    @include media('>=tablet') {
        font-size: 20px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.pdp-unavailable-size-option {
    margin: 20px 0 10px;
}

.product-pdp-button {
    margin: 10px 0 0;
}

.product-ufd-button {
    @include media('>=desktop') {
        margin: 10px 0 0;
    }
}

.product-pdp-fake-link-btn {
    display: block;
    text-align: center;
    width: 100%;
    margin: 10px 0 0;
    font: vw(24px) $Arial;

    @include media('>=tablet') {
        font-size: 20px;
    }

    @include media('>=desktop') {
        font-size: 12px;
    }
}

.pdp-preorder-msg {
    text-align: right;
    font: 12px/12px $Arial;
    color: $royal-blue;
    margin: 0 0 10px;

    @include media('>=desktop') {
        text-align: center;
    }
}

.pdp-preorder-msg.cross-sell-preorder-msg {
    text-align: right;
}

.pdp-preorder-msg.product-set-preorder-msg {
    text-align: center;
}

.product-add-to-cart {
    .instorecollect-btn {
        height: 44px;
        font-size: 10px;
        line-height: 44px;
    }
}







.cross-sell-pdp-links {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}



.pdp-not-quickview {
    .product-detail {
        position: relative;

        @include media('>=desktop') {
            width: 250px;
            margin: 0 10px;
        }

        @include media('>=desktopLarge') {
            width: 505px;
        }
    }

    .pdpForm {
        margin: 18px 20px;
    }
}

.pdp-block-dots {
    margin: vw(90px) 0 0;

    @include media('>=desktop') {
        margin: 0;
    }

    @include media('>=desktopLarge') {
        margin: 45px 0 0;
    }
}

li.pdp-block-dots-item {
    margin-right: vw(45px);

    @include media('>=desktop') {
        width: 13px;
        min-width: 13px;
        height: 13px;
        margin-right: 15px;
    }

    &:last-child {
        margin-right: 0;
    }
}
*/
