.h-mosaic_3_images-container {
    display: grid;
    padding: 30px 25px 40px 30px;
    background-color: $blue;
    color: $white;

    // css hack to have proper anchor view on scroll if sticky panel is
    padding-top: calc(30px + 48px + 40px);
    margin-top: -88px;

    @include media('>=1000px') {
        display: grid;
        padding-left: 45px;
        padding-bottom: 100px;
        padding-right: 90px;
    }

    @include media('>=1000px', '<1200px') {
        grid-template-columns: 200px 50px 1fr 50px 100px 50px;
    }

    @include media('>=1000px', '<desktop') {
        padding-top: calc(50px + 48px + 40px);
    }

    @include media('>=desktop') {
        // css hack to have proper anchor view on scroll if sticky panel is
        padding-top: calc(50px + 80px + 40px);
        margin-top: -120px;
    }

    @include media('>=1200px') {
        grid-template-columns: 375px 70px 1fr 70px 197px;
    }

    @include media('>=desktopLarge') {
        padding: 70px 120px 130px 85px;

        // css hack to have proper anchor view on scroll if sticky panel is
        padding-top: calc(70px + 80px + 40px);
        margin-top: -120px;
    }
}

.h-mosaic_3_images-img_cell-1 {
    grid-row: 1;
    grid-column: 2;
    flex: 0 0 calc(30% + 50px);
    max-width: calc(30% + 50px);
    min-width: calc(85px + 50px);
    padding: 0 25px;
    justify-self: flex-end;

    @include media('>=1000px') {
        grid-row: 1 / 3;
        grid-column-start: 4;
        grid-column-end: 6;
        padding: 0;
        max-width: 145px;
        margin: 0 20px 20px 0;
        justify-self: flex-start;
    }

    @include media('>=1200px') {
        grid-row: 1 / 3;
    }
}

.h-mosaic_3_images-subtitle {
    grid-row: 1;
    grid-column: 1;
    font-family: $MontserratBold;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 15px;
    padding: 22px 0 8px;
    letter-spacing: 0.025em;

    @include media('>=1000px') {
        grid-column: 1;
        grid-row: 1;
    }

    @include media('>=1000px', '<1200px') {
        padding: 5px 0 20px;
    }

    @include media('>=1200px') {
        padding: 5px 0 78px;
    }
}

.h-mosaic_3_images-quote {
    grid-row: 2;
    grid-column: 1 / 3;
    font-family: $MontserratBold;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 33px;
    letter-spacing: 0.06em;
    text-align: center;
    margin: 22px 0 18px;

    @include media('>=1000px') {
        grid-row: 2;
        grid-column-start: 2;
        grid-column-end: 4;
        z-index: 1;
        max-width: 517px;
    }

    @include media('>=1000px', '<1200px') {
        margin: 0 60px 40px 0;
        align-self: end;
    }

    @include media('>=1200px') {
        margin: 30px 10px 10px 0;
    }

    @include media('>=desktopLarge') {
        font-size: 25px;
    }
}

.h-mosaic_3_images-img_cell-2 {
    grid-row: 3;
    grid-column: 1 / 3;
    padding: 0 10px 22px;

    @include media('>=1000px') {
        grid-column: 1 / 3;
        padding: 0;
    }

    @include media('>=1000px', '<1200px') {
        grid-row-start: 3;
        grid-row-end: 4;
    }

    @include media('>=1200px') {
        grid-row-start: 2;
        grid-row-end: 4;
    }
}

.h-mosaic_3_images-img-2 {
    width: auto;
    max-width: 100%;
    margin: 0 auto;

    @include media('>=desktop') {
        margin: 0;
        width: 100%;
    }
}

.h-mosaic_3_images-text {
    grid-row: 4;
    grid-column: 1 / 3;

    @include media('>=1000px') {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row: 3;
        justify-self: center;
        padding: 0 50px;
        align-self: end;
        max-width: 620px;
    }

    @include media('>=1200px') {
        padding: 0 30px;
    }

    @include media('>=desktopLarge') {
        padding: 0 60px;
    }
}

.h-mosaic_3_images-title {
    font-size: 30px;
    line-height: 37px;
    color: $white;
    margin: 0 0 18px;

    @include media('>=1000px') {
        grid-column: 2;
        grid-row: 2;
    }

    @include media('>=desktopLarge') {
        font-size: 45px;
        line-height: 50px;
    }

    span {
        color: $rounge;
    }
}

.h-mosaic_3_images-desc {
    font-size: 16px;
    line-height: 22px;

    @include media('>=desktopLarge') {
        font-size: 18px;
        line-height: 25px;
    }
}

.h-mosaic_3_images-img_cell-3 {
    position: relative;
    grid-row: 5;
    grid-column: 1 / 3;
    max-width: 42%;
    padding: 40px 0 0;

    @include media('>=1000px') {
        grid-row: 3;
        grid-column: 5 / 7;
        align-self: center;
        max-width: 197px;
        padding: 0;
    }
}

.h-mosaic_3_images-icon_wrap {
    position: absolute;
    top: 10px;
    right: -70px;

    @include media('>=tablet') {
        top: 40px;
        right: -80px;
    }

    @include media('>=1000px') {
        top: -90px;
        right: -40px;
    }

    @include media('>=1200px') {
        top: -130px;
        right: -40px;
    }
}
