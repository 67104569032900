.pd-btn-link.gl_btn {
    display: block;
    margin: 35px 15px;
    width: calc(100% - 30px);

    @include media('>=tablet') {
        margin: 35px auto;
        width: 300px;
    }
}
