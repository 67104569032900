.stories-block {
    @include media('>=tablet') {
        padding: 30px 0 0;
    }

    .stories-list {
        @include media('>=desktop') {
            display: flex;
            gap: 12px;
        }
    }
}
