// =================================
//    Footer
// =================================

// EWR20-29 - Footer - Pre-footer implementation
.prefooter {
    padding-top: 20px;

    @include media('>=desktop') {
        margin: 0 auto;
        max-width: 1000px;
    }
}

.pre-footer-picto-section {
    justify-content: center;
}

.pre-footer-picto-col {
    text-align: center;
    color: $blue;
    margin: 0 0 30px;

    @include media('>=tablet') {
        margin: 0 0 40px;
    }

    .pre-footer-picto-title {
        font-size: 11px;
        font-weight: bold;
        line-height: 14px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
    }

    svg {
        width: 50px;
        height: 50px;
    }
}

// Prefooter icons
.pre-footer-icon {
    width: auto;
    max-width: 100%;
    margin: 0 auto 5px;
}

// Back to top button
.backtotop-circle-btn {
    position: absolute;
    top: -40px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 60px;
    height: 60px;
    background: $grey-bg;
    box-shadow: 0 3px 6px 0 $backtotop-circle-shadow;
    border-radius: 50%;
    flex-direction: column;
    text-decoration: none;
    text-transform: uppercase;
    font: 12px $MontserratBold;

    @include media('>=desktop') {
        left: auto;
        right: 20px;
    }

    .chevron-container {
        height: 16px;
        transform: rotate(-90deg);

        .svgchevron {
            width: 10px;
        }

        .st0 {
            fill: $blue;
        }
    }
}

// Special max-width on footer
.footer-container {
    margin: 0 auto;
    max-width: 984px;
}

.main-footer-wrapper {
    background-color: $light-grey;
    margin: 20px 0 0;
    position: relative; // parent for back-to-top button
}

.footer-flex-container {
    display: flex;
    flex-direction: column;
}

.footer-container {
    position: relative; // parent for footer widget
}

// Footer section title
.footer-title {
    font: 13px / 16px $MontserratBold;
    letter-spacing: normal;
    color: $blue;
    text-transform: uppercase;
    text-decoration: none;

    @include media('>=desktop') {
        font: 12px / 14px $MontserratBold;
    }
}

.footer-item-list {
    display: none;

    @include media('>=desktop') {
        display: block;
    }

    &.gl_flex { // for .footer-social-icons
        display: flex;
    }
}

// Footer link
.footer-item-link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    padding: 0 36px 0 52px;
    border-top: 1px solid $footer-item-link;
    font: 13px / 16px $Arial;
    font-weight: bold;
    text-decoration: none;

    @include media('>=desktop') {
        display: block;
        border: none;
        height: auto;
        padding: 0;
        margin: 0 0 20px;
        font-size: 12px;
        font-weight: normal;
    }
}

// Footer accordion
.lifebuoysvg,
.turtlesvg,
.eyesvg {
    .st0 {
        fill: $blue;
    }
}

.svgmaplocation {
    width: 22px;
    height: 22px;

    .st0 {
        fill: $blue;
    }

    .st1 {
        fill: $white;
    }
}

// Action section: common styles
.footer-action-section {
    flex-direction: column;

    @include media('>=desktop') {
        flex-direction: row;
        margin: 0 -10px;
    }
}

.footer-action-item {
    margin: 0 0 30px;

    &.follow-us {
        .footer-social-icons {
            @include media('<340px') {
                margin: 0 -8px; // so icons are not glued together because there is no enough width
            }
        }
    }

    @include media('>=tablet') {
        margin: 15px 0 30px;
    }

    @include media('>=desktop') {
        margin: 0 0 20px;
    }

    .footer-title {
        margin: 0 0 10px;
    }

    .footer-subscribe-title {
        @include media('>=tablet') {
            margin: 0 0 16px;
        }

        @include media('>=desktop') {
            margin: 0 0 10px;
        }
    }

    .footer-subscribe-input {
        height: 44px;
        line-height: 1;

        @include media('>=desktop') {
            height: 42px;
        }
    }

    .footer-subscribe-btn {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font: 10px / 13px $MontserratBold;
        width: auto;
        background-color: $blue;
        color: $white;
        padding: 0 15px;
        text-transform: uppercase;

        @include media('>=desktop') {
            width: 40px;
            background: transparent;
            color: $blue;
            padding: 0;
        }
    }
}

// Links section: common styles
.footer-info-section {
    @include media('>=desktop') {
        margin: 15px -10px 70px;
    }
}

.footer-info-item {
    padding: 0;

    .footer-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 15px;
        border-top: 1px solid $footer-item-link;

        .footer-control-close {
            display: none;
        }

        @include media('>=tablet') {
            padding: 0 30px 0 20px;
        }

        @include media('>=desktop') {
            display: block;
            height: auto;
            padding: 0;
            margin: 0 0 20px;
            border: none;
        }
    }

    .footer-title.active {
        .footer-control-open {
            display: none;
        }

        .footer-control-close {
            display: flex;
        }
    }

    .footer-title-item {
        font: 12px / 14px $MontserratBold;
        letter-spacing: normal;
        flex-grow: 1;
    }
}

.footer-info-item:last-child {
    border-bottom: 1px solid $footer-item-link;

    @include media('>=desktop') {
        border: none;
    }
}

.footer-icon-svg {
    margin-right: 15px;

    svg {
        width: 22px;
    }
}

.footer-control {
    .svgchevron,
    .plussvg,
    .minussvg {
        width: 10px;

        .st0 {
            fill: $blue;
        }
    }
}

// Footer Wave
.footer-waves-container {
    position: relative;
    text-align: center;
    margin: 0 auto;
    height: 16px;
    top: -15px;

    .footer-waves-svg {
        width: 100%;
    }

    .footer-waves {
        fill: $light-grey;
    }
}

// Footer Social
.follow-us {
    .footer-title {
        @include media('>=desktop') {
            margin: 0 0 16px;
        }
    }
}

.footer-social-icons {
    justify-content: space-between;

    @include media('>=tablet') {
        justify-content: flex-start;
        height: 50px;
    }

    @include media('>=desktop') {
        justify-content: flex-start;
        height: 36px;
    }
}

.footer-social-icon {
    display: block;

    @include media('>=tablet') {
        margin-right: 9px;
    }

    @include media('>=desktop') {
        margin-right: 12px;
    }
}

.wechatsvg,
.youtubesvg,
.instagramsvg,
.pinterestsvg,
.facebooksvg,
.twittersvg {
    width: 50px;
    height: 50px;
    background-color: $white;
    border: 5px solid $white;
    border-radius: 50%;

    @include media('>=desktop') {
        width: 36px;
        height: 36px;
        border: none;
    }

    .st0 {
        fill: $white;
    }

    .st1 {
        fill: $blue;
    }

    &:hover .st1 {
        fill: $blue-hover;
    }
}

.instagramsvg {
    .st2 {
        stroke: $blue;
    }

    &:hover .st2 {
        stroke: $blue-hover;
    }
}

// Footer forms
.footer-form-group {
    padding: 0;

    .gl_form-mandatory {
        @include media('>=desktop') {
            width: 280px;
        }
    }
}

.footer-input {
    @include media('>=desktop') {
        width: 280px;
    }
}

// Footer widget
.footer-widget-section {
    padding: 30px 15px 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include media('>=desktop') {
        flex-direction: column-reverse;
        padding: 0;
        position: absolute;
        top: 15px;
        right: 0;
    }
}

.footer-main-logo-container {
    padding: 0 8px 0 0;

    @include media('>=desktop') {
        padding: 0;
    }
}

.turtlecirclesvg {
    width: 108px;
    height: 108px;

    .st0 {
        fill: $blue;
    }

    .st1 {
        stroke: $blue;
    }
}

.footer-review-container {
    padding: 0 0 0 8px;
    text-align: center;

    @include media('>=desktop') {
        padding: 0 0 15px;
    }
}

.site-reviews {
    display: none;
    width: 108px;
    height: 108px;

    .site-reviews-link {
        display: block;
        padding: 2px;
        border-radius: 50%;
        border: 3px solid $blue;
        background-color: $blue;
        white-space: nowrap;
        overflow: hidden;
        text-decoration: none;
        color: $white;

        &:hover {
            color: $white;
        }

        &:focus {
            outline-offset: 4px;
        }
    }

    .site-reviews-score {
        display: block;
        height: 35px;
        border-bottom: 1px solid $white;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.02em;
    }

    .site-reviews-total {
        display: block;
        height: 30px;
        border-bottom: 1px solid $white;
        font-size: 10px;
        line-height: 12px;
    }

    .site-reviews-bottom {
        display: block;
        height: 35px;
    }

    .site-reviews-title {
        max-width: 87px;
        margin: 0 auto;
        font-size: 9px;
        line-height: 18px;
        font-style: italic;
        overflow: hidden;
    }
}

.site-reviews-rateyo-stars {
    display: block;
    padding: 3px 0 0;

    &.jq-ry-container[readonly="readonly"] {
        cursor: pointer;
    }

    .jq-ry-normal-group {
        svg {
            fill: $dark-grey;
        }
    }

    .jq-ry-rated-group {
        svg {
            fill: $white;
        }
    }
}

// WeChat dialog
.weChatDialog { // jQuery UI dialog class
    position: fixed !important;
    top: 0 !important;
    left: 100vw !important;
    height: 100vh !important;
    width: 100vw !important;
    transition: transform 0.5s linear;

    &.ui-dialog {
        padding: 0;
        max-width: 100vw;
        background-color: $blue;

        @include media ('>=tablet') {
            max-width: 60vw;
        }
    }

    &.opened {
        transform: translateX(-100%);
    }

    .ui-dialog-titlebar {
        display: none;
    }

    .ui-dialog-titlebar-close {
        .ui-button-icon::before {
            color: $white;
        }
    }
}

.wechat-dialog-wrapper {
    background-color: $blue;
    height: 100%;
    min-height: 100vh;
    width: 100%;

    .main-footer & {
        display: none;
    }
}

.wechat-dialog-content {
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    text-align: center;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
    align-items: content;
    color: $white;
}

.weChatIcon {
    cursor: pointer;
}

.wechat-qrcode {
    width: auto;
}

.wechat-title {
    font-family: $MontserratBold;
    font-size: 20px;
    text-transform: uppercase;
}

.wechat-subtitle {
    font-family: $Arial;
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 20px;

    @include media('>=tablet') {
        padding-bottom: 30px;
    }
}

.wechat-account {
    font-family: $Arial;
    font-weight: bold;
    font-size: 20px;
    padding-top: 30px;
}

// crisis Dialog
.crisis-modal {
    font: 14px $Arial;
    font-weight: bold;
    padding: 60px 20px;

    @include media('>=tablet') {
        padding: 120px 40px;
        font-size: 18px;
    }
}

.crisisDialog {
    color: $blue;

    .ui-dialog-titlebar {
        height: 0;
    }

    .ui-dialog-titlebar-close {
        top: 8px;
        right: 8px;
        z-index: 3;

        .ui-button-icon::before {
            font-size: 36px;
        }
    }
}

// Footer bottom websites links
.footer-website-block {
    padding: 10px 15px 40px;

    @include media('>=desktop') {
        padding: 0 0 85px;
    }
}

.footer-website-items {
    font: 14px / 16px $Arial;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
        font-size: 12px;
    }
}

.footer-website-item {
    margin: 0 0 10px;

    @include media('>=tablet') {
        margin: 0;

        &::after {
            content: '|';
            font-size: 14px;
            padding: 0 5px;
        }

        &:last-child::after {
            content: none;
        }
    }
}

.footer-website-link {
    text-decoration: none;
}

.footer-form-group.gl_form-group {
    .gl_error-message,
    .backend-error-message {
        width: 280px;
        background-color: $white;
    }
}
