// Global blog styles

//mobile styles for the blog will be added in blog integration task
.header-blog-article-tile-wrapper {
    &:not(:first-of-type) {
        margin-top: 50px;

        @include media('>=desktop') {
            margin-top: 0;
        }
    }
}

//blog category tiles
.blog-other-category-li {
    width: 100%;
}

.blog-tile {
    width: 100%;
    text-align: center;
}

.blog-tile-title {
    font: 11px / 14px $MontserratBold;
    margin-top: 20px;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    padding: 0 20%;

    @include media('>=tablet') {
        .article-main-content & {
            line-height: 22px;
            font-size: 22px;
        }
    }

    @include media('>=desktop') {
        .article-main-content & {
            line-height: 11px;
            font-size: 11px;
        }
    }
}

.blog-tile-separator {
    width: 100%;
    margin-top: 10px;
}

.blog-tile-date {
    font: 10px / 10px $MontserratBold;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;

    @include media('>=desktop') {
        margin-top: 10px;
    }
}

.header-blog-article {
    margin-bottom: vw(60px);

    @include media('>=tablet') {
        margin-bottom: 50px;

        .header-menu-middle-section & {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.header-blog-article-row {
    width: 100%;
}

// Homepage blog article block
.small-blog-article-wrapper {
    z-index: 1;
    position: relative;
    margin: 25px 15px;

    @include media('>=desktop') {
        max-width: 833px;
        margin: 25px auto;

        .blog-category-page & {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @include media('>=desktopLarge') {
        max-width: 1121px;
    }
}

.small-blog-article-row {
    @include media('>=tablet') {
        margin: 0 -2% 0 0;
    }
}

.blog-viewall {
    position: absolute;
    right: 20px;
    top: 20px;
    font: italic vw(28px) $TimesNewRoman;

    @include media('>=tablet') {
        font-size: vw(24px);
    }

    @include media('>=desktop') {
        font-size: 14px;
    }
}

.small-blog-article-col-right.row {
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center;

    @include media('>=desktop') {
        padding: 0 20px;

        .odd-article & {
            order: 1;
            margin: 0;
            position: relative;
        }
    }
}

.small-blog-article-col-left {
    width: 100%;
    padding: 0;
    justify-content: center;
    align-items: center;
    align-content: center;

    @include media('>=tablet') {
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 2% 0;
        margin: 0 -2% 0 0;

        .odd-article & {
            order: 2;
            margin: 0 0 0 -2%;
        }
    }
}

.small-blog-article-border {
    position: absolute;
    border: 10px solid $blue;
    border-image-slice: 10 !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: $white;

    @include media('>=desktop') {
        border-image-slice: 5 !important;
        border-width: 5px;
    }

    .pdp-main & {
        border-image-slice: 5 !important;
        border-width: 5px;
    }
}

.small-blog-article-content {
    text-align: center;
    width: 100%;
    padding: vw(100px);

    @include media('>=tablet') {
        padding: 30px 10px;
    }

    @include media('>=desktop') {
        padding: 0;
    }
}

.small-blog-article-paragraph {
    margin: 0;
}

.blog-separator {
    width: 125px;
    margin: 10px auto;
    text-align: center;

    .svgwavepath {
        stroke: $blue;
    }
}

.small-blog-article-title {
    font: 16px $Arial;
    text-transform: unset;
    font-weight: bold;
    line-height: 18px;
    margin: 0 auto;
    letter-spacing: normal;

    @include media('>=desktop') {
        margin: 50px 0 0;
        font-size: 18px;
    }
}

.small-blog-article-subtitle {
    font: 12px $Arial;
    font-weight: bold;
    letter-spacing: normal;

    @include media('>=desktop') {
        font-size: 10px;
    }
}

.small-blog-article-description {
    color: $blue;
    margin-top: vw(50px);
    font: italic 16px $TimesNewRoman;

    @include media('>=tablet') {
        margin-top: 25px;
    }

    @include media('>=desktop') {
        margin-top: 30px;
    }
}

.small-blog-article-button.gl_button {
    margin: vw(50px) 0 0;
    font: 10px / 44px $MontserratBold;
    height: 44px;
    width: 188px;

    @include media('>=tablet') {
        margin: 25px 0 0;
        width: 277px;
    }

    @include media('>=desktop') {
        margin: 30px 0 25px;
    }
}
