.two-columns-component {
    margin: 45px 15px;
    color: $blue;

    @include media('>=tablet') {
        margin: 75px 70px;
    }

    @include media('>=desktop') {
        margin: 100px 20px;
    }

    @include media('>=desktopLarge') {
        margin: 100px auto;
        max-width: 1120px;
    }
}

.two-columns-title {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: normal;
    margin: 0 0 40px;

    @include media('>=tablet') {
        margin: 0 0 30px;
    }

    @include media('>=desktop') {
        display: none;
    }

    @include media('>=desktopLarge') {
        display: block;
    }

    &.two-columns-title-desktop {
        display: none;

        @include media('>=desktop') {
            display: block;
        }

        @include media('>=desktopLarge') {
            display: none;
        }
    }
}

.two-columns-text-wrapper {
    padding: 30px 0 0;

    @include media('>=tablet') {
        padding: 0;
    }

    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px;
    }

    @include media('>=desktopLarge') {
        flex: 1 0 auto;
        max-width: calc(100% - 380px);
        padding: 0 10px;
    }
}

.two-columns-image-wrapper {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px;
    }

    @include media('>=desktopLarge') {
        flex: 0 0 380px;
        max-width: 380px;
        padding: 0 10px;
    }
}

.two-columns-col-text {
    font-size: 14px;
    line-height: 20px;

    h2, h3 {
        font: inherit;
        text-transform: none;
        letter-spacing: normal;
        font-weight: bold;
        margin: 0;
    }

    p {
        margin: 0;
    }
}

.two-columns-text-row {
    @include media('>=tablet') {
        display: flex;
        flex-wrap: nowrap;
        margin: 0 -25px;
    }

    @include media('>=desktop') {
        display: block;
        margin: 0;
    }

    @include media('>=desktopLarge') {
        display: flex;
        margin: 0 -25px;
    }
}

.two-columns-col-text-1 {
    margin: 0 0 20px;

    @include media('>=tablet') {
        margin: 0;
    }

    @include media('>=desktop') {
        margin: 0 0 20px;
    }

    @include media('>=desktopLarge') {
        margin: 0;
    }
}

.two-columns-col-p2 {
    @include media('>=tablet') {
        margin: 0 0 50px;
    }
}

.two-columns-col-text {
    @include media('>=tablet') {
        flex: 0 0 50%;
        padding: 0 25px;
    }

    @include media('>=desktop') {
        padding: 0;
    }

    @include media('>=desktopLarge') {
        padding: 0 25px;
    }
}

.two-columns-content {
    @include media('>=desktop') {
        display: flex;
        flex-wrap: nowrap;
        margin: 0 -15px;
    }

    @include media('>=desktopLarge') {
        margin: 0 -10px;
    }
}

// included title-richtext component override
.two-columns-text {
    .title-richtext-component {
        margin: 0;
        max-width: none;
    }

    .title-richtext-text {
        font: inherit;
    }
}
