.h-image_gallery {
    position: relative;
    padding: 40px 0 45px 30px;
    background: $white;

    &.h-image_gallery-1 {
        z-index: 3;
    }

    &.h-image_gallery-2 {
        z-index: 1;
    }

    // css hack to have proper anchor view on scroll if sticky panel is
    padding-top: calc(40px + 48px + 40px);
    margin-top: -88px;

    @include media('>=desktop') {
        padding: 50px 0 70px 50px;

        // css hack to have proper anchor view on scroll if sticky panel is
        padding-top: calc(50px + 80px + 40px);
        margin-top: -120px;
    }

    @include media('>=desktopLarge') {
        padding: 70px 0 100px 80px;

        // css hack to have proper anchor view on scroll if sticky panel is
        padding-top: calc(70px + 80px + 40px);
        margin-top: -120px;
    }
}

.h-image_gallery-title {
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.08em;
    margin: 0 10px 17px 0;

    @include media('>=desktop') {
        font-size: 45px;
        line-height: 50px;
        margin: 0 20px 35px 0;
    }

    span {
        color: $rounge;
    }
}

.h-image_gallery-subtitle {
    font-family: $MontserratBold;
    font-size: 12px;
    line-height: 14px;
    color: $blue;
    text-transform: uppercase;
    margin: 0 0 12px;
}

.h-image_gallery-1-carousel {
    margin: 0 -3px;

    @include media('>=desktop') {
        padding: 0 -6px;
    }

    .h-image_gallery-tile {
        padding: 0 3px;

        @include media('>=desktopLarge') {
            padding: 0 6px;
        }
    }
}

.h-image_gallery-2-carousel {
    margin: 0 -6px;

    .h-image_gallery-tile {
        width: calc((100vw - 30px) / 3 * 2);
        padding: 0 6px;

        @include media('>=desktop') {
            width: calc((100vw - 50px) / 5 * 2);
        }

        @include media('>=desktopLarge') {
            width: calc((100vw - 80px) / 5 * 2);
        }
    }
}

.h-image_gallery-container {
    position: relative;
}

.h-image_gallery-arrows {
    display: inline-flex;
    position: absolute;
    bottom: -30px;
    right: 0;
    gap: 10px;
    margin: 0 50px 0 0;

    .slick-arrow {
        background-color: $beige-light;
        opacity: 0.85;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        border: 1px solid $cornflower-blue;
        z-index: 1;
    }

    .slick-prev {
        &::before {
            content: '';
            display: inline-block;
            width: 30%;
            height: 30%;
            border: 3px solid $blue;
            border-width: 2px 0 0 2px;
            transform: translateX(20%) rotate(-45deg);
        }
    }

    .slick-next {
        &::before {
            content: '';
            display: inline-block;
            width: 30%;
            height: 30%;
            border: 3px solid $blue;
            border-width: 2px 0 0 2px;
            transform: translateX(-20%) rotate(-225deg);
        }
    }
}
