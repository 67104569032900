.paragraph-image-component {
    margin: 45px 15px;
    color: $blue;

    @include media('>=tablet') {
        margin: 75px 130px;
    }

    @include media('>=desktop') {
        margin: 100px 20px;
    }

    @include media('>=desktopLarge') {
        margin: 100px auto;
        max-width: 1120px;
    }
}

.paragraph-image-content {
    padding: 30px 0 0;

    @include media('>=desktop') {
        display: flex;
        flex-wrap: nowrap;
        padding: 0;
    }

    @include media('>=desktopLarge') {
        padding: 0 95px 0 35px;
    }
}

.paragraph-image-col-text {
    @include media('>=desktop') {
        flex-grow: 1;
    }
}

.paragraph-image-col-img {
    @include media('>=desktop') {
        flex: 0 0 490px;
        max-width: 490px;
    }

    @include media('>=desktopLarge') {
        flex: 0 0 500px;
        max-width: 500px;
    }

    &.position-left {
        @include media('>=desktop') {
            padding: 0 90px 0 0;
        }

        @include media('>=desktopLarge') {
            padding: 0 100px 0 0;
        }
    }

    &.position-right {
        @include media('>=desktop') {
            padding: 0 0 0 90px;
        }

        @include media('>=desktopLarge') {
            padding: 0 0 0 100px;
        }
    }
}

.paragraph-image-image {
    @include media('<desktop') {
        margin: 0 auto;
    }
}

.paragraph-image-link {
    display: block;
    text-decoration: none;
}

.paragraph-image-col-text {
    font-size: 14px;
    line-height: 20px;

    h2, h3 {
        font: inherit;
        text-transform: none;
        letter-spacing: normal;
        font-weight: bold;
        margin: 0;
    }

    p {
        margin: 0;
    }
}

// included title-richtext component override
.paragraph-image-text {
    .title-richtext-component {
        margin: 0;
        max-width: none;
    }

    .title-richtext-text {
        font: inherit;
    }
}
