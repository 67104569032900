.title-richtext-component {
    margin: 50px 15px 30px;
    color: $blue;

    @include media('>=tablet') {
        margin: 50px auto;
    }
}

.title-richtext-title {
    font-family: $Arial;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    text-transform: none;
    letter-spacing: normal;
    text-align: center;
    margin: 0 0 15px;
}

.title-richtext-text {
    font-size: 12px;
    line-height: 14px;
    margin: 0 auto;

    @include media('>=tablet') {
        max-width: 524px;
    }

    p {
        margin: 0;
    }
}
