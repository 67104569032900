.noresult-wrapper {
    .gl_wave-separator::before {
        width: vw(125px);

        @include media('>=tablet') {
            width: 62px;
        }
    }
}

.noresults-centered-row,
.noresult-explore-header {
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
}

.noresult-header {
    margin: vw(100px) auto 20px;

    @include media('>=tablet') {
        margin: 70px auto 20px;
    }
}

.noresult-title {
    font-size: 16px;
}

.noresult-search {
    margin-bottom: 20px;

    @include media('>=tablet') {
        margin-bottom: 40px;
    }

    .search-suggestions-form {
        width: 100%;
        padding: 0 vw(36px);

        @include media('>=tablet') {
            padding: 0 10px;
        }
    }
}

.noresults-predictive {
    margin-top: 30px;
}

.noresult-title-separator {
    margin: vw(40px) auto;

    @include media('>=tablet') {
        margin: 20px auto;
    }
}

.noresult-link-separator {
    margin: vw(30px) auto vw(60px);

    @include media('>=tablet') {
        margin: 20px auto;
    }
}

.noresult-links-list {
    margin: 0 0 vw(80px);

    @include media('>=tablet') {
        margin: 0;
    }
}

.noresult-link-title {
    font-size: vw(28px);

    @include media('>=tablet') {
        font-size: 14px;
    }
}

.noresult-links-link {
    font-family: $MontserratBold;
    display: inline-block;
    font-size: vw(24px);
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 0 vw(40px);

    @include media('>=tablet') {
        font-size: 12px;
    }

    @include media('>=desktop') {
        margin: 10px 0;
    }
}

.noresult-footer {
    @include media('>=tablet') {
        margin: 70px auto;
    }
}

.noresult-explore-col {
    margin: 0 0 20px;
    position: relative;
}

.explore-universe-list {
    width: 100%;

    .noresult-explore-content-title {
        font-size: vw(36px);
        color: $white;
        text-shadow:
            -1px -1px 0 $blue,
            1px -1px 0 $blue,
            -1px 1px 0 $blue,
            1px 1px 0 $blue;

        @include media('>=tablet') {
            font-size: 18px;
        }
    }
}

.noresult-explore-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.noresult-explore-col-separator {
    margin: 5px 0;
}

.noresult-explore-title {
    font-size: vw(28px);

    @include media('>=tablet') {
        font-size: 14px;
    }
}

.noresult-explore-separator {
    margin: vw(20px) 0 vw(60px);

    @include media('>=tablet') {
        margin: 20px 0 30px;
    }
}

.nohints-discover {
    .images-carousel-component {
        @include media('>=desktop') {
            padding: 50px 0 45px;
        }
    }
}

.noresult-search-section {
    padding: 35px 40px;
    background-color: $blue;
    color: $white;

    @include media('>=tablet') {
        padding: 45px 40px;
    }
}

.noresult-search-title {
    text-align: center;
    color: $white;
    font: bold 18px / 20px $Arial;
    letter-spacing: normal;
    text-transform: none;
    margin: 0 0 10px;
}

.noresult-tips {
    text-align: center;
    margin: 10px 0 15px;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin: 30px 0 15px;
    }
}

.noresult-tips-title {
    font: bold 13px / 16px $Arial;
    color: $white;
    letter-spacing: normal;
    text-transform: none;
    margin: 0 0 15px;

    @include media('>=tablet') {
        flex-shrink: 0;
        margin: 0;
    }
}

.noresult-tips-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 12px;
    line-height: 14px;

    @include media('>=tablet') {
        justify-content: flex-start;
    }
}

.noresult-tips-item {
    padding: 0 2px 0 0;
    margin: 0 0 2px;

    @include media('>=tablet') {
        margin: 0 0 14px;
        flex-grow: 0;
        min-width: auto;
        padding: 0 20px;
        line-height: 16px;
    }
}

.noresult-search-form {
    max-width: 300px;
    margin: 0 auto;

    .noresult-search-fieldset {
        padding: 0;
    }
}

.noresult-help-wrapper {
    border-bottom: 1px solid $light-grey;
}

.noresult-help-section {
    margin: 30px;
    text-align: center;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        max-width: 600px;
        margin: 0 auto;
    }
}

.noresult-help-col1 {
    margin: 0 0 35px;
}

.noresult-help-col {
    @include media('>=tablet') {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 25px 10px;
        margin: 0;
    }

    &.noresult-help-col-grow {
        flex: 0 0 100%;
        max-width: none;
    }
}

.noresult-help-title {
    font: bold 16px / 18px $Arial;
    letter-spacing: normal;
    text-transform: none;
    margin: 0 0 5px;
}

.noresult-help-separator {
    margin: 0 0 15px;
}

.noresult-help-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 12px;
    line-height: 14px;
}

.noresult-help-item {
    padding: 0 10px 5px;
}

.noresult-categories-wrapper {
    border-bottom: 1px solid $light-grey;
}

.noresult-categories-section {
    padding: 30px 20px;
    text-align: center;

    @include media('>=tablet') {
        max-width: 600px;
        margin: 0 auto;
    }
}

.noresult-categories-title {
    font: bold 18px / 20px $Arial;
    letter-spacing: normal;
    text-transform: none;
    margin: 0 0 10px;

    @include media('>=tablet') {
        text-align: center;
    }
}

.noresult-categories-separator {
    margin: 0 0 15px;
}

.noresult-categories-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 12px;
    line-height: 14px;
}

.noresult-categories-item {
    padding: 0 10px 5px;

    @include media('>=tablet') {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}

.noresult-tools-section {
    margin: 20px 15px 40px;

    @include media('>=tablet') {
        max-width: 716px;
        margin: 20px auto 40px;
    }

    .noresult-tools-separator {
        text-align: left;
        margin: 0 0 0 -30px;

        @include media('>=tablet') {
            text-align: center;
            margin: 0 0 15px;
        }
    }
}

.noresult-tools-title {
    text-align: left;
    font: bold 18px / 20px $Arial;
    letter-spacing: normal;
    text-transform: none;
    margin: 0 0 10px;

    @include media('>=tablet') {
        text-align: center;
    }
}

.noresult-tools-list {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -7px;

    @include media('>=tablet') {
        justify-content: center;
        margin: 0;
    }
}

.noresult-tools-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 15px 7px 0;

    @include media('>=tablet') {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.noresult-tools-img {
    margin: 0 0 6px;
}

.noresult-tools-link {
    display: block;
    font: bold 13px / 16px $Arial;
    text-decoration: none;
}

.noresult-tools-link-label {
    display: block;
}

.noresult-services-section {
    margin: 20px 15px 40px;

    @include media('>=tablet') {
        padding: 20px 0;
        max-width: 716px;
        margin: 20px auto 40px;
    }

    .noresult-services-separator {
        text-align: left;
        margin: 0 0 0 -30px;

        @include media('>=tablet') {
            text-align: center;
            margin: 0 0 15px;
        }
    }
}

.noresult-services-title {
    text-align: left;
    font: bold 18px / 20px $Arial;
    letter-spacing: normal;
    text-transform: none;
    margin: 0 0 10px;

    @include media('>=tablet') {
        text-align: center;
    }
}

.noresult-services-list {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -7px;

    @include media('>=tablet') {
        justify-content: center;
        margin: 0;
    }
}

.noresult-services-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 15px 7px 0;

    @include media('>=tablet') {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}

.noresult-services-img {
    border: 1px solid $light-grey;
    margin: 0 0 6px;
}

.noresult-services-link {
    display: block;
    font: bold 13px / 16px $Arial;
    text-decoration: none;
}

.noresult-services-link-label {
    display: block;
}

.noresult-explore-section {
    margin: 20px 15px 40px;

    @include media('>=tablet') {
        padding: 20px 0;
        max-width: 716px;
        margin: 20px auto 40px;
    }

    .noresult-explore-separator {
        text-align: left;
        margin: 0 0 0 -30px;

        @include media('>=tablet') {
            text-align: center;
            margin: 0 0 15px;
        }
    }
}

.noresult-explore-h2 {
    text-align: left;
    font: bold 18px / 20px $Arial;
    letter-spacing: normal;
    text-transform: none;
    margin: 0 0 10px;

    @include media('>=tablet') {
        text-align: center;
    }
}

.noresult-explore-list {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -7px;

    @include media('>=tablet') {
        justify-content: center;
        margin: 0;
    }
}

.noresult-explore-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 15px 7px 0;

    @include media('>=tablet') {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.noresult-explore-img {
    margin: 0 0 6px;
}

.noresult-explore-link {
    display: block;
    font: bold 13px / 16px $Arial;
    text-decoration: none;
}

.noresult-explore-link-label {
    display: block;
}

.noresult-predictive-wrapper {
    padding: 0 9px;

    @include media('>=tablet') {
        padding: 0 3.8%;
    }

    @include media('>=desktop') {
        padding: 0 7%;
    }

    .product-carousel-component .newin-title-wrapper {
        margin: 0 9px;
    }
}
