.fullwidth-richtext-component {
    text-align: center;
    margin: 35px 40px;
    color: $blue;

    @include media('>=desktop') {
        margin: 35px 40px;
    }

    @include media('>=desktopLarge') {
        margin: 35px 40px;
    }
}

.fullwidth-richtext-title {
    font-size: vw(38px);
    text-align: center;
    margin: 0 0 20px;

    @include media('>=desktop') {
        font-size: 28px;
    }
}

.fullwidth-richtext-text {
    font-size: 16px;
    line-height: 18px;
}
