// PD blogarticleheader component style deviations with _blog_landing.scss
.blogarticleheader-component {
    // Blog Article page
    .article-page-header {
        .blog-big-article-wrapper {
            margin: 0 0 30px;

            @include media('>=desktop') {
                margin: 0 0 50px;
            }
        }

        .article-content-wrapper {
            @include media('>=desktop') {
                position: relative;
                width: auto;
                margin: -100px 20px 0;
            }
        }

        .big-acticle-content {
            padding: 25px 30px 0;
            margin: 0;

            @include media('>=tablet') {
                padding: 40px 95px 0;
            }

            @include media('>=desktop') {
                padding: 35px 50px 0;
                bottom: 0;
            }

            @include media('>=desktopLarge') {
                max-width: 1120px;
                margin: 0 auto;
            }
        }

        .blog-article-description {
            font-size: 14px;
            line-height: 18px;
            text-align: center;

            @include media('>=tablet') {
                font-size: 18px;
                line-height: 24px;
            }

            @include media('>=desktop') {
                max-width: 560px;
                margin: 0 auto;
            }
        }
    }

    .pd-wave-separator {
        display: block;
        margin: 10px 0 0;
    }
}

.blogarticleheader-highlight-container {
    width: 100%;
    padding: 0 3.8%;

    @include media('>=desktop') {
        padding: 0 7%;
    }
}
