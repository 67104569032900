// =================================
//    Styles for product tile
// =================================

.tiles-container {
    align-items: stretch;
}

.product-tile-wrapper {
    margin: 0 0 20px;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;

    .slick-track & {
        position: static;
    }

    @include media('>=tablet') {
        margin-bottom: 55px;
    }

    @include media('>=desktop') {
        margin: 0;
    }
}

.grid-tile {
    margin-bottom: 10px;
    // fix min-height: 1px; fixed IE 10 11 bug with grid tile
    // height being larger than it's child image on family page grid tiles
    min-height: 1px;
    position: relative;
    padding-bottom: 35px;
    flex-grow: 1;
    flex-shrink: 1;

    @include media('>=desktop') {
        margin-bottom: 15px;
    }

    .slick-track & {
        position: static;
    }
}

.product-tile {
    text-align: center;

    .slick-track & {
        position: static;
    }
}

.waterreactive-icon-wrapper {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 30px;
    height: 30px;
    z-index: 3;

    .st1 {
        fill: $grey-bg;
    }

    .st0 {
        fill: $blue;
    }
}

.product-tile-link {
    display: block;
    text-decoration: none;
    position: relative;

    &:hover {
        color: $blue;
    }

    &:focus {
        outline: none;

        // move outline on product name
        .product-name {
            outline: auto;
        }
    }
}

.main-image {
    background-color: $grey-bg;
}

.hover-image {
    display: none;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.product-image {
    position: relative;

    .thumb-link {
        display: block;
        position: relative;
    }

    .thumb-link.ratio-container::after {
        content: '';
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: 120%; /* productTileHelper.getImagesData: height / width = 120% */
    }

    .thumb-link.ratio-container {
        .main-image {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            min-height: 100%;
        }
    }

    @include media('>=desktop') {
        &:hover {
            .product-fake-btn {
                display: block;
            }
        }

        .thumb-link:hover {
            .hover-image {
                display: block;
            }

            .hover-image.lazyloaded ~ .main-image {
                visibility: hidden;
            }
        }

        // keyboard hover case
        &.keyboard-hover {
            .product-fake-btn {
                display: block;
            }

            .hover-image {
                display: block;
            }

            .hover-image.lazyloaded ~ .main-image {
                visibility: hidden;
            }
        }
    }
}

.swatch-list {
    flex-flow: row wrap;
    justify-content: flex-start;
    overflow: hidden;
    max-width: calc(100% - 24px);
    height: 24px;

    @include media('>=tablet') {
        height: 33px;
    }

    @include media('>=desktop') {
        height: 26px;
    }

    &.product-variation-list {
        height: auto;
    }
}

.more-swatches {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: 24px;
    line-height: 24px;
    margin: 2px;

    @include media('>=tablet') {
        height: 30px;
        line-height: 32px;
    }

    @include media('>=desktop') {
        height: 22px;
        line-height: 24px;
    }
}

.swatch-list-item {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: auto;
}

.swatch {
    display: block;
    vertical-align: middle;
    border: 1px solid $light-grey;
    padding: 3px;
    margin: 0 0 4px;
    width: 24px;
    height: 24px;

    @include media('>=tablet') {
        width: 33px;
        height: 33px;
    }

    @include media('>=desktop') {
        width: 26px;
        height: 26px;
        max-width: 26px;
        max-height: 26px;
        padding: 2px;
    }

    .swatch-list-item & {
        margin: 0 2px 4px;
    }
}

.swatch-image {
    width: 100%;
    height: 100%;
}

.product-fake-btn {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background: $blue-hover;
    border: 2px solid $blue-hover;
    font: 11px $MontserratBold;
    color: $white;
    padding: 10px 15px;
    text-transform: uppercase;
    text-decoration: none;
    box-shadow: 0 0 0 2px $white inset;
    min-width: 150px;
    z-index: 3;

    &:hover {
        color: $white;
    }
}

.product-info {
    margin: 8px 3px 0 10px;

    @include media('>=tablet') {
        margin: 15px 0 0;
    }
}

.product-name {
    .family-subset & {
        display: none;
    }

    .family-search-result-content & {
        margin-top: 20px;

        @include media('>=desktop') {
            margin-top: 10px;
        }
    }

    &.product-set-name {
        text-align: center;
    }
}

.product-name,
.product-color,
.product-pricing,
.wishlist-product-size,
.product-promo {
    text-align: left;
}

.product-name {
    .subset.product-tile & {
        text-align: center;
    }
}

.product-color {
    font: 12px / 16px $Arial;
    text-decoration: underline;
}

.product-name,
.name-link {
    font: 12px / 16px $Arial;
    font-weight: bold;
    text-decoration: none;
}

.product-subtitle {
    font: vw(18px) $MontserratBold;
    text-transform: capitalize;

    @include media('>=tablet') {
        font-size: 14px;
    }

    @include media('>=desktop') {
        font-size: 10px;
    }

    .family-subset & {
        display: none;
    }
}

.promotional-message,
.product-pricing,
.product-sales-price,
.product-standard-price {
    font: 10px / 16px $Arial;

    .family-subset & {
        display: none;
    }
}

.promotional-message,
.product-standard-price + .product-sales-price {
    color: $blue;
}

.product-standard-price {
    display: inline-block;
}

.product-sales-price {
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    padding-right: 5px;
}

.product-swatches {
    margin: vw(20px) 0 0;
    position: relative;

    @include media('>=desktop') {
        margin-top: 15px;
    }
}

.show-more-colors {
    display: inline-block;
    width: 20px;
    cursor: pointer;
    font: 16px $Arial;
    font-weight: bold;
    text-decoration: none;
}

/*
.family-badge-wrapper {
    height: 27px;
    position: relative;
}
*/

.family-badge {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 3; // product-tile: 2 is for the product hover image, pdp: hover the zoom image
}

// Pre-order on product tile
.product-tile-preorder {
    font: bold 12px/12px $Arial;
    color: $royal-blue;
    text-align: left;
    margin: 2px 0 0;
}

.wishlist-link-wrapper,
.wishlist-remove-wrapper {
    text-align: right;
    height: 0;
    position: relative;
    top: 4px;
    right: 4px;
    z-index: 3; // product-tile: 2 is for the product hover image
}

.wishlist-link,
.wishlist-remove {
    display: inline-block;
    width: 30px;
    height: 30px;
}
