.manifesto {
    padding: 85px 0 65px;
}

.manifesto-title {
    margin: 0 20px 20px;

    @include media('>=tablet') {
        margin: 0 32px 32px;
    }
}

.manifesto-description {
    font-family: $Arial;
    color: $blue;
    line-height: 24px;
    font-size: 16px;
    margin: 38px 0 24px;

    @include media('>=desktop') {
        margin: 0 0 24px;
    }
}

.manifesto-carousel-component {
    padding: 0 0 0 14px;

    @include media('>=tablet') {
        padding: 0 0 0 32px;
        display: flex;
        justify-content: flex-start;
    }

    .manifesto-carousel-component-tile {
        padding: 0 6px;

        @include media('>=tablet') {
            padding: 0 7px 0 0;
        }

        @include media('>=desktop') {
            padding: 0 12px 0 0;
        }
    }
}

.manifesto-link {
    margin: 0 0 20px;

    @include media('>=tablet') {
        width: 250px;
        margin: 0 0 25px;
    }

    &:hover {
        outline: auto;
        outline-color: $blue-hover;
    }
}

.manifesto-content {
    @include media('>=desktop') {
        display: flex;
    }
}

.manifesto-carousel {
    @include media('>=desktop') {
        width: 55%;
        flex-shrink: 0;
        flex-grow: 0;
    }

    .slick-dots li {
        border-color: $white;
    }

    .slick-dots li.slick-active {
        background-color: $white;
    }
}

.manifesto-text {
    padding: 0 20px;

    @include media('>=tablet') {
        padding: 0 32px;
    }

    @include media('>=desktop') {
        margin: 0;
        padding: 15% 100px 0;
    }

    @include media('>=desktopLarge') {
        padding: 295px 120px 0;
    }
}
