.main-story-block {
    text-align: center;

    .main-story-block-link {
        display: block;
        margin: 30px 15px;
        width: calc(100% - 30px);
    
        @include media('>=tablet') {
            margin: 30px auto;
            width: 300px;
        }
    }
}
