.richtext-component {
    font-size: 12px;
    line-height: 14px;
    color: $blue;
    margin: 20px 15px 45px;

    @include media('>=tablet') {
        margin: 20px auto 45px;
        max-width: 525px;
    }

    @include media('>=desktop') {
        margin: 15px auto 45px;
    }
}
