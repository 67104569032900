.outlet-login {
    background-repeat: repeat;
    background-position: center center;
    flex: 1 1 100%;
    width: 100%;

    @include media('retina2x') {
        background-size: 50% auto;
    }

    @include media('<desktop') {
        background: none !important;
    }

    .header-banner {
        display: none;
    }

    .header-desktop-top-right,
    .ship-to-link-wrapper,
    .header-search,
    .main-footer,
    .primary-logo-mobile,
    .minicart-wrapper,
    .header-top,
    .header-desktop-top-item {
        display: none !important;
    }

    // display locale
    .header-desktop-top {
        display: block !important;

        .header-dropdown {
            display: flex !important;
        }

        .header-desktop-top-list {
            display: inline-block;
        }
    }

    .mobile-menu-item-lvl-1 {
        display: none;

        &.locale-menu {
            display: block;
        }
    }

    .header-top {
        @include media('>=desktop') {
            display: none;
        }
    }

    .header-right {
        @include media('<desktop') {
            width: 100%;
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    .main-navigation {
        background: transparent;
    }

    .header-desktop-top-list {
        background: $white;
    }
}

.outlet-wrapper {
    text-align: center;
    padding: 0 0 10px;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    flex-flow: row nowrap;

    @include media('>=tablet') {
        padding: 70px 0;
    }

    @include media('>=desktop') {
        padding: 70px 0 86px; // bottom + 16px for background image with footer wave
    }
}

.bg-wrapper {
    min-height: 100%;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
}

.outlet-container {
    flex: 1 1 100%;

    @include media('>=tablet') {
        flex: 0 1 80%;
        padding: 0 2%;
        position: relative;
    }

    @include media('>=desktop') {
        flex: 0 1 50%;
    }

    .primary-logo-desktop {
        position: static;
        transform: none;
        text-align: center;
        padding: 0 0 21px;
    }

    .primary-logo-mobile {
        display: none;
    }
}

.outlet-outside-container {
    background: $white;
    flex: 1 1 100%;
    border: 2px solid $blue;

    @include media('>=desktop') {
        border: 5px solid transparent;
        outline: 10px solid $white;
        background-clip: padding-box;
    }
}

.outlet-inside-container {
    margin: 10px 20px 30px;
    text-align: left;

    @include media('>=tablet') {
        margin: 49px 50px 70px;
    }

    .gl_form-group {
        margin: vw(30px) 0;

        @include media('>=desktop') {
            margin: 15px 0;
        }
    }
}

.outlet-fieldset-wrapper {
    margin: vw(30px) 0 0;

    @include media('>=desktop') {
        margin: 0;
    }

    .gl_form-group {
        margin: 0;

        @include media('>=desktop') {
            margin: 0;
        }
    }

    .apply-code-btn-wrapper {
        padding-bottom: 30px;
    }

    .apply-code-btn {
        height: 100%;
    }
}

.outlet-code-input {
    .backend-error-message,
    .gl_error-message {
        white-space: nowrap;
    }
}

.outlet-consent {
    .consent-disclamer {
        font: vw(24px) $Arial;

        @include media('>=tablet') {
            font-size: 12px;
        }

        .consent-disclamer-br {
            display: none;
        }
    }

    .gl_input-success-container {
        display: none;
    }
}

.outlet-information {
    font-family: $Arial;
    font-size: vw(28px);
    color: $blue;
    margin-bottom: 0;

    @include media('>=tablet') {
        font-size: vw(20px);
    }

    @include media('>=desktop') {
        font-size: 12px;
    }

    .gl_button {
        margin-top: 20px;
    }
}

.outlet-information-open {
    text-align: center;
}

.outlet-information-closed {
    text-align: center;
    font-weight: bold;
}

.outlet-code {
    font-size: vw(38px);

    @include media('>=tablet') {
        font-size: vw(26px);
    }

    @include media('>=desktop') {
        font-size: 16px;
    }
}

.outlet-title {
    font-size: vw(48px);
    margin: 0;
    text-align: center;

    @include media('>=desktop') {
        font-size: 32px;
        margin: 59px 0 49px;
    }
}

.outlet-form {
    @include media('>=desktop') {
        margin-top: 20px;
    }
}

.bottom-link {
    font-family: $Arial;
    font-size: vw(28px);
    text-align: center;
    margin-top: 40px;

    @include media('>=tablet') {
        font-size: vw(20px);
    }

    @include media('>=desktop') {
        font-size: 12px;
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
    }
}

.outlet-separator {
    text-align: center;
    margin: 0 0 20px;
}

.get-code-link-wrapper {
    text-align: center;
}

.get-code-link {
    width: auto;
}

.outlet-logo-desktop {
    text-align: center;
    padding-top: 20px;
}

.outlet-logo-desktop-link {
    display: inline-block;
}

.outlet-logo-image {
    width: 90px;

    @include media('>=desktop') {
        width: auto;
    }
}

.cart-actions .outlet-total-label {
    display: none;
    font: 12px / 14px $Arial;
    padding: 5px 0 0;
    color: $red;

    @include media('>=desktop') {
        display: block;
    }
}

.gl_form-group.required #dwfrm_outlet_register_optinconsent-error.gl_error-message {
    bottom: -18px;
}
