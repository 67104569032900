.text-images-button-component {
    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
    }
}

.text-image-button-col {
    @include media('>=desktop') {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.text-image-button-content {
    color: $blue;
    text-align: center;
    background-color: $white;
    margin: 35px 40px;

    @include media('>=desktop') {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        text-align: left;
    }

    @include media('>=desktopLarge') {
        margin: 50px 100px;
    }
}

.text-images-button-title {
    font-size: vw(38px);
    margin: 0 0 20px;

    @include media('>=desktop') {
        font-size: 20px;
        margin: 0 0 30px;
    }
}

.text-images-button-text {
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 30px;

    @include media('>=desktop') {
        margin: 0 0 40px;
    }

    @include media('>=desktopLarge') {
        margin: 0 0 60px;
    }
}

.text-images-button-text-link.gl_btn {
    display: block;
    max-width: 250px;
    margin: 0 auto;

    @include media('>=desktop') {
        margin: 0;
    }
}

.text-image-button-carousel-wrapper {
    .images-carousel-slide-link {
        display: block;
        margin: 0;
    }

    .carousel-with-arrows {
        padding: 0 30px;
    }

    .slick-arrow {
        position: absolute;
        top: calc(50% - 10px);
        width: 30px;
        height: 30px;
        z-index: 1;
    }

    .slick-prev {
        left: 5px;
    }

    .slick-next {
        right: 5px;
    }
}
