
.h-designer-container {
    position: relative;
    z-index: 2;
    padding: 50px;
    color: $blue;
    overflow: hidden;
    background: $beige-light;

    // css hack to have proper anchor view on scroll if sticky panel is
    padding-top: calc(50px + 48px + 40px);
    margin-top: -88px;

    @include media('>=tablet') {
        display: flex;
        flex-flow: row nowrap;
        padding: 40px 30px;

        // css hack to have proper anchor view on scroll if sticky panel is
        padding-top: calc(40px + 48px + 40px);
        margin-top: -88px;
    }

    @include media('>=desktop') {
        padding: 60px 0 60px;

        // css hack to have proper anchor view on scroll if sticky panel is
        padding-top: calc(60px + 80px + 40px);
        margin-top: -120px;
    }

    @include media('>=desktopLarge') {
        padding: 70px 0 75px;

        // css hack to have proper anchor view on scroll if sticky panel is
        padding-top: calc(70px + 80px + 40px);
        margin-top: -120px;
    }
}

.h-designer-image-wrapper {
    position: relative;
}

.h-designer-decor {
    position: absolute;
    width: 240px;
    z-index: 1;
    right: -80px;
    top: -70px;

    @include media('>=tablet') {
        top: -20px;
    }

    @include media('>=desktopLarge') {
        width: 300px;
        right: -160px;
    }
}

.h-designer-face {
    @include media('>=tablet') {
        max-width: 50%;
        flex-shrink: 0;
    }

    @include media('>=desktop') {
        padding: 0 0 0 60px;
    }

    @include media('>=desktopLarge') {
        padding: 0 0 0 80px;
    }
}

.h-designer-col-content {
    position: relative;
    padding: 47px 0 0;

    @include media('>=tablet') {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        padding: 0 0 0 40px;
        flex-grow: 1;
    }

    @include media('>=desktop') {
        padding: 0 40px;
    }

    @include media('>=desktopLarge') {
        padding: 0 60px;
    }
}

.h-designer-subtitle {
    font-family: $MontserratBold;
    font-size: 12px;
    line-height: 15px;
    padding: 0 0 8px;
    letter-spacing: 0.025em;

    @include media('>=tablet') {
        text-align: right;
        padding: 0 0 30px;
    }
}

.h-designer-title {
    font-size: 30px;
    line-height: 37px;
    margin: 0 0 16px;

    @include media('>=tablet') {
        margin: 0 0 27px;
    }

    @include media('>=desktop') {
        font-size: 45px;
        line-height: 50px;
    }

    span {
        color: $rounge;
    }
}

.h-designer-desc {
    font-size: 16px;
    line-height: 22px;
    padding: 0 0 30px;

    @include media('>=tablet') {
        max-width: 550px;
        margin: 0 auto;
    }

    @include media('>=desktopLarge') {
        font-size: 18px;
        line-height: 25px;
    }
}

.h-designer-quote {
    font-family: $MontserratBold;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 27px;
    color: $rounge;
    text-align: center;

    @include media('>=desktop') {
        font-size: 27px;
        line-height: 35px;
    }
}
