// Ultra Fast Delivery Form (on UFD Landing Page & in UFD dialog on PDP)
.ufd-form {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;

    .ufd-code-field {
        position: static;
        flex-grow: 1;

        .gl_input-success-container {
            width: 40px;
        }
    }

    .ufd-code-input {
        line-height: 40px;
        height: 40px;
        min-height: 40px;
        padding: 0 40px 0 8px;
    }

    .ufd-submit {
        width: auto;
        min-width: 125px;
        margin: 0 0 0 10px;
        flex-shrink: 0;
    }

    &.gl_loading {
        pointer-events: none;
        opacity: 0.5;
    }

    .gl_ajax-wrapper {
        position: absolute;
        left: 0;
        right: 0;
    }

    // deviation on UFD form to prevent overlap
    .gl_form-group {
        &.required {
            .gl_error-message,
            .backend-error-message,
            .form-caption {
                top: 45px;
                bottom: auto;
                line-height: 12px;
                max-height: 24px;
                overflow: hidden;
            }
        }
    }
}

// Ultra Fast Delivery Landing Page
.ufd-landingpage-content {
    color: $blue;
    margin: vw(30px) vw(36px);

    @include media('>=tablet') {
        margin: 35px auto 250px;
        max-width: 345px;
    }
}

.ufd-landingpage-title {
    font: 18px / 22px $MontserratBold;
    text-align: center;
    margin: 0 0 10px;
}

.ufd-landingpage-desc {
    font: 16px / 18px $Arial;
    text-align: center;
    margin: 0 0 30px;
}

.ufd-landingpage-mobile-sticky {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $light-grey;
    z-index: 1000;

    @include media('>=tablet') {
        position: static;
        background: none;
    }
}

.ufd-landingpage-mobile-sticky-not-possible {
    padding: 20px 18px 18px;

    @include media('>=tablet') {
        padding: 0;
    }
}

.ufd-landingpage-mobile-sticky-possible {
    padding: 0 18px 18px;

    @include media('>=tablet') {
        padding: 0;
    }
}

.ufd-landingpage-eligible-desc {
    font: 16px / 18px $Arial;
    margin: 0 0 20px;
}

.ufd-landingpage-options {
    font: 16px / 18px $Arial;
}

.ufd-landingpage-options-desc {
    margin: 0 0 5px;
}

.ufd-landingpage-option {
    margin: 0 0 10px;

    .ufd-landingpage-option-label {
        font: 16px / 18px $Arial;
    }
}

.ufd-landingpage-pref-desc {
    font: 11px / 12px $Arial;
    text-align: center;
    padding: 12px 0;

    @include media('>=tablet') {
        text-align: left;
        padding: 10px 0;
    }
}

.ufd-landingpage-footer-mobile-sticky-place {
    .main-footer {
        @include media('<tablet') {
            padding: 0 0 150px;
        }
    }
}

.ufd-landingpage-home-link {
    display: block;
}

.ufd-form-backend-error {
    color: $red;
    font: 11px / 12px $Arial;
    margin: 10px 0 20px;
}

.ufd-landingpage-disclaimer {
    margin: 10px 0 0;
    font: 16px / 18px $Arial;
    text-align: left;

    .html-slot-container {
        margin: 0 0 20px;
    }
}

.ufd-landingpage-bike-container {
    text-align: center;
    margin: 125px auto;

    @include media('>=tablet') {
        margin: 90px auto 220px;
    }

    .bikesvg {
        width: 70px;
        height: 30px;
    }

    .st0 {
        fill: $blue;
    }
}

.ufd-landingpage-possible-submit.gl_loading {
    pointer-events: none;
    opacity: 0.5;
}

// Ultra Fast Delivery Common Elements
.gl_button.ufd-button {
    font-size: 11px;
    padding: 0 10px;
    line-height: 40px;
    height: 40px;
}

.gl_button.ufd-submit,
.gl_button.ufd-remove-btn {
    font-size: 10px;
}

.ufd-button + .ufd-button {
    margin-top: 10px;
}

// Ultra Fast Delivery Slider (bottom sticky panel)
.ufd-slider {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: $light-grey;
    padding: 15px 20px 20px;
    text-align: center;
    color: $blue;
    z-index: 1000;

    .ufd-slider-link {
        display: inline-block;
        min-width: 170px;
        width: auto;
        max-width: 100%;
        margin: 0 auto;
    }
}

.ufd-slider-bike-container {
    margin: 0 0 5px;
    height: 18px;

    .bikesvg {
        width: 40px;
        height: 18px;
    }

    .st0 {
        fill: $blue;
    }
}

.ufd-slider-title {
    font: 11px / 14px $MontserratBold;
    margin: 0 0 10px;
}

.ufd-slider-disclaimer {
    font: 11px / 12px $Arial;
    margin: 0 0 17px;
}

.main-footer.ufd-slider-footer-place {
    padding: 0 0 250px;
}

// PDP dialog with UFD
.ufd-form-backend-result {
    font: 11px / 12px $Arial;
    margin: 10px 0;

    // UFD success result
    .ufd-success-result {
        margin: 0 0 10px;
        text-align: center;
    }

    // UFD error result
    .ufd-error-result {
        color: $red;
    }
}

// UFD no results cases (category products, family products, pattern products)
.ufd-no-results {
    color: $blue;
    text-align: center;
    margin: vw(30px) vw(36px);

    @include media('>=tablet') {
        margin: 35px auto 250px;
        max-width: 345px;
    }
}

.ufd-no-results-message {
    font: bold 16px / 18px $Arial;
    margin: 0 0 30px;
}

// Ultra Fast Delivery Store Page
.ufd-storepage-title {
    font: 18px / 22px $MontserratBold;
    text-align: center;
    margin: 0 0 10px;
}

.ufd-storepage-text {
    font: 16px / 18px $Arial;
    margin: 0 0 30px;
}

.ufd-storepage-form {
    margin: 0 0 40px;

    @include media('>=tablet') {
        margin: 0 0 20px;
    }
}

.ufd-storepage-list {
    text-align: center;
}

.ufd-storepage-list-section {
    text-align: left;
    font: 14px / 16px $Arial;
    margin: 0 0 10px;
}

.ufd-list-button {
    font: 11px / 12px $Arial;
    margin: 0 0 15px;
}

.ufd-storepage-list-title {
    margin: 0 0 30px;
}

.ufd-storepage-list-content {
    margin: 0 0 20px;
}

.ufd-storepage-landing-link-wrapper {
    text-align: center;
}

.ufd-storepage-image-wrapper {
    max-width: 200px;
    margin: 20px auto 100px;

    @include media('>=tablet') {
        margin: 20px auto 0;
    }
}
