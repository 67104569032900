.asset-4-images {
    display: flex;
    flex-flow: row wrap;
}

.asset-4-images-tile {
    display: block;
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;

    @include media('>=tablet') {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.asset-4-images-title-wrapper {
    @include absolute-horizont-center;
    width: 100%;
    padding: 0 10px;
    text-align: center;

    &.link {
        bottom: 10px;
    }

    &.button {
        bottom: 0;
    }
}

.asset-4-images-title {
    font-family: $MontserratBold;
    text-transform: uppercase;
    color: $white;

    .link & {
        font-size: 16px;
        line-height: 20px;
        text-decoration: underline;
        text-shadow: 2px 2px 4px $box-shadow;
    }

    .button & {
        display: inline-block;
        width: 70%;
        padding: 10px;
        font-size: 10px;
        line-height: 14px;
        background-color: $blue;
    }
}
