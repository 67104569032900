.background-color_with_images-container {
    padding: 35px 55px;

    @include media('>=tablet') {
        padding: 40px 35px;
    }

    .image-container {
        position: relative;
        display: grid;
        justify-content: center;

        &.desktop-row-2-tiles {
            @include media('>=desktop') {
                grid-template-columns: repeat(2, auto);
                justify-content: center;
            }
        }
    }

    .color-with-image {
        width: initial;
        max-width: 100%;
    }
}

.background-color_with_images-title {
    display: none;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: normal;
    text-align: center;

    @include media('>=desktop') {
        font-size: 36px;
        line-height: 44px;
    }

    &.before {
        margin: 0 0 20px;

        @include media('>=desktop') {
            margin: 0 0 45px;
        }
    }

    &.after {
        margin: 20px 0 0;

        @include media('>=desktop') {
            margin: 45px 0 0;
        }
    }

    &.mobile-before,
    &.mobile-between,
    &.mobile-after {
        @include media('<tablet') {
            display: block;
        }
    }

    &.tablet-before,
    &.tablet-between,
    &.tablet-after {
        @include media('>=tablet', '<desktop') {
            display: block;
        }
    }

    &.desktop-before,
    &.desktop-on-top,
    &.desktop-after {
        @include media('>=desktop') {
            display: block;
        }
    }

    &.mobile-between {
        @include media('<tablet') {
            text-align: left;
            padding: 0 15px;
        }
    }

    &.tablet-between {
        @include media('>=tablet', '<desktop') {
            text-align: left;
            padding: 0 20px;
        }
    }

    &.desktop-on-top {
        @include media('>=desktop') {
            @include absolute-center;
            max-width: 35%;
            z-index: 1;
        }
    }
}

.background-color_with_images-link-wrapper {
    margin: 30px 0 0;
    text-align: center;
}

.background-color_with_images-link {
    display: inline-block;
    font: 10px / 14px $MontserratBold;
    border: 0;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    padding: 15px;
    min-width: 200px;

    @include media('>=tablet') {
        min-width: 300px;
    }

    &.link-blue {
        color: $white;
        background-color: $blue;
    }

    &.link-white {
        color: $white;
        background-color: transparent;
        border: 1px solid $white;
    }
}
