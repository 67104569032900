$desktop-tiles-grid-max-width-desktop: 1720px;

.search-header-wrapper {
    margin: 0 0 20px;

    @include media('>=desktop') {
        margin: 0;
    }
}

.search-header {
    text-align: center;
    margin-top: vw(120px);

    @include media('>=tablet') {
        margin-top: 70px;
    }
}

.search-header-wave {
    margin-top: vw(40px);

    @include media('>=desktop') {
        margin-top: 20px;
    }
}

//Blog Articles
.primary-content {
    .pt_content-search-result &,
    .pt_gifting & {
        margin-bottom: vw(65px);

        @include media('>=desktop') {
            margin-bottom: 50px;
        }
    }
}

.search-result-pages,
.search-result-articles {
    margin-top: vw(110px);

    @include media('>=desktop') {
        margin-top: 30px;
    }
}

.search-result-pages.row {
    @include media('>=desktop') {
        justify-content: center;
    }
}

.search-result-articles-more {
    text-align: center;
    margin: 0 auto 40px;

    @include media('>=desktop') {
        margin: 20px auto 40px;
    }
}

.search-blog-article {
    margin-bottom: vw(70px);

    @include media('>=desktop') {
        margin-bottom: 20px;
    }
}

.search-article-content-title,
.search-article-readmore {
    display: inline-block;
    font: 12px $Arial;
    color: $blue;

    @include media('>=tablet', '<desktop') {
        font-size: vw(24px);
    }
}

.search-article-content-title {
    margin: 0;
}

// Blog Products
.search-result-view-more {
    margin: 25px auto 0;
}

.search-result-refinements {
    visibility: hidden;

    &.visible {
        visibility: visible;
    }

    @include media('>=desktop') {
        visibility: visible;
        margin-top: 20px;
        border-top: 1px solid $light-grey;
        box-shadow: inset 0 -9px 1px -8px $light-grey;
        flex-wrap: nowrap;
    }
}

.refinements-section {
    position: relative;
    margin: vw(50px) 0 0;

    @include media('>=desktop') {
        margin: 0;
    }
}

.progress-loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $transparent-white;
    z-index: 4;

    .gl_ajax-loader {
        display: flex;
        justify-content: center;
        position: relative;
    }

    .turtlesvg {
        width: 24px;

        .st0 {
            fill: $blue;
        }
    }

    .gl_ajax-wrapper {
        width: 100%;
        height: 100%;
        margin: 0;
        justify-content: center;
        align-content: center;
        align-items: center;

        .search-result-subset & {
            align-items: flex-start;
        }
    }
}

.sort-by-mobile-interface {
    display: none;

    &.visible {
        display: block;
    }
}

.search-refinement {
    cursor: pointer;
    position: relative;
    padding-right: 0;
    padding-left: 0;

    &.active {
        background-color: $light-grey;
    }

    @include media('>=desktop') {
        border-right: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;
        flex-shrink: 1;
    }
}

.search-refinement-status-arrow {
    position: absolute;
    top: 0;
    right: vw(36px);
    height: 100%;
    width: 20px;
    justify-content: flex-start;
    align-content: stretch;
    flex-flow: row nowrap;
    display: flex;
    align-items: center;

    &::before {
        content: '';
        width: 100%;
        height: 11px;
        display: block;
        background: url('../../images_active/menu-down-arrow.png') no-repeat;
        transform: scale(0.5);
        transform-origin: center;

        @include media('>=tablet') {
            transform: scale(1);
        }
    }

    .search-refinement.expanded &,
    .ui-state-active &,
    .active & {
        &::before {
            transform: rotate(180deg) scale(0.5);

            @include media('>=tablet') {
                transform: rotate(180deg) scale(1);
            }
        }
    }

    @include media('>=desktop') {
        position: absolute;
        width: 20px;
        height: 100%;
        right: 0;
        top: 0;

        &::before {
            content: '';
            position: absolute;
            top: 32px;
            left: 0;
            background: none;
            width: auto;
            height: 0;
            @include triangle-down();
        }

        .store-availability-wrapper & {
            display: none;
        }
    }
}

.search-refinement-toggle {
    padding: 0 0 0 vw(60px);
    font: vw(32px) $MontserratBold;
    line-height: vw(100px);
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid $light-grey;
    display: block;
    width: 100%;
    text-decoration: none;

    @include media('>=tablet') {
        font-size: 20px;
        line-height: 55px;
    }

    @include media('>=desktop') {
        padding-left: 10px;
        padding-right: 30px;
        font-size: 11px;
        line-height: 70px;
        border-bottom: 0;
    }
}

.search-refinement-dropdown {
    top: calc(100% - 1px);
    display: none;
    z-index: 10000;
    width: 100%;
    background-color: $white;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: vw(30px) vw(60px);
    border-bottom: 1px solid $light-grey;

    &.visible {
        display: flex;
        flex-direction: column;
    }

    @include media('>=tablet') {
        padding: vw(20px) vw(60px);
    }

    @include media('>=desktop') {
        padding: 0;
        position: absolute;
        max-width: $desktopLarge;
        align-items: center;

        &.visible {
            flex-direction: row;
            padding-left: 20px;
            display: flex;
            border-top: 1px solid $light-grey;
            border-bottom: 0;
        }
    }
}

.search-refinements-inline {
    padding: vw(60px) vw(50px);

    &.visible {
        flex-direction: row;
        justify-content: flex-start;
    }

    @include media('>=tablet') {
        padding: vw(40px) vw(50px);
    }

    @include media('>=desktop') {
        padding: 0;
    }
}

.search-refinement-item {
    cursor: pointer;
    width: 100%;
    margin: vw(25px) 0;
    align-items: center;

    &.unselectable {
        cursor: default;
    }

    @include media('>=desktop') {
        height: 70px;
        width: auto;
        margin: 0 30px 0 0;
    }
}

.search-refinement-color-item {
    position: relative;
}

.search-refinement-inline {
    width: auto;
    height: auto;
    margin: vw(10px);

    @include media('>=desktop') {
        margin: 0 5px 0 0;
    }
}

.search-refinement-gender-item {
    .family-gender-refinements & {
        margin-top: vw(50px);
    }

    @include media('>=desktop') {

        .family-gender-refinements & {
            margin: 0 40px 0 0;
            flex-basis: auto;
            max-width: none;
        }

        &:last-of-type {
            .family-gender-refinements & {
                margin-bottom: 0;
            }
        }
    }
}

.search-refinement-gender-link,
.search-refinement-link {
    height: 40px;
    font-family: $MontserratBold;
    font-size: vw(28px);
    line-height: 40px;
    text-decoration: none;
    display: block;
    position: relative;
    text-transform: uppercase;

    &::before {
        content: '';
        float: left;
        height: 40px;
        width: 40px;
        margin-right: vw(20px);
        border: 2px solid $blue;
        box-sizing: border-box;
    }

    .selected & {
        &::before {
            border-color: $blue-hover;
        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            height: 32px;
            width: 32px;
            background-color: $blue-hover;
            box-sizing: border-box;
            left: 4px;
            top: 4px;
        }
    }

    .unselectable & {
        cursor: default;
    }

    @include media('>=tablet') {
        height: 55px;
        font-size: 20px;
        line-height: 55px;

        &::before {
            width: 55px;
            height: 55px;
        }

        .selected & {
            &::after {
                height: 45px;
                width: 45px;
                left: 5px;
                top: 5px;
            }
        }
    }

    @include media('>=desktop') {
        height: 28px;
        line-height: 28px;
        font-size: 11px;

        &::before {
            width: 28px;
            height: 28px;
            border: 1px solid $blue;
            margin-right: 5px;
        }

        .selected & {
            &::after {
                height: 22px;
                width: 22px;
                left: 3px;
                top: 3px;
            }
        }
    }
}

.search-refinement-size-link {
    width: vw(70px);
    height: vw(70px);
    border: 1px solid $blue;
    font: vw(20px) $MontserratBold;
    line-height: vw(70px);
    display: block;
    text-align: center;
    color: $blue;
    text-decoration: none;

    .selected & {
        background-color: $blue;
        color: $white;
    }

    .unselectable & {
        cursor: default;
    }

    @include media('>=desktop') {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 11px;
    }
}

.search-refimement-color {
    width: 40px;
    height: 40px;
    border: 1px solid $light-grey;
    display: block;
    text-decoration: none;
    position: relative;

    .selected & {
        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 34px;
            height: 34px;
            top: 2px;
            left: 2px;
            border: 2px solid $white;
            box-sizing: border-box;
            z-index: 3; // for .color-multi
        }
    }

    .unselectable & {
        cursor: default;
    }

    @include media('>=tablet') {
        width: 70px;
        height: 70px;

        .selected & {
            &::before {
                width: 62px;
                height: 62px;
                top: 3px;
                left: 3px;
            }
        }
    }

    @include media('>=desktop') {
        width: 28px;
        height: 28px;

        .selected & {
            &::before {
                content: '';
                position: absolute;
                display: block;
                width: 22px;
                height: 22px;
                top: 2px;
                left: 2px;
                border-width: 1px;
                box-sizing: border-box;
            }
        }
    }
}

//Search Refinement Colors
.color-beige {
    background-color: $beige;
}

.color-black {
    background-color: $black;
}

.color-blue {
    background-color: $blue;
}

.color-brown {
    background-color: $brown;
}

.color-green {
    background-color: $green;
}

.color-grey {
    background-color: $grey;
}

.color-multi {
    .color-multi-topLeft,
    .color-multi-topRight,
    .color-multi-bottomRight,
    .color-multi-bottomLeft {
        position: absolute;
        width: 19px;
        height: 19px;
        z-index: 2;
    }

    .color-multi-topLeft {
        top: 0;
        left: 0;
        background-color: $m-blue;
    }

    .color-multi-topRight {
        top: 0;
        right: 0;
        background-color: $m-green;
    }

    .color-multi-bottomRight {
        bottom: 0;
        right: 0;
        background-color: $m-red;
    }

    .color-multi-bottomLeft {
        bottom: 0;
        left: 0;
        background-color: $m-yellow;
    }

    @include media('>=tablet') {
        .color-multi-topLeft,
        .color-multi-topRight,
        .color-multi-bottomRight,
        .color-multi-bottomLeft {
            width: 34px;
            height: 34px;
        }
    }

    @include media('>=desktop') {
        .color-multi-topLeft,
        .color-multi-topRight,
        .color-multi-bottomRight,
        .color-multi-bottomLeft {
            width: 13px;
            height: 13px;
        }
    }
}

.color-orange {
    background-color: $orange;
}

.color-pink {
    background-color: $pink;
}

.color-purple {
    background-color: $purple;
}

.color-red {
    background-color: $red;
}

.color-white {
    background-color: $white;
}

.color-yellow {
    background-color: $yellow-light;
}

.search-result-options {
    @include media('>=desktop') {
        flex-shrink: 1;
        padding: 0 10px;
        align-items: center; // vertical centering of content

        .search-results-clear-all + & {
            border-left: 1px solid $light-grey;
        }
    }

    @include media('>=desktopMedium') {
        padding: 0 20px;
    }
}

.sort-by {
    width: 100%;
}

.search-results-clear-all {
    margin: 0 auto;
    font-size: 12px;
    margin-top: vw(35px);

    @include media('>=tablet') {
        font-size: vw(24px);
    }

    @include media('>=desktop') {
        height: 100%;
        line-height: 70px;
        font-size: 12px;
        padding: 0 20px;
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: auto;
        margin: 0;
    }
}

.clear-refinement {
    @include media('>=desktop') {
        height: 100%;
        line-height: 70px;
        margin-left: 20px;
    }
}

.clear-refinement-mobile {
    position: absolute;
    top: 0;
    right: vw(110px);
}

.clear-refinement-mobile-link {
    font: vw(24px) $Arial;

    @include media('>=tablet') {
        font-size: 20px;
    }
}

.search-results-mobile-thumbs {
    width: 100%;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
}

.search-results-mobile-thumb {
    height: vw(100px);
    border-right: 1px solid $light-grey;
    padding: 0;
    position: relative;
    display: flex;

    &.ui-state-active {
        background-color: $light-grey;
    }

    &:last-child {
        border-right: 0;
    }

    @include media('>=tablet') {
        height: 55px;
    }
}

.search-results-mobile-thumb-link {
    padding: 0 vw(20px) 0 vw(35px);
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    font: vw(28px) $MontserratBold;
    line-height: 1;
    color: $blue;
    position: relative;
    cursor: pointer;
    align-self: center;

    @include media('>=tablet') {
        font-size: 20px;
        line-height: 55px;
    }
}

.sort-by-interface-item {
    padding: 0 0 0 vw(60px);
    font: vw(32px) $MontserratBold;
    line-height: vw(100px);
    height: vw(100px);
    color: $blue;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    border-bottom: 1px solid $light-grey;

    @include media('>=tablet') {
        font-size: 20px;
        line-height: 55px;
        height: 55px;
    }
}

//Hiding refinement bars
.refinement-header,
.refinement.Category {
    display: none;
}

.category-subset-row {
    margin-top: 0;
    align-items: stretch;
    position: relative;

    .category-name + & {
        margin-top: vw(20px);

        @include media('>=tablet') {
            margin-top: 20px;
        }

        @include media('>=desktop') {
            margin-top: 15px;
        }
    }

    .search-header-wave + & {
        margin-top: 20px;

        @include media('>=desktop') {
            margin-top: 30px;
        }
    }
}

// simple CLP & search pages subset
.search-result-subset {
    margin: 0 0 15px;

    @include media('>=desktop') {
        margin: 0 0 20px;
    }

    & + & {
        padding-top: 30px;
    }

    // resize loader on desktop
    .gl_ajax-loader {
        @include media('>=desktop') {
            width: 80px;
            height: 80px;
        }

        &::before,
        &::after {
            @include media('>=desktop') {
                width: 70px;
                height: 70px;
                border-width: 5px;
            }
        }

        &::after {
            @include media('>=desktop') {
                top: 0;
                left: 0;
            }
        }

        .turtlesvg {
            @include media('>=desktop') {
                width: 46px;
            }
        }
    }
}

.category-subset-header {
    text-align: center;
    margin-top: vw(120px);

    @include media('>=desktop') {
        margin-top: 70px;
    }
}

.category-subset-header-link {
    text-decoration: none;
}

.grid-subset-view-all-container {
    width: 100%;
    text-align: right;

    .gl_ajax-wrapper {
        display: inline-block;
        vertical-align: middle;
    }
}

.grid-subset-view-all {
    font: italic vw(28px) $TimesNewRoman;
    display: inline-block;
    vertical-align: middle;

    @include media('>=tablet') {
        font-size: vw(24px);
    }

    @include media('>=desktop') {
        font-size: 14px;
    }
}

.search-refinement-sort.gl_form-group {
    padding-bottom: 0;
}

.no-results {
    font: bold 16px / 18px $Arial;
    color: $blue;
    text-align: center;
    margin: vw(30px) vw(36px);

    @include media('>=tablet') {
        margin: 35px auto 250px;
        max-width: 345px;
    }
}

// wrapper is used on CLP, search, browse-by-patterns with mobile/desktop left filter panel
.wrapper {
    display: block;

    @include media('>=desktop') {
        display: flex;
    }
}

// Filter section
.refinements-filter-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid $light-grey;

    @include media('>=desktop') {
        padding: 0 15px 7px;
        height: auto;
    }
}

.category-subset-header-link {
    font: 13px / 15px $Arial;
    font-weight: bold;
    color: $blue;
    display: inline-block;
    text-transform: none;
    letter-spacing: normal;

    @include media('>=desktop') {
        line-height: 18px;
        padding: 0;
    }
}

.refinements-filter-title {
    flex-grow: 1;
    text-align: center;
    font: 13px / 15px $MontserratBold;
    color: $blue;
    text-transform: uppercase;

    .refinements-filter-h2 {
        font: inherit;
        letter-spacing: normal;
        text-transform: none;
    }
}

.refinements-filter-title.refinements-filter-top-title {
    padding: 0 15px;
    text-align: left;

    @include media('>=desktop') {
        font: 13px / 18px $Arial;
        font-weight: bold;
        padding: 0;
        text-transform: none;
    }
}

.refinements-filter-number {
    font: 13px / 15px $Arial;
    color: $blue;
    margin-left: 10px;
}

.refinements-filter-control {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.refinements-filter-close {
    width: 48px;
    cursor: pointer;

    .closesvg {
        width: 14px;
        height: 14px;

        .st0 {
            fill: $blue;
        }
    }
}

.refinements-filter-back {
    padding: 0 15px;

    .backsvg {
        width: 20px;
        height: 20px;

        .st1 {
            fill: $white;
            stroke: $blue;
        }

        .st0 {
            fill: $blue;
        }
    }
}

.refinements-filter-wrapper {
    display: none;

    &.active {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: $white;
        z-index: 99999;
    }

    @include media('>=desktop') {
        display: block;
        padding: 0 10px;
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;
        overflow: visible;
        z-index: initial;
        background: $white;

        // left sidebar from 2 tiles
        width: 300px;
    }

    // left sidebar from 3 tiles
    @include media('>=1270px') {
        width: 200px;
    }

    // small left sidebar from 4 tiles
    @include media('>=1620px') {
        width: 200px;
    }

    // standard left sidebar from 4 tiles
    @include media('>=1720px') {
        width: 300px;
    }
}

// top & width should be calculated based on sticky header
.refinements-filter-wrapper.sticky {
    @include media('>=desktop') {
        position: fixed;
        z-index: 3;
    }
}

.refinements-filter-wrapper.absolute {
    @include media('>=desktop') {
        position: absolute;
        bottom: 50px;
    }
}

.refinements-section-vertical {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    @include media('>=desktop') {
        width: initial;
        height: initial;
        overflow: visible;
    }
}

.refinement-filter-toggle {
    display: flex;
    align-items: center;
    min-height: 50px;
    cursor: pointer;
}

.refinement-filter-item {
    border-bottom: 1px solid $light-grey;
}

.refinement-filter-li:last-child {
    .refinement-filter-item {
        margin: 0 0 100px;
    }
}

.refinement-filter-item-title {
    padding: 5px 15px;
    flex-grow: 1;
    font: 13px / 16px $MontserratBold;
    color: $blue;
    text-transform: uppercase;
}

.refinement-filter-item-value {
    padding: 5px 0;
    font: 13px / 16px $Arial;
    color: $blue;
    text-align: right;
}

.refinement-filter-item-chevron {
    margin: 0 17px 0 10px;
    width: 14px;

    @include media('>=desktop') {
        margin: 0 15px 0 0;
        width: 8px;
    }

    &.open {
        transform: rotate(90deg);
    }

    .svgchevron {
        width: 10px;

        @include media('>=desktop') {
            width: 8px;
            height: 12px;
        }

        .st0 {
            fill: $blue;
        }
    }
}

.refinements-filter-mobile-footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: $light-grey;
    padding: 15px;
}

.refinements-filter-results {
    font: 12px / 14px $Arial;
    color: $blue;
    text-align: center;
    font-weight: bold;

    @include media('>=desktop') {
        font-weight: normal;
        margin: 75px 0 10px;
    }
}

.refinements-filter-btn {
    width: 100%;
    padding: 16px;
    background-color: $blue;
    font: 10px / 13px $MontserratBold;
    text-transform: uppercase;
    color: $white;
}

.refinements-filter-btn--space {
    margin: 10px 0 0;
}

.refinements-filter-clear-btn {
    width: 100%;
    padding: 16px;
    background-color: $white;
    font: 10px / 13px $MontserratBold;
    text-transform: uppercase;
    color: $blue;
    border: 1px solid $blue;
}

.refinements-filter-clear-link {
    text-decoration: underline;
    color: $blue;
    cursor: pointer;

    &:hover {
        color: $blue-hover;
    }
}

// Sort by on desktop filter section
.refinement-sort-selector {
    padding: 13px 15px 15px;
}

.refinement-sort-label {
    display: block;
    font: 12px / 14px $Arial;
    margin: 0 0 10px;
    color: $blue;
}

.refinement-sort-select {
    font: 12px / 42px $Arial;
    height: 45px;
}

.sort-select-value-0 {
    font-style: italic;
}

.refinement-values-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    background: $white;
    left: 100%;
    transition: left 0.3s;

    &.visible {
        overflow: visible;
        left: 0;
        z-index: 2;
    }

    @include media('>=desktop') {
        display: none;
        position: relative;
        width: initial;
        margin-bottom: 20px;
        left: 0;

        &.visible {
            display: block;
            overflow: auto;
        }
    }
}

.refinement-options-container {
    cursor: initial;
    background-color: $white;
    padding: 15px 15px 90px;

    @include media('>=desktop') {
        padding: 0 15px;
        max-height: 360px;
    }

    .refinement-attr-li {
        position: relative;
        flex: 100%;
        padding: 0 10px 0 0;
        margin: 0 0 20px;
        cursor: pointer;
        min-height: 20px;

        @include media('>=desktop') {
            margin: 0 0 10px;
        }
    }

    &.size-options-container .refinement-attr-li {
        flex: 25%;
        flex-grow: 0;

        @include media('>=desktop') {
            flex: 50%;
            max-width: 50%;
            overflow: hidden;
        }
    }

    &.color-options-container .refinement-attr-li {
        @include media('>=desktop') {
            max-width: 50%;
            overflow: hidden;
        }

        .gl_color-button.refinement-options-input {
            flex-shrink: 0;
        }
    }
}

.refinement-options-li {
    display: flex;
    margin: 0 0 20px;
}

.refinement-viewmore-li {
    padding: 0 0 3px;
}

.refinement-viewmore-li-btn {
    padding: 5px;
    color: $blue;
    text-decoration: underline;
    font: 12px / 14px $Arial;
    cursor: pointer;

    &:hover {
        color: $blue-hover;
    }
}

// Deviation in CATEGORY & THEME filters
.refinement-orliweb_code_ligne_produit-container,
.refinement-orliweb_stat05_theme-container {
    .refinement-attr-li {
        flex: 50%;

        @include media('>=desktop') {
            flex: 100%;
        }
    }
}

// !important to overwrite ui inputs
.refinement-options-label {
    flex-grow: 1;
    padding: 0 0 0 10px;
    font: 12px / 20px $Arial !important;
    color: $blue;
    display: flex !important;
    align-items: center;
    cursor: pointer;
}

.refinement-options-label-icon {
    background-color: $white;
    width: 30px;
    height: 30px;
    margin-left: 6px;

    @include media('>=desktop') {
        position: absolute;
        right: -24px;
        margin-left: 0;
    }
}

.sort-value-label {
    padding: 0 0 0 30px;
}

.refinement-attribute-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.mobile-filter-wrapper {
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    @include media('>=tablet') {
        height: 64px;
    }

    &.sticky {
        position: fixed;
        width: 100%;
        background-color: $white;
        z-index: 24999; // header sticky z-index - 1
    }
}

.mobile-filter-products {
    font: 12px / 16px $Arial;
    font-weight: bold;
    color: $blue;
    padding: 16px 15px;
    border-right: 1px solid $light-grey;
    flex-grow: 1;

    @include media('>=tablet') {
        padding: 23px 20px;
    }
}

.mobile-filter-cell {
    height: 100%;
}

.mobile-filter-button {
    position: relative;
    padding: 15px 13px;
    cursor: pointer;

    @include media('>=tablet') {
        padding: 21px 13px;
    }

    .filtersvg {
        width: 24px;

        .st0 {
            fill: $blue;
        }
    }
}

.mobile-filter-view {
    padding: 12px 15px;
    border-left: 1px solid $light-grey;

    @include media('>=tablet') {
        padding: 18px 15px;
    }
}

.subset {
    position: relative;
}

// View all panel with link on desktop
.category-view-all-panel {
    width: 100%;
    margin: 0 auto;
    text-align: right;

    @include media('>=desktop') {
        max-width: $desktop-tiles-grid-max-width-desktop;
        padding: 4px 20px 20px;
    }

    @include media('>=1270px') {
        padding: 4px 20px 30px;
    }
}

.category-view-all-link {
    font: 12px / 14px $Arial;
}

// CLP & search pages
.search-border-wrapper {
    @include media('>=desktop') {
        border-top: 1px solid $light-grey;
    }
}

.search-wrapper {
    padding: 12px 3px 30px;

    @include media('>=tablet') {
        padding: 17px 5px 30px;
    }

    @include media('>=desktop') {
        max-width: $desktop-tiles-grid-max-width-desktop;
        margin: 0 auto;
        padding: 25px 10px 50px;
        position: relative;
    }

    @include media('>=1270px') {
        padding: 30px 10px 50px;
    }

    @include media('>=1520px') {
        padding: 20px 10px 60px;
    }

    .search-result-items {
        position: relative;

        // space for 2 colums
        @include media('>=desktop') {
            width: calc(100% - 300px);
            margin-left: auto; // important for active sticky filter
        }

        // space for 3 colums with 200px filter:
        // breakpoint = 350px tiles min-width x 3 + 200px filter = 1270px
        @include media('>=1270px') {
            width: calc(100% - 200px);
        }

        // space for 4 colums with 200px filter:
        // breakpoint = 350px tiles min-width x 4 + 200px filter = 1620px
        @include media('>=1620px') {
            width: calc(100% - 200px);
        }

        // space for 4 colums with 300px filter:
        // breakpoint = 350px tiles min-width x 4 + 300px filter = 1720px
        @include media('>=1720px') {
            width: calc(100% - 300px);
        }
    }

    .grid-tile {
        padding: 0 3px;

        @include media('>=tablet') {
            padding: 0 10px;
        }

        // 3 tiles on >= 1270px
        @include media('>=1270px') {
            flex-basis: 33.33%;
            max-width: 33.33%;
        }

        // 4 tiles on >= 1620px
        @include media('>=1620px') {
            flex-basis: 25%;
            max-width: 25%;
        }
    }

    .search-result-view-more {
        display: block;
        width: calc(100% - 24px);
        margin: 8px auto 0;

        @include media('>=tablet') {
            min-width: 230px;
            width: auto;
            margin: 30px auto 0;
        }

        @include media('>=desktop') {
            min-width: 350px;
        }
    }
}

.category-name {
    font: 13px / 15px $Arial;
    font-weight: bold;
    padding: 0 12px;
    color: $blue;

    @include media('>=tablet') {
        padding: 0 10px;
    }

    @include media('>=desktop') {
        padding: 0 0 7px;
        margin: 0 10px 5px;
        border-bottom: 1px solid $light-grey;
    }
}

// Pattern page: browse-by-patterns
.pattern-result-content {
    .category-view-all-panel {
        @include media('>=desktop') {
            padding: 20px 10px;
            max-width: 1024px;
        }

        @include media('>=desktopLarge') {
            max-width: 1358px;
        }
    }
}

.pattern-background {
    width: auto;
    min-width: 100%;
}

.pattern-wrapper {
    margin: 0 0 50px;

    @include media('>=tablet') {
        justify-content: center;
    }

    @include media('>=desktop') {
        justify-content: flex-start;
        max-width: 1024px;
        margin: 30px auto 100px;
    }

    @include media('>=desktopLarge') {
        max-width: 1358px;
    }

    &.pattern-wrapper-no-results {
        margin: 12px 3px 50px;

        @include media('>=tablet') {
            margin: 17px 5px 50px;
        }

        @include media('>=desktop') {
            margin: 0 auto 100px;
        }
    }

    // fixed filter panel on pattern page
    .pattern-refinements-wrapper {
        @include media('>=desktop') {
            width: 344px;
            flex-grow: 0;
        }

        @include media('>=desktopLarge') {
            width: 338px;
        }
    }
}

.pattern-result-items {
    @include media('>=desktop') {
        width: 680px;
        margin-left: auto; // important for active sticky filter
    }

    @include media('>=desktopLarge') {
        width: 1020px;
    }
}

// Pattern page: PLP
.pattern-products-result-content {
    .search-result-subset {
        padding: 0 3px;

        @include media('>=tablet') {
            padding: 0 5px;
        }

        @include media('>=desktop') {
            padding: 0 10px;
        }

        @include media('>=desktopLarge') {
            padding: 0;
        }
    }

    .grid-tile {
        padding: 0 3px;

        @include media('>=tablet') {
            padding: 0 10px;
        }
    }
}

.faq-answer {
    display: none;
}

.refinements-filter-selected-values-wrapper {
    border-bottom: 1px solid $light-grey;
    padding: 12px 11px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.refinements-filter-selected-entry {
    flex: 0 0 auto;
    padding: 2px 4px;
}

.refinements-filter-selected-values-button {
    background-color: $blue;
    color: $white;
    padding: 4px;
    font: 12px / 16px $Arial;
    height: 22px;

    .closesvg {
        width: 8px;
        height: 8px;
        margin-left: 12px;
        position: relative;
        top: -1px;
    }

    .st0 {
        fill: $white;
    }
}

.pt_wishlist {
    .wishlist-link-wrapper {
        display: none;
    }
}

.removefromwishsvg {
    .st0 {
        fill: $blue;
    }

    .st1 {
        stroke: $blue;
        fill: $white;
    }
}

.wishlist-share-copy-wrapper {
    text-align: right;
    margin-bottom: 2px;
}

.wishlist-share-copy-button {
    width: auto;
}

.wishlist-share-container {
    background-color: $grey-bg;
    padding-top: 10px;
    padding-bottom: 10px;
    font: 12px / 16px $Arial;
    font-style: italic;
    text-align: left;
    word-wrap: break-word;
}

.wishlist-title {
    font: 18px / 20px $Arial;
    font-weight: bold;
    padding: 0 20px;
    text-transform: none;
    margin-bottom: 16px;
}

.wishlist-section {
    padding: 41px 0;
    text-align:center;

    & + & {
        border-top: 1px solid $grey-bg;
        padding-top: 40px;
    }

    &.wishlist-save {
        padding-top: 27px;
    }

    &:last-child {
        margin-bottom: 29px;
    }
}

.wishlist-share-fakelink-wrapper {
    margin: 16px 20px;
    justify-content: center;
}

.wishlist-share-fakelink {
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;

    &:hover {
        .share-text {
            color: $blue-hover;
        }

        .st0 {
            fill: $blue-hover;
        }

        .st1 {
            stroke: $blue-hover;
        }
    }
}

.share-text {
    font: 12px / 14px $Arial;
    text-decoration: underline;
    color: $blue;
}

.share-icon-wrapper {
    width: 30px;
    height: 30px;
    margin: 0 10px 0 0;

    .st0 {
        fill: $blue;
    }

    .st1 {
        stroke: $blue;
    }
}

.wishlist-list {
    margin-top: 20px;
}

.wishlist-save-disclaimer {
    font: 16px / 20px $Arial;
    text-transform: none;
    font-weight: bold;
    color: $blue;
    margin-bottom: 20px;
}

.wishlist-save-button {
    width: 250px;
    margin: 0 auto;
}

.wishlist-favorite-store-form {
    order: 2;
    flex: 0 0 auto;
}

.search-result-pages-wrapper {
    margin-bottom: 30px;
}

.search-result-page {
    padding: 4px 0;
}

.search-result-page-link {
    font: 12px / 14px $Arial;
    color: $blue;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    display: block;
}
