.turtleicon-component {
    margin: 30px 15px 20px;
    text-align: center;
    height: 50px;

    @include media('>=tablet') {
        margin: 30px 15px 15px;
    }
}

.turtleicon-svg {
    width: 50px;

    .st0 {
        fill: $blue;
    }
}
