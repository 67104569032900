.splited-main-hero-banner {
    display: grid;

    &.tablet-row-2-tiles {
        @include media('>=tablet') {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &.tablet-row-3-tiles {
        @include media('>=tablet') {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &.desktop-row-2-tiles {
        @include media('>=desktop') {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &.desktop-row-3-tiles {
        @include media('>=desktop') {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &.mobile-tiles-gap {
        @include media('<tablet') {
            gap: 10px;
        }
    }

    &.tablet-tiles-gap {
        @include media('>=tablet', '<desktop') {
            gap: 10px;
        }
    }

    &.desktop-tiles-gap {
        @include media('>=desktop') {
            gap: 20px;
        }
    }
}

.splited-main-hero-config-error {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    background: $white;
    padding: 20px;
    z-index: 9999;
    color: $red;
}

.splited-main-hero-tile-wrapper {
    display: block;
    position: relative;
}

.splited-main-hero-text-wrapper {
    position: absolute;
    padding: 20px 10px;

    &.right {
        text-align: right;
    }

    &.center {
        text-align: center;
    }
}

.splited-main-hero-title {
    font-family: $Arial;
    color: $white;
    letter-spacing: normal;

    &.shadow {
        text-shadow: 2px 2px 4px $blue;
    }
}

.splited-main-hero-subtitle {
    color: $white;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;

    &.button {
        display: inline-block;
        background-color: $blue;
        padding: 5px 10px;
    }

    &.link {
        text-decoration: underline;
    }
}
