.h-mosaic_4_images-container {
    position: relative;
    background: $white;

    @include media('>=desktop') {
        display: flex;
        flex-flow: row nowrap;
        padding: 60px 40px 100px;
    }
}

.h-mosaic_4_images-block-1 {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    padding: 35px 30px 40px;

    @include media('>=desktop') {
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        flex: 0 0 20%;
        flex-shrink: 0;
        max-width: 320px;
        padding: 0;
    }

    @include media('>=desktopLarge') {
        flex: 0 0 320px;
    }
}

.h-mosaic_4_images-img_cell-1 {
    position: relative;
    flex: 0 0 40%;
    max-width: 40%;
    padding: 0 20px 0 0;

    @include media('>=desktop') {
        padding: 0 0 20px;
        flex: 0 0 auto;
        width: 100%;
        max-width: 70%;
        flex-grow: 1;
    }

    .h-mosaic_4_images-img_wrap {
        @include media('>=desktop') {
            overflow: hidden;
            height: 100%;
        }
    }

    .h-mosaic_4_images-img-1 {
        @include media('>=desktop') {
            width: auto;
            height: 100%;
        }
    }
}

.h-mosaic_4_images-icon_wrap {
    position: absolute;
    top: -50px;
    left: -20px;

    @include media('>=desktop') {
        top: -40px;
        left: -90px;
    }

    @include media('>=desktopLarge') {
        left: -110px;
    }
}

.h-mosaic_4_images-img_cell-2 {
    flex: 0 0 60%;
    max-width: 60%;
    padding: 0 0 0 20px;

    @include media('>=desktop') {
        padding: 0;
        flex: 0 0 auto;
        width: 100%;
        max-width: none;
    }
}

.h-mosaic_4_images-block-2 {
    text-align: center;
    padding: 0 30px;
    color: $blue;

    @include media('>=desktop') {
        padding: 0 40px;
        flex-grow: 1;
    }
}

.h-mosaic_4_images-text {
    margin: 0 auto;
    max-width: 530px;

    @include media('>=desktop') {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}

.h-mosaic_4_images-subtitle {
    font-family: $MontserratBold;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.025em;
    margin: 0 0 18px;

    @include media('>=desktop') {
        margin: 0 0 40px;
    }

    @include media('>=desktopLarge') {
        margin: 0 0 70px;
    }
}

.h-mosaic_4_images-desc {
    font-size: 16px;
    line-height: 22px;

    @include media('>=desktopLarge') {
        font-size: 18px;
        line-height: 25px;
    }
}

.h-mosaic_4_images-title {
    font-size: 30px;
    line-height: 37px;
    margin: 0 0 16px;

    @include media('>=desktopLarge') {
        font-size: 45px;
        line-height: 50px;
    }

    span {
        color: $rounge;
    }
}

.h-mosaic_4_images-block-3 {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 40px 30px 35px;

    @include media('>=desktop') {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex: 0 0 20%;
        flex-shrink: 0;
        max-width: 320px;
        padding: 0;
    }

    @include media('>=desktopLarge') {
        flex: 0 0 320px;
    }
}

.h-mosaic_4_images-img_cell-3 {
    flex: 0 0 60%;
    max-width: 60%;
    padding: 0 15px 0 0;

    @include media('>=desktop') {
        padding: 0 0 20px;
        flex: 0 0 auto;
        width: 100%;
        max-width: none;
    }
}

.h-mosaic_4_images-img_cell-4 {
    flex: 0 0 40%;
    max-width: 40%;
    padding: 0 0 0 15px;

    @include media('>=desktop') {
        padding: 0;
        flex: 0 0 auto;
        width: 100%;
        max-width: 60%;
        flex-grow: 1;
    }

    .h-mosaic_4_images-img_wrap {
        @include media('>=desktop') {
            height: 100%;
        }
    }

    .h-mosaic_4_images-img-4 {
        @include media('>=desktop') {
            width: auto;
            height: 100%;
        }
    }
}
