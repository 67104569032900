.text-image-component {
    margin: 45px 35px;
    color: $blue;

    @include media('>=tablet') {
        display: flex;
        flex-wrap: nowrap;
        margin: 75px 20px;
    }

    @include media('>=desktop') {
        margin: 100px auto;
        padding: 0 20px;
        max-width: 780px;
    }
}

.text-image-col-link {
    display: block;
}

.text-image-col-text {
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    max-width: 230px;
    padding: 30px 0 0;

    @include media('>=tablet') {
        max-width: 300px;
    }

    &.position-left {
        @include media('>=tablet') {
            padding: 50px 70px 50px 0;
        }
    }

    &.position-right {
        @include media('>=tablet') {
            padding: 50px 0 50px 70px;
        }
    }

    p {
        margin: 0;
    }
}

.text-image-col-img {
    @include media('>=tablet') {
        flex-grow: 1;
    }
}
