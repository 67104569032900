// =================================
//    General style for retina icon container
// =================================
.gl_ic_retina-container {
    position: relative;

    // =================================
    //    container width and height should be taken from non retina images dimensions*/
    // =================================
    &.gl_ic_container-family-fatherson,
    &.gl_ic_container-family-motherdaughter,
    &.gl_ic_container-family-all {
        width: 50px;
        height: 50px;
    }

    &.gl_ic_container-express-delivery {
        width: 67px;
        height: 34px;
    }

    &.gl_ic_container-secured-payment {
        width: 61px;
        height: 56px;
    }

    &.gl_ic_container-gift-box {
        width: 41px;
        height: 62px;
    }

    &.gl_ic_container-footer-logo {
        width: 107px;
        height: 107px;
    }

    &.gl_ic_container-input-map {
        width: 13px;
        height: 18px;
    }

    &.gl_ic_container-input-map-big {
        width: 25px;
        height: 34px;
    }

    &.gl_ic_container-pay-pal {
        width: 87px;
        height: 21px;
    }

    &.gl_ic_container-gift {
        width: 37px;
        height: 37px;
    }

    &.gl_ic_container-complementary-gift-box {
        width: 95px;
        height: 105px;
    }

    &.gl_ic_container-turtle-logo {
        width: 41px;
        height: 41px;
    }

    &.gl_ic_container-wishlist-heart {
        width: 13px;
        height: 11px;
    }

    &.gl_ic_container-get_direction {
        width: 26px;
        height: 26px;
    }
}

// =================================
//    General style for retina icons
// =================================
.gl_ic_retina {
    position: absolute;
    left: 0;
    top: 0;
}

// =================================
//    Icons
// =================================
.gl_ic_family-fatherson {
    @include mobile-first-icon('family_fatherson');

    &:hover {
        @include mobile-first-icon('family_fatherson_hover');
    }
}

.gl_ic_family-motherdaughter {
    @include mobile-first-icon('family_motherdaughter');

    &:hover {
        @include mobile-first-icon('family_motherdaughter_hover');
    }
}

.gl_ic_family-all {
    @include mobile-first-icon('family_all');

    &:hover {
        @include mobile-first-icon('family_all_hover');
    }
}

.gl_ic_express-delivery {
    @include mobile-first-icon('express_delivery');
}

.gl_ic_secured-payment {
    @include mobile-first-icon('secured_payment');
}

.gl_ic_gift-box {
    @include mobile-first-icon('gift_box');
}

.gl_ic_nav {
    position: absolute;
    width: 5px;
    left: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &::before {
        display: block;
        color: $blue;
        line-height: 10px;
        font-size: 10px;
        transform: scaleY(1.6);
        position: relative;
    }
}

.gl_ic_back {
    .store-detail-back-mobile & {
        position: static;
    }

    &::before {
        content: '<';
    }
}

.gl_ic_next {
    &::before {
        content: '>';
    }
}

.gl_ic_pay-pal {
    @include mobile-first-icon('pay_pal');
}

.gl_ic_gift {
    @include mobile-first-icon('gift');
}

.gl_ic_get_direction {
    @include mobile-first-icon('get_direction');

    .gl_get-direction:hover & {
        @include mobile-first-icon('get_direction_hover');
    }
}
