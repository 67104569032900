// =================================
//    Homepage
// =================================
@mixin homepage-white-button-link() {
    display: inline-block;
    width: 100%;
    padding: 8px 10px;
    height: auto;
    font: 10px / 12px $MontserratBold;
    border: 1px solid $blue;
    color: $blue;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;

    @include media('>=tablet') {
        width: auto;
        min-width: 238px;
        margin: auto 0;
    }

    @include media('>=desktop') {
        min-width: 250px;
    }

    &:hover {
        border-color: $blue-hover;
        color: $blue-hover;
    }
}

// Homepage banner
.homepage-banner {
    margin: 0 auto;
}

// The first outlet text block
.home-outlet-text-block-wrapper {
    text-align: center;
    font: 12px / 14px $MontserratBold;
    padding: 30px 3.8%;
    margin: 0 0 20px;
    text-transform: uppercase;
    // default color & background
    color: $white;
    background-color: $blue;

    @include media('>=desktop') {
        padding: 30px 7%;
        font-size: 14px;
        line-height: 16px;
    }
}

// Main block
.home-main-block-wrapper {
    position: relative;
    margin: 0 0 vw(140px);

    @include media('>=tablet') {
        margin: 0 0 70px;
    }
}

.home-main-block-border {
    @include media('>=desktop') {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 10px;
        border: 5px solid $white;
    }
}

.home-main-block-container.row {
    position: relative;

    @include media('>=desktop') {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

.home-main-block-video.row {
    justify-content: center;
}

.home-main-block-video-button {
    margin-top: 10px;
}

.video-responsive-wrapper {
    width: 100%;
}

.video-responsive {
    position: relative;
    padding-bottom: 56.25%; // 16:9
    height: 0;
    margin: 0 auto;
}

.video-iframe {
    border: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home-main-block-container-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;

    &::before {
        box-shadow: inset 0 -70vw 38vw -22vw $white;
        content: '';
        width: 100%;
        height: 100%;
        bottom: -2px;
        left: 0;
        position: absolute;
        z-index: 1;

        @include media('>=tablet') {
            box-shadow: inset 0 -35vw 24vw -12vw $white;
        }
    }

    @include media('>=desktop') {
        display: none;
    }
}

.home-main-mobileimage {
    transform: scaleX(-1) rotate(180deg);
    height: 100%;
}

.home-main-block-content {
    background: $white;
    padding: vw(80px) vw(60px);
    position: relative;

    @include media('>=tablet') {
        padding: vw(50px);
    }

    @include media('>=desktop') {
        background: none;
        padding: 0;
    }
}

.home-main-block-content-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 10px solid $white;

    @include media('>=desktop') {
        display: none;
    }
}

.home-main-block-text {
    position: relative;
}

.home-main-block-title {
    text-align: center;
    //font-size: vw(80px);
    //ERV-408 Homepage main block broken on DE and ES
    word-break: break-word;

    @include media('>=tablet') {
        font-size: 50px;
    }

    @include media('>=desktop') {
        color: $white;
    }

    @include media('<desktop') {
        color: $blue !important;
    }
}

.home-main-block-subtitle {
    text-align: center;
    font-family: $TimesNewRoman;
    font-style: italic;
    font-size: vw(40px);
    word-break: break-word;

    @include media('>=tablet') {
        font-size: 32px;
        line-height: 32px;
    }

    @include media('>=desktop') {
        color: $white;
    }

    @include media('<desktop') {
        color: $blue !important;
    }
}

.home-main-block-link {
    @include media('<desktop') {
        color: $white !important;
        background: $blue !important;
    }

    @include media('>=desktop') {
        color: $blue;
        background-color: $white;
    }
}

.home-main-block-wave-separator {
    width: 100%;
    margin: vw(40px) 0;

    @include media('>=desktop') {
        margin: 20px 0;
    }
}

.slick-track {
    .button-holder-for-spacing {
        height: 25px;
        display: block;
        visibility: hidden;

        @include media('>=tablet', '<desktop') {
            height: vw(40px);
        }

        @include media('>=desktop') {
            margin-bottom: 20px;
        }
    }
}

// Homepage main content wrapper (after hero banner)
.homepage-main {
    margin: 0 auto;
    max-width: 1500px;
}

// New-in block - Product tile carousel
.newin-wrapper {
    margin: 0 0 vw(70px);

    @include media('>=tablet') {
        margin: 0 0 70px;
    }

    &.gl_flexbox-container {
        @include media('>=desktop', '<desktopMedium') {
            padding: 0;
        }
    }
}

.newin-title {
    text-align: center;
    margin: 0 0 vw(120px);

    @include media('>=tablet') {
        margin: 0 0 50px;
    }

    .newin-productcarousel ~ & {
        @include media('>=desktop') {
            margin-top: 45px;
        }
    }
}

.newin-productcarousel,
.socialmedia-carousel {
    position: relative;

    .slick-arrow {
        position: absolute;
        top: 45%;
    }

    .slick-prev {
        left: -40px;
    }

    .slick-next {
        right: -40px;
    }
}

.newin-productcarousel {
    .slick-track {
        position: relative;
        min-width: 100%;
    }

    .slick-list {
        margin: 0 -6px;
    }

    &.has-big-product {
        @include media('>=desktop') {
            display: flex;
            justify-content: flex-end;
        }

        .carousel-component {
            @include media('>=desktop') {
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                grid-template-rows: repeat(2, minmax(0, 1fr));
                padding: 0 26px 0 0;
                margin: 0 !important;
            }

            .product-tile-wrapper {
                @include media('>=tablet') {
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                }

                @include media('>=desktop') {
                    max-width: 100%;
                    flex-basis: unset;
                    justify-self: end;
                    width: 243px;
                }

                @include media('>=desktopLarge') {
                    width: 326px;
                }

                &:first-child {
                    @include media('>=desktop') {
                        grid-area: 1 / 1 / 2 / 2;
                    }
                }

                &:nth-child(2) {
                    @include media('>=desktop') {
                        grid-area: 2 / 1 / 3 / 2;
                        align-self: end;
                    }
                }

                &:last-child {
                    @include media('>=desktop') {
                        grid-area: 1 / 2 / 3 / 4;
                        width: 566px;

                        @include media('>=desktopLarge') {
                            width: 762px;
                        }
                    }
                }
            }
        }
    }
}

.newin-tile-wrapper {
    display: none;
}

.newin-view-more {
    margin: 0 auto vw(70px);

    @include media('>=tablet') {
        margin-bottom: 70px;
    }
}

// General homepage carousel rules
.carousel-component {
    position: relative;

    @include media('>=desktopLarge') {
        margin: 0 auto;
        max-width: calc(1235px + 20px); // + 20px on tiles paddings to avoid negative margins
    }

    // all tiles are hidden by default before mode initialization
    .carousel-component-tile {
        display: none;
    }

    .slick-arrow {
        position: absolute;
        top: 40%;
        width: 25px;
        height: 25px;
        z-index: 1;
    }

    .slick-prev {
        left: -15px;

        @include media('>=desktopLarge') {
            left: -30px;
        }
    }

    .slick-next {
        right: -15px;

        @include media('>=desktopLarge') {
            right: -30px;
        }
    }
}

.carousel-component:not(.slick-initialized) {
    margin: 0 7px;
    justify-content: flex-start;

    @include media('>=tablet') {
        margin: 0 5px;
    }

    @include media('>=desktop') {
        justify-content: center;
        margin: 0 10px;
    }

    @include media('>=desktopLarge') {
        margin: 0 auto;
    }

    &.flex-left {
        justify-content: flex-start;
    }

    .carousel-component-tile {
        padding: 0 8px;

        @include media('>=tablet') {
            padding: 0 10px;
        }

        // 5 column on desktop large
        @include media('>=desktopLarge') {
            flex-basis: 20%;
            max-width: 20%;
        }
    }
}

.carousel-component.slick-initialized {
    margin: 0 5px;

    @include media('>=desktop') {
        margin: 0 10px;
    }

    @include media('>=desktopLarge') {
        margin: 0 auto;
    }

    .carousel-component-tile {
        padding: 0 10px;
        margin: 0;
    }
}

// reserve space for arrows
.carousel-component.carousel-with-arrows {
    margin: 0 20px;

    @include media('>=desktop') {
        margin: 0 20px;
    }

    @include media('>=desktopLarge') {
        margin: 0 auto;
    }
}

// View more button: on product carousel and images carousel
.view-more-wrapper {
    display: none;
    text-align: center;
}

.view-more-btn {
    @include homepage-white-button-link();
}

// Product carousel component
.product-carousel-component {
    margin: 15px 0 25px;

    @include media('>=tablet') {
        margin: 15px 0 30px;
    }

    @include media('>=desktopLarge') {
        margin: 35px 0;
    }

    .product-carousel-component-title {
        @include header-title();
    }

    @include header-title-wave();

    .newin-title-wrapper {
        margin: 0 15px;
    }

    .newin-action-wrapper {
        margin: 0 15px;
        text-align: center;
    }

    .newin-link {
        display: block;

        @include media('>=tablet') {
            width: 300px;
            margin: 0 auto;
        }
    }

    .newin-link,
    .newin-view-more {
        margin: 12px 0 0;

        @include media('>=tablet') {
            margin: 20px auto 0;
        }
    }

    // non carousel mode
    .carousel-component:not(.slick-initialized) {
        // deviation: right/left space
        margin: 0 3px;

        @include media('>=tablet') {
            margin: 0 5px;
        }

        @include media('>=desktop') {
            margin: 0 10px;
        }

        @include media('>=desktopLarge') {
            margin: 0 auto;
        }

        .carousel-component-tile {
            padding: 0 3px;
            margin: 0;

            @include media('>=tablet') {
                padding: 0 10px;
            }
        }
    }
}

// Image carousel component
.images-carousel-component {
    padding: 20px 0;

    @include media('>=desktopLarge') {
        padding: 25px 0;
    }
}

.images-carousel-title-wrapper {
    margin: 0 15px;

    @include media('>=desktop') {
        margin: 0 20px;
    }

    @include media('>=desktopLarge') {
        margin: 0;
    }

    @include header-title-wave();
}

.images-carousel-title,
.images-carousel-title-link {
    @include header-title();
}

.images-carousel-subtitle {
    display: block;
    font: 12px / 14px $Arial;
    color: $blue;
    text-decoration: none;
    margin: 0 15px;

    @include media('>=tablet') {
        text-align: center;
    }

    @include media('>=desktop') {
        margin: 0 20px;
    }

    @include media('>=desktopLarge') {
        margin: 0;
    }
}

.images-carousel-wrapper {
    .images-carousel-title-wrapper + & { // title + carousel (no wave, no subtitle)
        padding-top: 10px;

        @include media('>=tablet') {
            padding-top: 15px;
        }
    }

    .images-carousel-subtitle + & { // subtitle + carousel
        padding-top: 25px;

        @include media('>=tablet') {
            padding-top: 30px;
        }
    }
}

// non carousel mode
.images-carousel:not(.slick-initialized) {
    .carousel-image-tile {
        margin: 0 0 16px;

        @include media('>=tablet') {
            margin: 0 0 10px;
        }
    }
}

// carousel center mode (with half slides)
.images-carousel.half-carousel-mode {
    @include media('>=desktopLarge') {
        max-width: 1500px;
    }

    .carousel-image-tile {
        padding: 0;
        margin: 0;
    }

    .slick-arrow {
        position: absolute;
        top: calc(50% - 15px);
        width: 33px;
        height: 33px;
        z-index: 1;
        border-radius: 50%;
        background-color: $grey-bg;
    }

    .slick-prev {
        left: 15px;
        padding: 8px 6px 8px 10px;
    }

    .slick-next {
        right: 15px;
        padding: 8px 10px 8px 6px;
    }
}

.images-carousel-button-wrapper {
    text-align: center;
    margin: 0 15px;
}

.images-carousel-button {
    display: block;
    margin: 15px 0 0;

    @include media('>=tablet') {
        width: 300px;
        margin: 30px auto 0;
    }
}

// Image carousel component: slides
.images-carousel-slide-link {
    position: relative;
    display: block;
}

.images-carousel-slide-link,
.images-carousel-slide-text {
    font: 13px / 1.15 $Arial;
    font-weight: bold;
    color: $blue;
    margin: 5px 0 0;
    text-decoration: none;
}

.images-carousel-slide-link:focus {
    outline-offset: -1px;
}

.images-carousel-slide-link:hover {
    .images-carousel-slide-text {
        color: $blue-hover;
    }
}

// Homepage Flexible content block
.home-flexible-wrapper {
    margin: 0 0 vw(70px);

    @include media('>=tablet') {
        margin: 0 0 70px;
    }

    &.gl_flexbox-container-desktop {
        @include media('>=desktop', '<desktopMedium') {
            padding: 0 40px;
        }
    }
}

.home-flexible-content.row {
    justify-content: center;
    align-content: center;
    align-items: stretch;
    padding: vw(40px);
    position: relative;
    overflow: hidden;

    @include media('>=desktop') {
        padding: 40px;
    }

    @include media('retina2x') {
        background-size: 50% auto;
    }
}

.home-gift-content {
    position: relative;
    background-position: center bottom;
    background-repeat: repeat-x;

    @include media('retina2x') {
        background-size: 50% auto;
    }

    @include media('<desktop') {
        background-image: none !important; // only the image
    }
}

.home-flexible-content-image,
.home-gift-content-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
}

.flexible-col-left {
    padding: 0;
    margin: 0 0 vw(20px);

    @include media('>=desktop') {
        margin: 0;
        padding: 0 10px 0 0;
    }
}

.flexible-col-right.row {
    padding: 10% 0;
    background: $white;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
}

.home-flexible-paragraph {
    color: $blue;
    margin: 0 0 vw(30px);

    @include media('>=desktop') {
        margin: 0 0 30px;
    }
}

.home-flexible-separator {
    margin: vw(20px) 0;

    @include media('>=desktop') {
        margin: 20px 0;
    }
}

// Homepage Gift block
.home-gift-wrapper {
    margin: 0 0 vw(70px);

    @include media('>=tablet') {
        margin: 0 0 70px;
    }
}

.home-gift-body {
    justify-content: center;
    flex-flow: row wrap;
}

.gift-col-left {
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 10%;
    font-size: 0;

    @include media('>=tablet') {
        padding: 40px;
    }

    @include media('>=desktop') {
        padding: 0;
    }

    background-position: center bottom;
    background-repeat: repeat-x;

    @include media('>=desktop') {
        background: none !important;
    }

    @include media('retina2x') {
        background-size: 50% auto;
    }
}

.home-gift-button {
    margin: vw(10px);

    @include media('>=desktop') {
        width: auto;
        max-width: none;
        margin: 0 3px;
    }
}

.home-gift-separator {
    margin: vw(20px) 0 vw(50px);

    @include media('>=tablet') {
        margin: 20px 0;
    }

    @include media('>=desktop') {
        margin: 20px 0 30px;
    }
}

.home-gift-title {
    color: $white;
}

.gift-col-right {
    padding: 0;

    @include media('>=desktop') {
        padding: 0 10px;
        margin: -1px 0;
    }
}

.gift-right-body {
    .gl_button {
        @include media('>=desktop') {
            padding: 0 20px;
        }
    }
}

.gift-bottom {
    height: 40px;
    background-position: center bottom;
    background-repeat: repeat-x;

    @include media('retina2x') {
        background-size: 50% auto;
    }

    @include media('>=desktop') {
        display: none;
    }
}

// Home browse by pattern block
.home-browsepattern-wrapper {
    margin-top: vw(40px);

    @include media('>=tablet') {
        margin-top: 40px;
    }
}

.home-browsepattern-container {
    position: relative;
}

.home-browsepattern-content-wrapper.row {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.home-browsepattern-separator {
    margin: 20px 0;
}

.home-browsepattern-link {
    background: $white;
    color: $blue;
}

.home-browsepattern-title {
    color: $white;
    text-align: center;
}

.home-browsepattern-content {
    position: relative;
    width: 100%;
}

// Home mix&match block
.home-mixmatch-content-wrapper.row {
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0 0 vw(70px);

    @include media('>=tablet') {
        margin: 0 0 70px;
    }

    @include media('>=desktop') {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        margin: 0;
    }
}

.home-mixmatch-container {
    position: relative;
}

.home-mixmatch-content {
    width: 100%;
    text-align: center;
}

.home-mixmatch-separator {
    margin: 20px 0;
}

.home-mixmatch-subtitle {
    color: $blue;
    margin: 0 0 30px;
}

// home social media block
.home-socialmedia-wrapper {
    margin: vw(70px) 0 vw(70px);

    @include media('>=tablet') {
        margin: 70px 0 70px;
    }
}

.home-seo-wrapper {
    margin: 0 0 vw(70px);
    text-align: center;

    @include media('>=tablet') {
        margin: 0 0 70px;
    }

    &.gl_flexbox-container {
        @include media('>=desktop', '<desktopMedium') {
            padding: 0;
        }
    }

    .svgwave {
        transform: scale(0.5);
    }

    .svgwavepath {
        stroke: $blue;
    }
}

.home-seo-separator {
    margin: 12px 0;
}

.home-seo-title {
    font-weight: bold;
    text-transform: none;
}

.home-seo-title,
.home-seo-text {
    font-family: $Arial;
    font-size: 12px;
    font-style: italic;
    color: $blue;
}

.read-more-block {
    .full-text {
        display: none;

        @include media('>=desktop') {
            display: block;
        }
    }

    .cropper-text-wrapper {
        @include media('>=desktop') {
            display: none;
        }
    }

    .cropper-text {
        &::after {
            content: '... ';
        }
    }

    .read-more-button {
        text-decoration: underline;

        &:hover {
            cursor: pointer;
            color: $blue-hover;
        }

        &:focus {
            color: $blue-hover;
        }
    }

    &.show-full-text {
        .full-text {
            display: block;
        }

        .cropper-text-wrapper {
            display: none;
        }
    }
}

// About block on homepage via Page Designer
.homepage-title {
    @include header-title();

    text-align: center;

    .title-link {
        text-decoration: none;
    }
}

.about-us-block {
    margin: 40px 15px;

    @include media('>=tablet') {
        margin: 75px 15px;
    }

    @include media('>=desktop') {
        max-width: 830px;
        margin: 60px auto;
    }

    .about-us-block-title {
        @include media('>=tablet') {
            font-size: 16px;
            line-height: 19px;
        }
    }
}

.main-hero-banner {
    margin: 25px 0 10px;

    @include media('>=tablet') {
        margin: 25px 0 35px;
    }

    &.first {
        margin-top: 0;
    }

    .main-banner-image {
        margin: 0 auto 10px;
        padding: 0;

        @include media('>=tablet') {
            margin-bottom: 35px;
        }
    }

    .bynder-widget {
        margin-bottom: 10px;

        @include media('>=tablet') {
            margin-bottom: 35px;
        }
    }

    .title-link {
        text-decoration: none;
    }

    .homepage-image-title-wrapper {
        text-decoration: none;
        display: block;
    }

    .top-banner-wrapper {
        width: 100%;
        height: 345px;
        display: block;
        margin: 0 0 10px;

        iframe {
            width: 100%;
            height: 100%;
            border: 0;
        }

        @include media('>=tablet') {
            margin-bottom: 35px;
            height: 400px;
        }

        @include media('>=desktop') {
            height: 550px;
        }
    }

    .homepage-button-link {
        margin: 10px 15px 0;
        max-width: calc(100% - 30px);

        @include media('>=tablet') {
            margin: 10px auto 0;
        }

        @include media('>=desktop') {
            margin-top: 20px;
        }
    }

    .homepage-description {
        margin-bottom: 10px;

        @include media('>=tablet') {
            margin-bottom: 15px;
        }

        @include media('>=desktop') {
            margin-bottom: 20px;
        }
    }

    .homepage-title {
        width: 80%;
        margin: 0 auto 5px;

        @include media('>=tablet') {
            width: 60%;
            font-size: 24px;
            line-height: 24px;
        }

        @include media('>=desktop') {
            font-size: 32px;
            line-height: 32px;
        }

        @include media('>=1400px') {
            width: 35%;
        }
    }
}

.homepage-wave {
    margin: 0 0 5px;
}

.homepage-description {
    display: block;
    text-align: center;
    font: 12px / 14px $Arial;
    text-decoration: none;
    color: $blue;
    margin: 0 15px;

    @include media('>=tablet') {
        margin: 0 auto;
    }

    @include media('>=tablet', '<desktop') {
        width: 75vw;
        max-width: 580px;
    }

    @include media('>=desktop') {
        max-width: 580px;
    }
}

.homepage-image-wrapper {
    display: block;
    margin: 10px 0;

    @include media('>=tablet') {
        margin-bottom: 30px;
    }
}

.about-us-icon {
    width: 50px;
    padding: 15px 0;
    margin: 0 auto;

    @include media('>=tablet') {
        padding: 15px 0 10px;
    }
}

.homepage-button-link {
    display: block;
    margin-bottom: 15px;

    @include media('>=tablet') {
        width: 300px;
        margin: 0 auto 15px;
    }
}

/* Newsletter subscription block */
.receive-newsletter {
    margin: 15px 0;

    .input-text {
        padding: 0 40px 0 10px;
        height: 44px;
    }

    .receive-newsletter-group {
        padding: 0;
        margin: 0 auto;
        max-width: 400px;

        &.gl_has-success {
            .gl_input-wrapper {
                display: none;
            }
        }
    }

    .receive-newsletter-subscribe-button {
        padding: 0 6px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font: 10px / 13px $MontserratBold;
        width: auto;
        background-color: $blue;
        color: $white;
        text-transform: uppercase;
        min-width: 40px;
        z-index: 1;

        @include media('>=desktop') {
            width: auto;
            font-size: 11px;
        }

        &:hover {
            background-color: $blue-hover;
            color: $white;
        }
    }

    .gl_loading .receive-newsletter-subscribe-button {
        display: none;
    }

    .receive-newsletter-title {
        @include header-title();
    }

    .subscription-success {
        display: block;
        text-align: center;
        font: 12px / 14px $Arial;
        text-decoration: none;
        color: $blue;
    }
}

/* Page Designer Homepage */
.page-designer-reference {
    .form-error {
        color: $red;
    }
}
