.pd-video-component {
    margin: 45px 15px;

    @include media('>=tablet') {
        margin: 45px 60px;
    }

    @include media('>=desktop') {
        margin: 45px 20px;
    }

    @include media('>=desktopLarge') {
        margin: 45px auto;
        max-width: 1120px;
    }
}
